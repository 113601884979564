import React from 'react'
import EventAttendees from './EventAttendees'
import EventDays from './EventDays'

const Squares = ({selected}) => {
  return (
    <div className='d-flex flex-row gap-3 h-100 '>
        <EventAttendees selected={selected}/>
        <EventDays selected={selected}/>
    </div>
  )
}

export default Squares