import React from 'react'
import { Card, CardHeader, CardBody, CardTitle, CardText, InputGroup, Input, Form, Label, FormGroup, Button, Col, Row, Container} from "reactstrap"

import FormWizard from '../Wizard/FormWizard'


const Home = () => {

    return (

        <Row className=' d-flex justify-content-center align-items-center vh-100 '>
            <Col lg='8' sm='12'>
                <FormWizard/>
            </Col>
        </Row>

    )
}

export default Home