/* eslint-disable no-unused-vars */
import huella_chile from '../../../assets/Images/huella_chile.png'
import undp_logo from '../../../assets/Images/undp_logo.png'
import mma_logo from '../../../assets/Images/MmaChile.png'
import lmd from '../../../assets/Images/lmd.png'
import Table1 from '../Tables/Table1'
import Table2 from '../Tables/Table2'
import Table3 from '../Tables/Table3'
import Table4 from '../Tables/Table4'
import { PDFDownloadLink, Document, Page, View, Text, StyleSheet, Image } from '@react-pdf/renderer'
import TableEmisiones from '../Tables/TableEmisiones'

const styles = StyleSheet.create({
    page: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 65
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    headersection: {
        display:'flex',
        flexDirection:'column',
        marginBottom:30
    },
    subSection:{
        display:'flex',
        flexDirection:'column',
        alignItems:'flex-start',
        marginBottom:25
    },
    subSectionText:{
        fontSize:10,
        marginBottom:7
    },
    h1: {
        fontWeight:'bold',
        fontSize:18,
        textAlign:'center',
        marginBottom:10,
        color:'#1f497d'
    },
    text: {
        fontSize: 10,
        textAlign: 'left',
        fontWeight:'regular'
    },
    textIndice: {
        fontSize: 10,
        textAlign: 'left',
        fontWeight:'regular',
        marginTop:15
    },
    textIndiceSub: {
        fontSize: 10,
        textAlign: 'left',
        fontWeight:'regular',
        marginTop:15,
        paddingLeft:15
    },
    sectionTitle: {
        fontSize:10,
        textAlign:'left',
        fontWeight:'bold',
        marginBottom:8
    },
    portada:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 65
    },
    summaryTitle: {
        color: '#1f497d',
        fontWeight:'bold',
        fontSize:'11',
        marginBottom:13,
        marginTop:13
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableCol: {
        width: "50%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCell: {
        margin: "auto",
        marginTop: 30,
        fontSize: 10,
        marginBottom:30
    }

})

const PDFDocument = ({ data1, usage, co2e, numberOfEvents}) => {
    const calculateTotal = (usage, co2e) => {
        const emissionNames = ['Alimentación', 'Adquisición de electricidad red', 'Uso de combustible', 'Hoteles', 'Merchandising', 'Transporte de artistas', 'Transporte de carga', 'Residuos', 'Consumo de agua']

        const totals = new Array(9).fill(0)
        for (let i = 0; i < numberOfEvents; i++) {
            totals[0] += Number(usage[i].alimentation.usage)
            totals[1] += Number(usage[i].energy.usage)
            totals[2] += Number(usage[i].fuel.total)
            totals[3] += Number(usage[i].hotel.usage)
            totals[4] += Number(usage[i].merchandising.usage)
            totals[5] += Number(usage[i].transfer.traslado_artistas)
            totals[6] += Number(usage[i].transfer.traslado_carga)
            totals[7] += Number(usage[i].waste.compostaje) +  Number(usage[i].waste.reciclaje) + Number(usage[i].waste.residuos)
            totals[8] += Number(usage[i].water.agua_noria) + Number(usage[i].water.agua_red) + Number(usage[i].water.agua_camion)
        }
        const max = Math.max(...totals)
        const index = totals.indexOf(max)
        const maxValue  = {
            label: emissionNames[index],
            percentage: Number((max / co2e) * 100).toFixed(2)
        }
        return { totals, maxValue}

    }
    const sectionsThatUseAverage = (data1) => {
        const uses = []
        for (let i = 0; i < numberOfEvents; i++) {
            if (data1.carbonDeclaration[i].agua.agua_camion.use === 'average') uses.push(`Agua transportada - ${data1.eventDetails[i].eventIdentificator}`)
            if (data1.carbonDeclaration[i].agua.agua_red.use === 'average') uses.push(`Agua de la red - ${data1.eventDetails[i].eventIdentificator}`)
            if (data1.carbonDeclaration[i].alimentos_bebidas.staff.wasDistributed === 'average') uses.push(`Alimentación staff y artistas - ${data1.eventDetails[i].eventIdentificator}`)
            if (data1.carbonDeclaration[i].energy.electricNetwork.use === 'average') uses.push(`Consumo de la red eléctrica - ${data1.eventDetails[i].eventIdentificator}`)
            if (data1.carbonDeclaration[i].fuels.GLP.use === 'average') uses.push(`Consumo de GLP - ${data1.eventDetails[i].eventIdentificator}`)
            if (data1.carbonDeclaration[i].fuels.bencina.use === 'average') uses.push(`Consumo de bencina - ${data1.eventDetails[i].eventIdentificator}`)
            if (data1.carbonDeclaration[i].fuels.diesel.use === 'average') uses.push(`Consumo de diesel - ${data1.eventDetails[i].eventIdentificator}`)
            if (data1.carbonDeclaration[i].fuels.gas_natural.use === 'average') uses.push(`Consumo de gas natural - ${data1.eventDetails[i].eventIdentificator}`)
            if (data1.carbonDeclaration[i].merchandising.use === 'average') uses.push(`Merchandising- ${data1.eventDetails[i].eventIdentificator}`)
            if (data1.carbonDeclaration[i].residuos.reciclaje.use === 'average') uses.push(`Reciclaje - ${data1.eventDetails[i].eventIdentificator}`)
            if (data1.carbonDeclaration[i].residuos.residuos.use === 'average') uses.push(`Residuos - ${data1.eventDetails[i].eventIdentificator}`)
        }
        return uses.join(', ')
    }
    return (
        <Document>
            <Page Page size='A4' style={styles.page}>
                <View style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between', marginBottom:'30px'}}>
                    <Image src={mma_logo} style={{height:'50px'}}/>
                    <Image src={huella_chile} style={{height:'50px'}}/>
                    <Image src={undp_logo} style={{height:'50px'}}/>
                </View>
                <View style={styles.headersection}>
                    <Text style={styles.h1}>
                        DECLARACIÓN DE CUANTIFICACIÓN DE EMISIONES DE GEI PARA EVENTOS
                    </Text>
                    <Text style={styles.text}>
                        Este documento presenta en resumen la cuantificación y/o neutralización de emisiones de Gases de
                        Efecto Invernadero (GEI) asociado al evento:
                    </Text>
                </View>

                <View style={styles.subSection}>

                    <Text style={styles.sectionTitle}>Identificación del evento</Text>
                    <View >
                        <Text  style={styles.subSectionText}>Nombre del evento: {data1.generalEventData.eventName}</Text>
                        <Text style={styles.subSectionText}>Localización: {data1.eventDetails[0].eventAddress} </Text>
                        <Text style={styles.subSectionText}>Periodo de reporte (inicio - término): {data1.eventDetails[0].startMontageDate} - {data1.eventDetails[numberOfEvents - 1].endUnmountDate}</Text>
                    </View>
                </View>
        
                <View style={styles.subSection}>

                    <Text style={styles.sectionTitle}>Organización Responsable</Text>
                    <View >
                        <Text  style={styles.subSectionText}>Razón social: {data1.userData.razonSocial}</Text>
                        <Text style={styles.subSectionText}>RUT: {data1.userData.RUT}</Text>
                        <Text style={styles.subSectionText}>Nombre persona responsable: {data1.userData.name}</Text>
                        <Text style={styles.subSectionText}>Correo electrónico: {data1.userData.email}</Text>
                        <Text style={styles.subSectionText}>Fecha de generación de reporte: { new Date().toJSON().slice(0, 10).replace(/-/g, '/')}</Text>
                    </View>
                </View>

                <View style={styles.subSection}>

                    <Text style={styles.sectionTitle}>Verificación de tercera parte independiente? No</Text>
                    <View>
                        <Text style={styles.subSectionText}>Razón social organismo verificador:</Text>
                        <Text style={styles.subSectionText}>RUT organismo verificador:</Text>
                        <Text style={styles.subSectionText}>Correo electrónico:</Text>
                        <Text style={styles.subSectionText}>Fecha de emisión verificación:</Text>
                    </View>
                </View>


            </Page>
            <Page Page size='A4' style={styles.page}>
                <View style={{marginVertical:10}}>

                    <Text style={styles.text}>Para el siguiente informe se utilizaron emisiones promedio en función de los asistentes correspondientes por evento individual en las secciones: {sectionsThatUseAverage(data1)}</Text>
                </View>

                <View style={styles.subSection}>
                    <Text style={styles.sectionTitle}>Tabla 1. Emisiones totales de {data1.generalEventData.eventName}</Text>
                    <Table1 co2e={co2e}/>
                </View>


                <View style={styles.subSection}>
                    <Text style={styles.sectionTitle}>Tabla 2. Indicadores de Intensidad - Huella de Carbono Evento por unidad funcional</Text>
                    <Table2 data1={data1} co2e={co2e} numberOfEvents={numberOfEvents} />
                </View>
            </Page>

            <Page style={styles.portada}>
                <Text style={styles.h1}>
                    INFORME:CUANTIFICACIÓN DE EMISIONES DE GASES DE EFECTO
                    INVERNADERO DE EVENTO
                </Text>
                <Text>{data1.generalEventData.eventName}</Text>
                <Image src={lmd} style={{height:'100px', marginTop:'20px'}}/>


            </Page>
            <Page style={styles.page}>
                <View style={{marginVertical:10}}>

                    <Text style={styles.summaryTitle}>CONTENIDO INFORME</Text>
                    <Text style={styles.textIndice}>1. INTRODUCCIÓN</Text>
                    <Text style={styles.textIndice}>2. METODOLOGÍA DE CUANTIFICACIÓN </Text>
                    <Text style={styles.textIndiceSub}>2.1 Límites del Sistema</Text>
                    <Text style={styles.textIndice}>3. RESULTADOS</Text>
                    <Text style={styles.textIndiceSub}>3.1 Resultados - Huella de Carbono Evento por unidad funcional</Text>
                    <Text style={styles.textIndiceSub}>3.2 Resultados - Huella de Carbono Evento según etapa cadena de valor y fuente de emisión</Text>
                    <Text style={styles.textIndice}>4. CONCLUSIONES</Text>
                    <Text style={styles.textIndice}>5. ANEXO</Text>
                    <Text style={styles.textIndiceSub}>5.1 Anexo. Factores de emisión utilizados</Text>
                    <Text style={styles.textIndiceSub}>5.2 Anexo. Limitaciones, exclusiones y supuestos utilizados en la cuantificación</Text>

                </View>

            </Page>

            <Page style={styles.page}>
                <View style={{marginVertical:10}}>

                    <Text style={styles.summaryTitle}>1.  INTRODUCCIÓN</Text>
                    <Text style={styles.text}>El presente documento entrega los resultados de la cuantificación de las emisiones de Gases de Efecto Invernadero (GEI) del evento {data1.generalEventData.eventName}, organización {data1.userData.razonSocial} y fecha {new Date().toISOString().split('T')[0]}</Text>
                </View>
                <View style={{marginVertical:10}}>
                    <Text style={styles.summaryTitle}>2.  METODOLOGÍA DE CUANTIFICACIÓN </Text>
                    <Text style={styles.text}>La cuantificación de emisiones de GEI del evento se presenta en toneladas de dióxido equivalente (t CO2eq/evento) y ha sido elaborado en conformidad con la NCh-ISO 14067:2014 y los requisitos específicos del Programa HuellaChile.</Text>
                </View>

                <View style={{marginVertical:10}}>

                    <Text style={styles.sectionTitle}>2.1 Limites del sistema</Text>
                    <Text style={styles.text}>Fuentes de emisión considerados</Text>
                    <Table3/>
                </View>

                <View style={{marginVertical:10}}>

                    <Text style={styles.summaryTitle}>3.  RESULTADOS</Text>
                    <Text style={styles.sectionTitle}>3.1 Resultados - Huella de Carbono Evento por unidad funcional</Text>
                    <Table2 data1={data1} co2e={co2e} numberOfEvents={numberOfEvents}/>
                </View>
            </Page>
            <Page style={styles.page}>
                <View style={{marginVertical:10}}>
                    <Text style={styles.sectionTitle}>3.2 Resultados - Huella de Carbono Evento por unidad funcional</Text>
                </View>
                <Table4  data1={data1} usage={usage} co2e={co2e} numberOfEvents={numberOfEvents}/>

                <View style={{marginVertical:10}}>

                    <Text style={styles.summaryTitle}>4.  CONCLUSIONES</Text>
                    <Text style={styles.sectionTitle}>Las emisiones de GEI de evento de {data1.generalEventData.eventName}, {new Date(data1.eventDetails[0].startEventDate).getFullYear()} cuantificadas corresponde a {calculateTotal(usage, co2e).maxValue.label} el que contribuye
                        con la mayor parte de las emisiones, equivalentes al {calculateTotal(usage, co2e).maxValue.percentage}%</Text>
                </View>

                <View style={{marginVertical:10}}>

                    <Text style={styles.summaryTitle}>5.  ANEXO</Text>
                    <Text style={styles.sectionTitle}>5.1 Anexo. Factores de emisión utilizados </Text>
                    <Text style={styles.text}>Fuentes de emision considerados</Text>
                    <View style={styles.subSection}>
                        <TableEmisiones />
                    </View>


                </View>
                <View style={{marginVertical:10}}>

                    <Text style={styles.sectionTitle}>Anexo. Limitaciones, exclusiones y supuestos utilizados en la cuantificación </Text>
                    <Text style={styles.text}>Fuentes de emision considerados</Text>
                </View>

            </Page>
        </Document>
    )
}
export default PDFDocument