import React from 'react'
import Point from './Point'

const WaterLegend = () => {
    return (
        <>
            <Point name='Agua de la red'/>
            <Point name='Agua transportada'/>
            <Point name='Agua proveniente de noria'/>

        </>
    )
}

export default WaterLegend