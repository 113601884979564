import React, { Fragment, useState, useContext, useEffect} from "react"
import { Row, Col, Button, Form, CardBody, Accordion, Spinner} from "reactstrap"
import { ArrowLeft, ArrowRight } from "react-feather"
import { useForm, FormProvider} from "react-hook-form"
import { DataContext } from "../../../../Context/Context"
import DeclarationMain from "./DeclarationMain"
import { calculateTotal } from "../../../../Helpers/CalculateTotal"
import CustomModal from "../../../Charts/CustomModal"
const Declaration = ({ stepper }) => {
  // ** States and comp functions
  const [open, setOpen] = useState("")
  const toggle = (id) => {
    open === id ? setOpen() : setOpen(id)
  }
  const [centeredModal, setCenteredModal] = useState(false)
  const [goNext, setGoNext] = useState(false)

  // ** Context
  const {co2e, setData, numberOfEvents, usage, setco2e, setShowPDF, data1} = useContext(DataContext)

  // ** React Hook forms
  const {...methods} = useForm({ criteriaMode: "all"})
  console.log(methods.formState.errors, 'methods')

  //Calcula total cada vez que cambia la data

  // ** Submit
  const onSubmit = (data) => {
    setCenteredModal(true)
    if (goNext) {
      setCenteredModal(false)
      setShowPDF(true)
      stepper.next()
    }
    const carbonDeclaration = data
    const {eventsTotal} = calculateTotal(usage, numberOfEvents)
    const newData = data1
    newData.generalEventData.totalCO2 = co2e
    for (let i = 0; i < numberOfEvents; i++) {
      newData.eventDetails[i].totalCO2 = eventsTotal[i]
    }
    setData({...newData, carbonDeclaration})


  }

  useEffect(() => {
    const {total} = calculateTotal(usage, numberOfEvents)
    if (isNaN(total)) {
      setco2e(0)
    } else {
      setco2e(total)
    }
  }, [methods.watch()])


  return (
      <Fragment>

        <Row className="content-header  ">
          <div className="d-flex flex-row align-items-center justify-content-between px-1  my-2">
            <div>
              <h5 className="mb-0">Declaración de huella</h5>
              <div>
                <small>
                  Declara a continuación los campos utilizados durante el evento{" "}
                  <br />
                  Luego de este paso podrás obtener información gráfica sobre tus eventos declarados.
                </small>
              </div>
            </div>
            <h3 className="text-success">
              {co2e > 1 ? `${(co2e).toFixed(2)} TON ` : `${(co2e * 1000).toFixed(2)} Kg`} CO<sub>2</sub>e
            </h3>
          </div>

          <CardBody className="h-50">
            <FormProvider {...methods}>
              <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Accordion
                    open={open}
                    toggle={toggle}
                    className={`accordion-margin`}
                >
                  {Array.apply(null, {length: numberOfEvents}).map((e, index) => (
                      <DeclarationMain key={index} index={index} />
                  ))}

                </Accordion>

                <Col className="d-flex justify-content-between mt-3">
                  <Button
                      type="button"
                      color="primary"
                      className="btn-prev"
                      onClick={() => stepper.previous()}
                  >
                    <ArrowLeft
                        size={14}
                        className="align-middle me-sm-25 me-0"
                    ></ArrowLeft>
                    <span className="align-middle d-sm-inline-block d-none">
                    Ir atrás
                  </span>
                  </Button>
                  <Button type="submit" color="primary" className="btn-next">
                  <span className="align-middle d-sm-inline-block d-none">
                    Siguiente
                  </span>
                    <ArrowRight
                        size={14}
                        className="align-middle ms-sm-25 ms-0"
                    ></ArrowRight>
                  </Button>
                </Col>
              </Form>
            </FormProvider>
          </CardBody>
        </Row>
        <CustomModal centeredModal={centeredModal} setCenteredModal = {setCenteredModal} setGoNext = {setGoNext}/>
      </Fragment>
  )
}

export default Declaration