import React from 'react'
import { User, Calendar} from 'react-feather'
import { Row, Col } from 'reactstrap'

const EventDays = ({selected}) => {
    const calculateDate = (date1, date2) => {
        const date_1 = new Date(date1)
        const date_2 = new Date(date2)
        const difference = date_2.getTime() - date_1.getTime()
        const TotalDays = Math.ceil(difference / (1000 * 3600 * 24))
        if (TotalDays === 0) return 1
        return TotalDays
    }
    return (
        <Row className=' d-flex justify-content-center align-items-center w-100 h-100 p-1 ' style={{backgroundColor:'black', border:'3px #e21d34 solid'}}>
            <Row className='w-100 '>
                <Col className='d-flex justify-content-center '><Calendar size={98} color='white'/></Col>
            </Row>
            <Row className='w-100'>
                <Col>
                    <h1 className='text-center text-white' style={{fontSize:'45px', fontWeight:'bold'}}>{calculateDate(selected.startMontageDate, selected.endUnmountDate)}</h1>
                    <h5 className='text-center text-white' style={{ fontWeight:'bold'}}>{calculateDate(selected.startMontageDate, selected.endUnmountDate) > 1 ?  'Días en total' : 'Día en total'}</h5>
                </Col>
            </Row>
        </Row>
    )
}

export default EventDays