import React from 'react'
import { View, StyleSheet, Text } from '@react-pdf/renderer'

const styles = StyleSheet.create({
    table: {
        display: "table",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        width:'75%'
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableCol: {
        width: "40%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCell: {
        margin: "auto",
        marginTop: 5,
        fontSize: 10
    }

})

const Table2 = ({co2e, data1, numberOfEvents}) => {

    const calculateTotalAttendees = (data1) => {
        let totalAttendees = 0
        for (let i = 0; i < numberOfEvents; i++) {
            totalAttendees += Number(data1.eventDetails[i].attendeesNumber)
        }
        return totalAttendees
    }

    return (
        <View style={styles.table}>
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Huella de Carbono Evento</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Unidad</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Valor</Text>
                </View>
            </View>
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Evento realizado</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>tCO2e/evento</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{(Number(co2e).toFixed(2) / numberOfEvents)}</Text>
                </View>

            </View>

            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Cantidad de asistentes al Evento</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>tCO2e/personas</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{(co2e / calculateTotalAttendees(data1)).toFixed(6)}</Text>
                </View>

            </View>


        </View>
    )
}

export default Table2