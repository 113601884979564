import { createContext, useState } from "react"

export const DataContext = createContext()

export const DataProvider = ({children}) => {
    const [numberOfEvents, setNumberOfEvents] = useState(1) //total of events
    const [co2e, setco2e] = useState(0) //total
    const [data1, setData] = useState({}) //data from forms
    const [values, setValues] = useState() //values from api
    const [goToDeclaration, setGoToDeclaration] = useState(false)
    const [showPDF, setShowPDF] = useState(false)

    const usage = {
        0: {water:{}, merchandising:{}, fuel:{}, waste:{}, transfer:{}, hotel:{}, fugitivas:{}, energy:{}, alimentation:{}},
        1: {water:{}, merchandising:{}, fuel:{}, waste:{}, transfer:{}, hotel:{}, fugitivas:{}, energy:{}, alimentation:{}},
        2: {water:{}, merchandising:{}, fuel:{}, waste:{}, transfer:{}, hotel:{}, fugitivas:{}, energy:{}, alimentation:{}},
        3: {water:{}, merchandising:{}, fuel:{}, waste:{}, transfer:{}, hotel:{}, fugitivas:{}, energy:{}, alimentation:{}},
        4: {water:{}, merchandising:{}, fuel:{}, waste:{}, transfer:{}, hotel:{}, fugitivas:{}, energy:{}, alimentation:{}},
        5: {water:{}, merchandising:{}, fuel:{}, waste:{}, transfer:{}, hotel:{}, fugitivas:{}, energy:{}, alimentation:{}},
        6: {water:{}, merchandising:{}, fuel:{}, waste:{}, transfer:{}, hotel:{}, fugitivas:{}, energy:{}, alimentation:{}},
        7: {water:{}, merchandising:{}, fuel:{}, waste:{}, transfer:{}, hotel:{}, fugitivas:{}, energy:{}, alimentation:{}},
        8: {water:{}, merchandising:{}, fuel:{}, waste:{}, transfer:{}, hotel:{}, fugitivas:{}, energy:{}, alimentation:{}},
        9: {water:{}, merchandising:{}, fuel:{}, waste:{}, transfer:{}, hotel:{}, fugitivas:{}, energy:{}, alimentation:{}},
        10: {water:{}, merchandising:{}, fuel:{}, waste:{}, transfer:{}, hotel:{}, fugitivas:{}, energy:{}, alimentation:{}}
    }


    return (
        <DataContext.Provider value={
            {numberOfEvents, setNumberOfEvents, co2e, setco2e, goToDeclaration, setGoToDeclaration, data1, setData, values, setValues, usage, setShowPDF, showPDF}}>
            { children }
        </DataContext.Provider>
    )
}