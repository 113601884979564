import React from 'react'
import { X } from 'react-feather'
import { Button } from 'reactstrap'
import toast from 'react-hot-toast'
//https://calculadoramde.cl/calculadora/api/valuesAPI
const EliminateButton = ({id, eventName, setData, setLoading}) => {
  const onEliminateButton = async (id) => {
    fetch('https://calculadoramde.cl/calculadora/api/formAPI/delete', {
      method:'PUT',
      headers:{
        'Content-Type': 'application/json'
      },
      body:JSON.stringify({EventID: id})
    })
        .catch(error => error)
        .then(response => {
          response.json()

        })
        .then(result => {
          console.log(result)
          setLoading(true)
          fetch('https://calculadoramde.cl/calculadora/api/formAPI')
              .then(response => response.json())
              .then(result => {
                setData(result)
              })
              .then(() => setLoading(false))
          return 'Success'
        })
  }
  const handleClick = (id) => {
    return toast.promise(onEliminateButton(id), {
      loading:'Loading',
      success:`Se eliminó el evento ${eventName} correctamente!`,
      error:'Whoops, hubo algun error al eliminar este evento'
    })
  }

  return (
      <Button className='btn btn-icon btn-danger ' size='sm' onClick={() => handleClick(id)}><X/></Button>

  )
}

export default EliminateButton