
export const calculateTotal = (results, amountOfEvents) => {
  if (results) {
    console.log(results, 'resultados')
    let total = 0
    const eventsTotal = {}
    if (results) {
      for (let i = 0; i < amountOfEvents; i++) {
        let eventTotal = 0
        eventTotal +=  Number(results[i]?.water?.agua_red) + Number(results[i]?.water?.agua_camion) + Number(results[i]?.water.agua_noria)
        eventTotal += Number(results[i]?.energy?.usage)
        eventTotal += Number(results[i]?.fuel?.bencina) +  Number(results[i]?.fuel?.diesel) + Number(results[i]?.fuel?.gas_natural) + Number(results[i]?.fuel?.GLP)
        eventTotal += Number(results[i]?.hotel?.usage)
        eventTotal += Number(results[i]?.merchandising?.usage)
        eventTotal += Number(results[i]?.waste?.compostaje) +  Number(results[i]?.waste?.residuos) + Number(results[i]?.waste?.reciclaje)
        eventTotal += Number(results[i]?.alimentation?.usage)
        eventTotal += Number(results[i]?.transfer?.traslado_artistas) + Number(results[i]?.transfer?.traslado_carga)  + Number(results[i].transfer.traslado_asistentes)
        eventsTotal[i] = eventTotal
        total += eventTotal
      }

      return {total, eventsTotal}
    }
  }

}