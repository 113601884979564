import React from 'react'
import { View, StyleSheet, Text } from '@react-pdf/renderer'

const styles = StyleSheet.create({
    table: {
        display: "table",
        width: "70%",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableCol: {
        width: "50%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCell: {
        margin: "auto",
        marginTop: 5,
        fontSize: 10,
        marginBottom:5
    }

})

const Table3 = () => {
    return (
        <View style={styles.table}>
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Cadena de valor / Fuente de emisión</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Incluido/Excluido/No aplica</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Detalle de Fuente de Emisión</Text>
                </View>
            </View>
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Consumo de agua</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Incluido</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}></Text>
                </View>
            </View>

            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Transporte de artistas</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Incluido</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}> Artistas y staff</Text>
                </View>
            </View>

            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Transporte de carga</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Incluido</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}></Text>
                </View>
            </View>

            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Uso de combustible</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Incluido</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>GLP, Diésel, Bencina, Gas natural</Text>
                </View>
            </View>

            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Adquisición de electricidad red</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Incluido</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}></Text>
                </View>
            </View>

            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Alimentación</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Incluido</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Staff y público</Text>
                </View>
            </View>
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Hoteles</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Incluido</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}></Text>
                </View>
            </View>
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Residuos</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Incluido</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Residuos, reciclaje y compostaje</Text>
                </View>
            </View>
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Merchandising</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Incluido</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}></Text>
                </View>
            </View>


        </View>
    )
}

export default Table3