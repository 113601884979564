/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable semi */
/* eslint-disable comma-dangle */
import React from "react";
import { Container, Row, Col } from "reactstrap";
import hero from "../assets/Images/Recurso3.svg";
import ButtonL from "../assets/Images/buttonL.svg";
import { Instagram } from "react-feather";
import image1 from "../assets/Images/image.jpg";
import CalculadoraLogo from "../assets/Images/calculadoralogo.svg";
import Logo1 from "../assets/Images/lmd.png";
import Fondos from "../assets/Images/fondos.png";
import Gob from "../assets/Images/gob.svg";
import bird from "../assets/Images/background.svg";
import footer1 from "../assets/Images/footerlogo1.svg";
import footer2 from "../assets/Images/footerlogo2.svg";
import bird2 from "../assets/Images/bird.png";
import { Link } from "react-router-dom";
import Case from "../assets/Images/case.png";
import divider from "../assets/Images/divider.svg";
import pdf from "../assets/manual.pdf";

const Landing = () => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <>
      <div
        className=""
        style={{
          backgroundImage: `url(${bird})`,
          backgroundPosition: "top center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <header className="my-4">
          <Container>
            <Row className="d-flex align-items-center justify-content-center position-relative">
              <Col className="position-absolute start-0 top-0 d-sm-none d-md-block">
                <img src={Logo1} style={{ height: "120px" }} />
              </Col>
              <Col className=" start-0 top-0 d-sm-block d-md-none">
                <div className=" d-flex align-items-center justify-content-center mb-2">
                  <img src={Logo1} style={{ height: "120px" }} />
                </div>
              </Col>
              <Col className="d-flex align-items-center justify-content-center">
                <img src={CalculadoraLogo} style={{ height: "80px" }} />
              </Col>
            </Row>
          </Container>
        </header>
        <section>
          <Container>
            <Row className="d-flex align-items-center justify-content-center">
              <Col sm="12" md="8">
                <img src={hero} className="img-fluid" />
              </Col>
            </Row>
            <Row className="d-flex align-items-center justify-content-center mt-3">
              <Col sm="12" md="6">
                <p className="text-center text-black">
                  ¡Sé el cambio que el planeta necesita! Súmate para avanzar en
                  la sostenibilidad de la industria musical independiente en
                  Chile midiendo el impacto de tus acciones con la Calculadora
                  MDE, desarrollada por La Música Declara Emergencia y el
                  programa de gestión de carbono Huella Chile, del Ministerio
                  del Medio Ambiente.
                </p>
              </Col>
            </Row>
            <Row className="d-flex align-items-center justify-content-center mt-1">
              <Col sm="12" md="6">
                <h3
                  style={{ fontWeight: "bold", color: "#E31D35" }}
                  className="text-center"
                >
                  HEMOS DISEÑADO UN INSTRUCTIVO DEL USO DE LA CALCULADORA.
                </h3>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col
                className=" d-flex justify-content-sm-center justify-content-md-end align-items-center "
                sm="12"
                md="6"
              >
                <Link
                  className="btn"
                  to={pdf}
                  download="Manual_de_usuario"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    backgroundImage: `url(${ButtonL})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                >
                  <span className="text-white" style={{ fontWeight: "bold" }}>
                    DESCARGA EL INSTRUCTIVO <br />{" "}
                    <span className="text-danger">
                      Todo lo que necesitas para calcular <br /> la huella de
                      carbono
                    </span>
                  </span>
                </Link>
              </Col>
              <Col
                className=" d-flex justify-content-sm-center justify-content-md-start align-items-center "
                sm="12"
                md="6"
              >
                <Link
                  to="/formulario"
                  className="btn  py-2 "
                  style={{
                    backgroundImage: `url(${ButtonL})`,
                    backgroundPosition: "",
                    backgroundSize: "cover",
                  }}
                >
                  <span
                    className="text-danger py-3  "
                    style={{ fontWeight: "bold" }}
                  >
                    CALCULADORA <br />{" "}
                    <span className="text-white ">HUELLA DE CARBONO </span>
                  </span>
                </Link>
              </Col>
            </Row>
          </Container>
        </section>
        <section>
          <Container>
            <Row className="mt-3  g-3 d-flex justify-content-center align-items-center ">
              <Col
                sm="12"
                md="5"
                style={{ border: "2px solid #E31D35" }}
                className="d-flex align-items-center justify-content-center bg-white"
              >
                <p
                  className="text-center text-black py-3"
                  style={{ fontWeight: "bold" }}
                >
                  ESTA HERRAMIENTA GRATUITA TE PERMITIRÁ REGISTRAR, MEDIR Y
                  ENTENDER EL IMPACTO DE TU OFICINA, SALA DE ENSAYO, ESTUDIO DE
                  GRABACIÓN, GIRA, PRODUCCIÓN, CONCIERTO O FESTIVAL. LA
                  CALCULADORA FACILITA LA MEDICIÓN DEL CONSUMO DE ENNERGÍA,
                  AGUA, LA GENERACIÓN DE RESIDUOS, RECICLAJE, VIAJES Y
                  MATERIALES DE PRODUCCIÓN, TRANSFORMANDO ESTA INFORMACIÓN EN UN
                  REPORTE QUE TE PERMITIRÁ DEFINIR UNA ESTRATEGIA DE
                  SUSTENTABILIDAD PARA REDUCIR Y GESTIONAR TUS EMISIONES DE
                  CARBONO.
                </p>
              </Col>
              <Col
                sm="12"
                md="5"
                className="d-flex align-items-center justify-content-center "
              >
                <img
                  src={image1}
                  className="img-fluid  "
                  style={{ border: "10px solid #E31D35" }}
                />
              </Col>
            </Row>
            <Row className="d-flex justify-content-center align-items-center mt-3">
              <Col
                md="12"
                sm="6"
                className="d-flex justify-content-center align-items-center my-2"
              >
                <Instagram color="#E31D35" size={35} className="mx-1" />{" "}
                <span
                  className="h3 flex justify-content-center align-items-center text-black m-0 p-0"
                  style={{ fontWeight: "bold" }}
                >
                  LAMUSICADECLARA
                </span>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <section className="mt-3">
        <Container className="text-center p-0">
          <Row className="d-flex align-items-center justify-content-center">
            <Col sm="12" md="6">
              <h1 style={{ fontWeight: "bold" }} className="text-black">
                NO HAY FUTURO SI NO <br /> HACEMOS ALGO HOY
              </h1>
            </Col>
          </Row>
          <Row className="">
            <Col>
              <img src={divider} style={{ height: "10px" }} />
            </Col>
          </Row>
          <Row className="mt-3 d-flex align-items-center justify-content-center">
            <Col sm="12" md="6">
              <h5
                className="text-center px-5"
                style={{
                  fontWeight: "bold",
                  color: "#E31D35",
                  textTransform: "uppercase",
                }}
              >
                Se nos acabó el tiempo, la emergencia climática y ecológica es
                una realidad y las acciones que tomemos harán la diferencia para
                el futuro del planeta.
              </h5>
            </Col>
          </Row>
          <Row className="mt-3 d-flex align-items-center justify-content-center">
            <Col sm="12" md="6">
              <p className="text-black">
                Hacemos un llamado a todos los miembros de la industria de la
                música a que nos acompañen a que trabajemos juntos para realizar
                los cambios culturales y operacionales necesarios para
                contribuir a un futuro de carbono neutralidad.
              </p>
            </Col>
          </Row>
          <Row className="my-3 d-flex align-items-center justify-content-center ">
            <Col sm="12" md="6">
              <p style={{ fontWeight: "bold" }} className="text-black">
                Creemos en el poder de la música para promover cambios sociales
                necesarios para crear un futuro mejor.
              </p>
            </Col>
          </Row>
          <Row className="my-3 d-flex align-items-center justify-content-center ">
            <Col sm="12" md="6"></Col>
          </Row>
        </Container>
      </section>
      <footer className="" style={{ backgroundColor: "#E31D35" }}>
        <Row className="d-flex align-items-center justify-content-center position-relative mt-5 ">
          <Col
            sm="12"
            md="6"
            className="d-flex align-items-center justify-content-center position-absolute  "
          >
            <img src={Case} style={{ height: "450px" }} className="py-5" />
          </Col>
        </Row>
        <Row
          className=" px-2 py-5 d-flex align-items-center justify-content-center"
          style={{
            backgroundImage: `url(${bird2})`,
            backgroundPosition: "center",
            backgroundSize: "",
          }}
        >
          <Col
            sm="12"
            md="6"
            className="d-flex align-items-center justify-content-sm-center  justify-content-md-end gap-4 mb-2 mt-5"
          >
            <div>
              <img src={Fondos} style={{ height: "100px" }} />
            </div>
            <div>
              <img src={Gob} style={{ height: "100px" }} />
            </div>
          </Col>
          <Col
            sm="12"
            md="6"
            className="d-flex align-items-center justify-content-sm-center  justify-content-md-start gap-2 mb-2 mt-5 "
          >
            <div>
              <img src={footer1} style={{ height: "100px" }} />
            </div>
            <div className="">
              <img src={footer2} style={{ height: "50px" }} />
            </div>
          </Col>

          <Col sm="12">
            <div
              className="d-flex flex-row justify-content-center align-items-center gap-1 mt-4 cursor-pointer"
              onClick={() =>
                openInNewTab("https://www.instagram.com/lamusicadeclara/?hl=es")
              }
            >
              <Instagram color="white" size={35} />{" "}
              <span
                className="h3 text-white m-0 p-0"
                style={{ fontWeight: "bold" }}
              >
                LAMUSICADECLARA
              </span>
            </div>
          </Col>
          <Col
            sm="12"
            className="d-flex flex-col align-items-center justify-content-center mt-3"
          >
            <Link
              className="btn"
              style={{
                backgroundImage: `url(${ButtonL})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                fontWeight: "bold",
              }}
              to="/admin"
            >
              <span className="text-white px-3">IR A ADMINISTRACIÓN</span>
            </Link>
          </Col>
        </Row>
      </footer>
    </>
  );
};

export default Landing;
