import React from 'react'
import { User } from 'react-feather'
import { Row, Col } from 'reactstrap'
//import Attendees from '../../assets/icons/Asistentes.svg'

const EventAttendees = ({selected}) => {
    return (
        <Row className=' d-flex justify-content-center align-items-center w-100 h-100 p-2 ' style={{backgroundColor:'black', border:'3px #e21d34 solid'}}>
            <Row className=''>
                <Col className='d-flex justify-content-center mb-1'><User size={100} color='white'/></Col>
            </Row>
            <Row className='w-100'>
                <Col>
                    <h1 className='text-center text-white' style={{fontSize:'45px', fontWeight:'bold'}}>{selected.attendeesNumber}</h1>
                    <h5 className='text-center text-white' style={{ fontWeight:'bold'}}>Asistentes al evento</h5>
                </Col>
            </Row>
        </Row>
    )
}

export default EventAttendees