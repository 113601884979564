import React, {useContext, useState} from "react"
import { Col, Input, Row, Label, FormFeedback } from "reactstrap"
import { useFormContext, Controller } from "react-hook-form"
import { DataContext } from "../../../../Context/Context"
const Hotels = ({index}) => {
    // ** context
    const { control, watch, formState:{errors}, register} = useFormContext()

    // ** states
    const [resp, setResp] = useState(0)
// ** variables
    const hotelWasUsed = `[${index}.hotel.use]`
    const amountOfRooms = `[${index}.hotel.rooms]`
    const amountOfNights = `[${index}.hotel.nights]`

    // ** custom functions
    const {data1, usage} = useContext(DataContext)

    const calculateHotel = (declaration, context, index) => {
        let result = 0
        const info = declaration[index]?.hotel
        const cont = context?.eventDetails
        if (info) {
            if (cont) {
                const country = context?.eventDetails[index]?.eventCountry
                const rooms = declaration[index]?.hotel.rooms
                const nights = declaration[index]?.hotel.nights
                fetch(
                  `https://calculadoramde.cl/calculadora/api/valuesAPI/hotels/${country}`
                )
                  .then((response) => response.json())
                  .then((data) => {
                    result =
                      Number(data[0].emision) * Number(rooms) * Number(nights)
                    setResp(result)
                  })

                if (usage[index]) {

                    const obj =  {usage: resp / 1000}
                    usage[index].hotel = obj

                }
                return Number(resp / 1000)

            }

        }

    }
    const total = calculateHotel(watch(), data1, index)
    return (
        <Row className="" >
            <Row className=""><Col className="d-flex justify-content-end"><h5 className="text-success">+ {total > 1 ? `${total.toFixed(2)} TON ` : `${(total * 1000).toFixed(2)} Kg `} CO<sub>2</sub>e</h5></Col></Row>


            <Row className="input--main--container mt-1">
                <Col className="left--section" md='6' sm='12'>
                    <Row>
                        <h5>Uso de hotel</h5>
                        <small>Checkea en caso de haber utilizado un hotel para alojar al personal</small>
                    </Row>
                    <Row className="mt-1">
                        <div className="d-flex gap-1">
                            <div>

                                <input type='radio' className="form-check-input" value='true' {...register(hotelWasUsed, {required:true})}  />
                                <Label>Si</Label>
                            </div>
                            <div>

                                <input type='radio' className="form-check-input" value='false' {...register(hotelWasUsed, {required:true})}  />
                                <Label>No</Label>
                            </div>
                        </div>
                        <div className="mt-1">
                        </div>
                        {errors[index]?.hotel?.use?.types?.required && <small className='text-danger'>Por favor, selecciona si hubo o no uso de hotel</small>}

                    </Row>
                </Col>

                <Col className={`right--section mt-1  ${watch(hotelWasUsed) === 'true' ? '' : 'd-none'}`}>
                    <Row>
                        <Col sm='12' md='6'>
                            <Label>Habitaciones utilizadas</Label>

                            <Controller
                                id={amountOfRooms}
                                name={amountOfRooms}
                                control={control}
                                defaultValue='0'
                                rules={{required:true}}
                                render = {({field}) => (
                                    <Input type='number' name={amountOfRooms} invalid= {errors?.[amountOfRooms] && true} {...field}/>
                                )}
                            />

                            {errors?.[amountOfRooms]?.types?.required && <small className='text-danger'>Por favor, ingresa la cantidad de energía proveniente de la red eléctrica</small>}
                        </Col>
                        <Col sm='12' md='6'>
                            <Label>Cantidad de noches</Label>

                            <Controller
                                id={amountOfNights}
                                name={amountOfNights}
                                control={control}
                                defaultValue='0'
                                rules={{required:true}}
                                render = {({field}) => (
                                    <Input type='number' name={amountOfNights} invalid= {errors?.[amountOfNights] && true} {...field}/>
                                )}
                            />

                            {errors?.[amountOfNights]?.types?.required && <small className='text-danger'>Por favor, ingresa la cantidad de energía proveniente de la red eléctrica</small>}
                        </Col>
                    </Row>
                </Col>

            </Row>

        </Row>


    )
}

export default Hotels
