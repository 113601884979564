import React from 'react'
import { Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'

const Error = () => {
    return (
        <Row className=' d-flex justify-content-center align-items-center vh-100 '>
            <Row className='d-flex justify-content-center ' >
                <Row>
                    <Col>
                        <h1 className='text-center'>Whoops...</h1>
                        <p className='text-center'>Puede que no hayas rellenado el formulario</p>
                    </Col>
                </Row>
                <Row>
                    <Col className='d-flex justify-content-center align-items-center'>
                        <Link to='/'><p className='btn btn-primary'>Rellénalo aquí</p></Link>
                    </Col>

                </Row>
            </Row>

        </Row>
    )
}

export default Error