import {useState, useEffect} from 'react'

const useFetchElectricityInterval = (information, index) => {
    const [data, setData] = useState('')
    const [loading, setLoading] = useState(false)

    const fetchInterval = (information, ind) => {
        setLoading(true)
        if (information?.eventDetails) {
            const firstDate = new Date(information?.eventDetails?.[ind]?.startMontageDate)
            const secondDate = new Date(information?.eventDetails?.[ind]?.endUnmountDate)
            let firstMonth = (firstDate.getUTCMonth() + 1).toString()
            if (Number(firstMonth) < 10) {
                firstMonth = firstMonth.toString()
                firstMonth = `0${firstMonth}`
            }
            //const firstYear = firstDate.getUTCFullYear()
            let secondMonth = (secondDate.getUTCMonth() + 1).toString()
            if (Number(secondMonth) < 10) {
                secondMonth = secondMonth.toString()
                secondMonth = `0${secondMonth}`
            }
            //const secondYear = secondDate.getUTCFullYear()
            fetch(
              `https://calculadoramde.cl/calculadora/api/valuesAPI/electricity/2021`
            )
              .then((response) => response.json())
              .then((jsoned) => {
                setData(jsoned.emision)
                console.log("hice fetch de la data", jsoned.emision)
              })
            console.log('hice fetch de la data')
        }
        setLoading(false)
    }
    useEffect(() => {
        fetchInterval(information, index)
    }, [information])
    return [loading, data]
}

export default useFetchElectricityInterval