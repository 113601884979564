import React from 'react'
import { View, StyleSheet, Text } from '@react-pdf/renderer'
import emisiones from '../CSV/emisiones.json'

const styles = StyleSheet.create({
    table: {
        display: "table",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        width:'35%'
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableCol: {
        width: "40%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCell: {
        margin: "15px",
        marginTop: 5,
        fontSize: 8
    }

})

const TableEmisiones = () => {
    return (
        <View style={styles.table}>
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>CCH Nivel 1</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>CCH Nivel 2</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>CCH Nivel 3</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>CCH Nivel 4</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Contaminante</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Valor del FE</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Unidad del FE</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Origen del FE</Text>
                </View>

            </View>

            {emisiones.map((emision, index) => (

                <View style={styles.tableRow} key={`${index}-EMISION`}>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{emision['CCH Nivel 1']}</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{emision['CCH Nivel 2']}</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{emision['CCH Nivel 3']}</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{emision['CCH Nivel 4']}</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{emision['Contaminante']}</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{emision['Valor del FE']}</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{emision['Unidad del FE']}</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{emision['Origen del FE']}</Text>
                    </View>
                </View>
            ))}

        </View>
    )
}

export default TableEmisiones