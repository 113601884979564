import React, {useContext, useEffect} from "react"
import { Col, Input, Row, Label, FormFeedback, InputGroup } from "reactstrap"
import { useFormContext, Controller } from "react-hook-form"
import { DataContext } from "../../../../Context/Context"
import { calculateFood } from "../../../../Helpers/Calculations"

const Alimentation = ({index}) => {
    // ** context
    const { control, formState:{errors}, watch, register} = useFormContext()

    // ** states
    const {values, data1, usage} = useContext(DataContext)


// ** variables
    const foodWasDistributed = `[${index}.alimentos_bebidas.staff.wasDistributed]`
    const foodWasSold = `[${index }.alimentos_bebidas.attendees.wasSold]`
    const amountOfFood = `[${index }.alimentos_bebidas.staff.amount]`
    const foodUOM = `[${index }.alimentos_bebidas.staff.UOM]`
    const amountOfStaff = `[${index }.alimentos_bebidas.staff.amountOfStaff]`
    const total = calculateFood(watch(), index, data1, values)
    // ** custom functions

    useEffect(() => {
        const result = calculateFood(watch(), index, data1, values)
        usage[index].alimentation.usage = result

    }, [watch()])
    return (
        <Row>
            {/* INDIVIDUAL ROW*/}
            <Row className="input--main--container mt-1">
                <Row className=""><Col className="d-flex justify-content-end"><h5 className="text-success">+{total > 1 ? `${total.toFixed(2)} TON ` : `${(total * 1000).toFixed(2)} Kg `}CO<sub>2</sub>e</h5></Col></Row>

                <Col className="left--section" md='6' sm='12'>
                    <Row>
                        <h5>Comida repartida al equipo de producción y artistas</h5>
                        <small>Checkea en caso de haber repartido comida con el equipo de producción del evento y a los artistas</small>
                    </Row>
                    <Row className="mt-1">
                        <div className="d-flex gap-1">
                            <div>

                                <input type='radio' className="form-check-input" value='true' {...register(foodWasDistributed, {required:true})}  />
                                <Label>Si</Label>
                            </div>

                            <div>

                                <input type='radio' className="form-check-input" value='average' {...register(foodWasDistributed, {required:true})}  />
                                <Label>Sí, pero no tengo la cifra</Label>
                            </div>
                            <div>

                                <input type='radio' className="form-check-input" value='false' {...register(foodWasDistributed, {required:true})}  />
                                <Label>No</Label>
                            </div>
                        </div>
                        <div className="mt-1">
                        </div>
                        {errors[index]?.alimentos_bebidas?.wasDistributed?.types?.required && <small className='text-danger'>Por favor, selecciona si hubo o no distribución de comida al personal</small>}

                    </Row>
                </Col>


                <Col className={`right--section mt-1 ${watch(foodWasDistributed) === 'true' ? '' : 'd-none'}`}>
                    <Row>
                        <Col md='12' className="mb-1">
                            <Label>Cantidad de comida repartida <strong>(kg o raciones por persona)</strong></Label>

                            <InputGroup>
                                <Controller
                                    id={amountOfFood}
                                    name={amountOfFood}
                                    control={control}
                                    defaultValue='0'
                                    rules={{required:true}}
                                    render = {({field}) => (
                                        <Input type='number' name={amountOfFood} invalid= {errors[index]?.amountOfFood && true} {...field}/>
                                    )}
                                />
                                <select className=" input-group-text " {...register(foodUOM)} >
                                    <option selected>kg</option>
                                    <option>raciones</option>
                                </select>

                                {errors[index]?.amountOfFood?.types?.required && <small className='text-danger'>Por favor, digita la cantidad de comida total entregada a staff</small>}
                            </InputGroup>
                        </Col>

                        <Col md='12' className={`${watch(foodUOM) === 'raciones' ? '' : 'd-none' }`}>
                            <Label>Total de staff y artistas</Label>

                            <Controller
                                id={amountOfStaff}
                                name={amountOfStaff}
                                control={control}
                                defaultValue='0'
                                rules={{required:true}}
                                render = {({field}) => (
                                    <Input type='number' name={amountOfStaff} invalid= {errors[index]?.amountOfStaff && true} {...field}/>
                                )}
                            />

                            {errors[index]?.amountOfStaff?.types?.required && <small className='text-danger'>Por favor, digita la cantidad de comida total entregada a staff</small>}

                        </Col>

                    </Row>
                </Col>

                <Col className={`right--section mt-1 ${watch(foodWasDistributed) === 'average' ? '' : 'd-none'}`}>
                    <Row>
                        <Col md='12' className="mb-1">
                            <Label>Cantidad de trabajadores total <strong>(Artistas + staff)</strong></Label>


                            <Controller
                                id={amountOfStaff}
                                name={amountOfStaff}
                                control={control}
                                defaultValue='0'
                                rules={{required:true}}
                                render = {({field}) => (
                                    <Input type='number' name={amountOfStaff} invalid= {errors[index]?.amountOfStaff && true} {...field}/>
                                )}
                            />
                            {errors[index]?.amountOfStaff?.types?.required && <small className='text-danger'>Por favor, digita la cantidad de comida total entregada a staff</small>}

                        </Col>

                    </Row>
                </Col>

            </Row>
            {/* INDIVIDUAL ROW*/}
            <Row className="input--main--container mt-2">
                <Col className="left--section" md='6' sm='12'>
                    <Row>
                        <h5>Venta de alimentos al público</h5>
                        <small>Checkea en caso de haber vendido comida al público del evento</small>
                    </Row>
                    <Row className="mt-1">
                        <div className="d-flex gap-1">
                            <div>

                                <input type='radio' className="form-check-input" value='true' {...register(foodWasSold, {required:true})}  />
                                <Label>Si</Label>
                            </div>
                            <div>

                                <input type='radio' className="form-check-input" value='false' {...register(foodWasSold, {required:true})}  />
                                <Label>No</Label>
                            </div>
                        </div>
                        {errors[index]?.alimentos_bebidas?.wasSold?.types?.required && <small className='text-danger'>Por favor, selecciona si hubo o no venta al público</small>}

                        <div className="mt-1">
                        </div>
                    </Row>
                </Col>

            </Row>
        </Row>


    )
}

export default Alimentation
