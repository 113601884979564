import React from 'react'
import { View, StyleSheet, Text } from '@react-pdf/renderer'

const styles = StyleSheet.create({
    table: {
        display: "table",
        width: "70%",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableCol: {
        width: "50%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCell: {
        margin: "auto",
        marginTop: 5,
        fontSize: 10
    }

})

const Table4 = ({data1, usage, co2e, numberOfEvents }) => {
    console.log(data1, usage, co2e, numberOfEvents)
    const calculateTotal = (usage) => {
        const usages = {
            alimentation: 0,
            energy: 0,
            fuel: 0,
            fugitivas: 0,
            hotel: 0,
            merchandising: 0,
            artistTransfer: 0,
            chargeTransfer: 0,
            waste: 0,
            water: 0
        }

        for (let i = 0; i < numberOfEvents; i++) {
            usages.alimentation += Number(usage[i].alimentation.usage)
            usages.energy += Number(usage[i].energy.usage)
            usages.fuel += Number(usage[i].fuel.total)
            usages.hotel += Number(usage[i].hotel.usage)
            usages.merchandising += Number(usage[i].merchandising.usage)
            usages.artistTransfer += Number(usage[i].transfer.traslado_artistas)
            usages.chargeTransfer += Number(usage[i].transfer.traslado_carga)
            usages.waste += Number(usage[i].waste.compostaje) +  Number(usage[i].waste.reciclaje) + Number(usage[i].waste.residuos)
            usages.water += Number(usage[i].water.agua_noria) + Number(usage[i].water.agua_red) + Number(usage[i].water.agua_camion)
        }
        return usages

    }
    return (
        <View style={styles.table}>
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Cadena de valor / Fuente de emisión</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>tCO2e</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>%</Text>
                </View>
            </View>
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Consumo de agua</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{Number(calculateTotal(usage, data1).water).toFixed(2)}</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{(((calculateTotal(usage, data1).water) / (co2e)) * 100).toFixed(2)}</Text>
                </View>
            </View>

            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Transporte de artistas</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{Number(calculateTotal(usage, data1).artistTransfer).toFixed(2)}</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{(((calculateTotal(usage, data1).artistTransfer) / (co2e)) * 100).toFixed(2)}</Text>
                </View>
            </View>
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Transporte de carga</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{Number(calculateTotal(usage, data1).chargeTransfer).toFixed(2)}</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{(((calculateTotal(usage, data1).chargeTransfer) / (co2e)) * 100).toFixed(2)}</Text>
                </View>
            </View>
            

            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Uso de combustible</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{Number(calculateTotal(usage, data1).fuel).toFixed(2)}</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{(((calculateTotal(usage, data1).fuel) / (co2e)) * 100).toFixed(2)}</Text>
                </View>
            </View>

            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Adquisición de electricidad red</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{Number(calculateTotal(usage, data1).energy).toFixed(2)}</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{(((calculateTotal(usage, data1).energy) / (co2e)) * 100).toFixed(2)}</Text>
                </View>
            </View>

            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Alimentación</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{Number(calculateTotal(usage, data1).alimentation).toFixed(2)}</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{(((calculateTotal(usage, data1).alimentation) / (co2e)) * 100).toFixed(2)}</Text>
                </View>
            </View>
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Hoteles</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{Number(calculateTotal(usage, data1).hotel).toFixed(2)}</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{(((calculateTotal(usage, data1).hotel) / (co2e)) * 100).toFixed(2)}</Text>
                </View>
            </View>
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Residuos</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{Number(calculateTotal(usage, data1).waste).toFixed(2)}</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{(((calculateTotal(usage, data1).waste) / (co2e)) * 100).toFixed(2)}</Text>
                </View>
            </View>
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Merchandising</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{Number(calculateTotal(usage, data1).merchandising).toFixed(2)}</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{(((calculateTotal(usage, data1).merchandising) / (co2e)) * 100).toFixed(2)}</Text>
                </View>
            </View>

            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>TOTAL</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{Number(co2e).toFixed(2)}</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>100%</Text>
                </View>
            </View>

        </View>
    )
}

export default Table4