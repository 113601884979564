import React from 'react'
import lmd from '../../../assets/Images/lmd.png'
import EmisionBars from '../../Charts/EmisionBars'
import EmisionLegend from '../../Charts/EmisionLegend'
import WaterLegend from '../../Charts/WaterLegend'
import WaterBars from '../../Charts/WaterBars'
import { Row, Col } from 'reactstrap'
import GeneralData from '../../Summary/GeneralData'
import EventDaysResume from '../../Summary/EventDaysResume'
import Squares from '../../Summary/Squares'

const Dashboard = ({name, usage, selected, data, referencia}) => {
    return (
        <Row className=' position-relative h-100 w-100 d-flex align-items-center justify-content-center mx-auto' ref={referencia} >
            <Col sm='12' md='10' lg='11' className=''>
                <Row className='bg-white mb-3 rounded p-3 ' md='12'>
                    <Col className='d-flex flex-column gap-2 '>
                        <Row className='d-flex gap-2'>
                            <Col className='d-flex flex-column justify-content-center align-items-center p-2' style={{border:'3px black solid'}} sm='12' md='4'>
                                <h3 className='text-center text-black' style={{fontWeight:'bold'}}>Viendo información de:</h3>
                                <h4>{name}</h4>
                                <p>{data.eventDetails[selected].eventIdentificator}</p>

                                <p className='mt-1' style={{fontWeight:'bold'}}>¿Se te olvidó algun evento?</p>
                                <button className='btn' style={{backgroundColor:'#e21d34', color:'white'}}>Rellena el formulario nuevamente</button>
                            </Col>

                            <Col style={{border:'3px #e21d34 solid'}} >
                                <GeneralData selected={data?.carbonDeclaration[selected]}/>
                            </Col>
                        </Row>

                        <Row className='d-flex gap-2 match-height'>
                            <Col sm='12' md='4' className='d-flex flex-column gap-2 '>
                                <Row className='match-height'>
                                    <Col >
                                        <Squares selected={data.eventDetails[selected]}/>
                                    </Col>
                                </Row>

                                <Row className='match-height h-100 ' >
                                    <Col className='p-0'>
                                        <EventDaysResume selected={data.eventDetails[selected]}/>
                                    </Col>
                                </Row>
                            </Col>

                            <Col  style={{border:'3px #e21d34 solid'}} className='p-0 ' >
                                <Row className='d-flex align-items-center justify-content-center h-100 m-0 p-1 '>

                                    <Col className='d-flex align-items-center justify-content-center'>
                                        <div className='d-flex flex-column gap-1 w-100 p-2 '>
                                            <h5 className='text-center text-black' style={{fontWeight:'bold'}}>Emisión de CO2 por fuente <br/> (Toneladas por Co2e)</h5>
                                            <EmisionBars usage={usage} selected={0} data1={data}/>
                                            <div className='d-flex flex-column align-items-start '>
                                                <EmisionLegend/>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col className='d-flex align-items-start justify-content-center h-100'>
                                        <div className='d-flex flex-column  gap-1 p-2 h-100 '>
                                            <h5 className='text-center text-black' style={{fontWeight:'bold'}}>Utilización hídrica <br/> (Litros)</h5>
                                            <WaterBars usage={usage} selected={0} data1={data}/>
                                            <div className='d-flex flex-column align-items-start '>
                                                <WaterLegend/>
                                            </div>
                                            <div className='d-flex flex-column align-items-center justify-content-end h-100'>
                                                <img src={lmd} style={{width:'350px'}} className='mb-2'/>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>

        </Row>
    )
}
//hello copilot

export default Dashboard