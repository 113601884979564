import React, {useContext, useState, useEffect, useRef} from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Container, Spinner, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import EventDaysResume from '../Components/Summary/EventDaysResume'
import GeneralData from '../Components/Summary/GeneralData'
import Squares from '../Components/Summary/Squares'
import { DataContext } from '../Context/Context'
import html2canvas from 'html2canvas'
import { XLSXCreator } from '../Helpers/XLSXCreator'
import XLSX from 'xlsx'
import lmd from '../assets/Images/lmd.png'
import Error from './Error'
import EmisionBars from '../Components/Charts/EmisionBars'
import EmisionLegend from '../Components/Charts/EmisionLegend'
import WaterLegend from '../Components/Charts/WaterLegend'
import WaterBars from '../Components/Charts/WaterBars'
import { PDFDownloadLink } from '@react-pdf/renderer'
import PDFDocument from '../Components/PDF/Document/PDFDocument'

const Summary = () => {
  const {data1, numberOfEvents, usage, co2e} = useContext(DataContext)
  const [selected, setSelected] = useState(0)
  const [names, setNames] = useState([])
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen)
  }
  const [posted, setPosted] = useState(false)

  useEffect(() => {
    const dataPost = {...data1, usage}

    if (!posted) {
      fetch("https://calculadoramde.cl/calculadora/api/formAPI/insert", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(dataPost)
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data)
          setPosted(true)
        })
        .catch((e) => console.log(e))
    }
    console.log(dataPost)
    const newArray = []
    if (data1.eventDetails) {
      for (let i = 0; i < numberOfEvents; i++) {
        newArray.push(data1.eventDetails[i].eventIdentificator)
      }
      setNames(newArray)
    }
  }, [])

  const printRef =  useRef()

  const handleDownloadImage = async (index) => {
    setSelected(index)
    const element = document.getElementById('print'),
        canvas = await html2canvas(element, {backgroundColor:'#ffffff', windowHeight:1080, windowWidth:1920, removeContainer:false}),
        data = canvas.toDataURL('image/png'),
        link = document.createElement('a')
    link.href = data
    link.download = `Dashboard-${names[index]}`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

  }

  const downloadxls = async (e, data1, numberOfEvents) => {
    e.preventDefault()
    const sections = [
      {
        Sección: "Uso de energía",
        "Total Co2e (TON)": 0
      },
      {
        Sección: "Consumo de agua",
        "Total Co2e (TON)": 0
      },
      {
        Sección: "Combustible",
        "Total Co2e (TON)": 0
      },
      {
        Sección: "Traslado de carga",
        "Total Co2e (TON)": 0
      },
      {
        Sección: "Traslado de artistas",
        "Total Co2e (TON)": 0
      },
      {
        Sección: "Traslado de asistentes",
        "Total Co2e (TON)": 0
      },
      {
        Sección: "Alimentación",
        "Total Co2e (TON)": 0
      },
      {
        Sección: "Hoteles",
        "Total Co2e (TON)": 0
      },
      {
        Sección: "Residuos",
        "Total Co2e (TON)": 0
      },
      {
        Sección: "Merchandising",
        "Total Co2e (TON)": 0
      },
      {
        Sección: "TOTAL",
        "Total Co2e (TON)": 0
      }
    ]
    for (let i = 0; i < numberOfEvents; i++) {
      sections[0]['Total Co2e (TON)'] += Number(usage[i].energy.usage)
      sections[1]['Total Co2e (TON)'] += Number(usage[i].water.agua_red) + Number(usage[i].water.agua_camion)
      sections[2]['Total Co2e (TON)'] += Number(usage[i].fuel.total)
      sections[3]['Total Co2e (TON)'] += Number(usage[i].transfer.traslado_carga)
      sections[4]['Total Co2e (TON)'] += Number(usage[i].transfer.traslado_artistas)
      sections[5]['Total Co2e (TON)'] += Number(usage[i].transfer.traslado_asistentes)
      sections[6]['Total Co2e (TON)'] += Number(usage[i].alimentation.usage)
      sections[7]['Total Co2e (TON)'] += Number(usage[i].hotel.usage)
      sections[8]['Total Co2e (TON)'] += Number(usage[i].waste.total)
      sections[9]['Total Co2e (TON)'] += Number(usage[i].merchandising.usage)

    }
    const total = sections.reduce((accum, item) => accum + item['Total Co2e (TON)'], 0)
    sections[10]['Total Co2e (TON)'] = total
    const wb = XLSX.utils.book_new()

    for (let i = 0; i < numberOfEvents; i++) {
      const country = data1.eventDetails[i].eventCountry
      const response = await fetch(
        `https://calculadoramde.cl/calculadora/api/valuesAPI/hotels/${country}`
      )
      const result = await response.json()
      const ws = XLSX.utils.json_to_sheet(XLSXCreator(data1, usage, i, result[0].usage))
      XLSX.utils.book_append_sheet(wb, ws, data1.eventDetails[i].eventIdentificator)
    }
    const auxiliarObj = XLSX.utils.json_to_sheet(sections)
    XLSX.utils.book_append_sheet(wb, auxiliarObj, 'Resumen')

    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, "Inventario-emisiones.xlsx")
  }

  return (
      <>
        {data1.carbonDeclaration && (
            <Row className=' d-flex justify-content-center align-items-center  mt-3' >
              <Row className='d-flex justify-content-center h-100' ref={printRef} id='print'>
                <Col sm='12' md='10' lg='11' className=''>
                  <Row className='bg-white mb-3 rounded p-3 ' md='12'>
                    <Col className='d-flex flex-column gap-2 '>
                      <Row className='d-flex gap-2'>
                        <Col className='d-flex flex-column justify-content-center align-items-center p-2' style={{border:'3px black solid'}} sm='12' md='4'>
                          <h3 className='text-center text-black' style={{fontWeight:'bold'}}>Viendo información de:</h3>
                          <h5 className='text-center'>Selecciona a continuación el evento</h5>
                          <select className="form-select mt-1 mw-50 border border-danger text-center fw-bold" onChange = {e => setSelected(e.target.value)}>
                            {names.map((name, index) => (<option value={index}>{name}</option>))} </select>

                          <p className='mt-1' style={{fontWeight:'bold'}}>¿Se te olvidó algun evento?</p>
                          <Link className='btn' style={{backgroundColor:'#e21d34', color:'white'}} to='/formulario'>Rellena el formulario nuevamente</Link>
                        </Col>

                        <Col style={{border:'3px #e21d34 solid'}} >
                          <GeneralData selected={data1?.carbonDeclaration[selected]}/>
                        </Col>
                      </Row>

                      <Row className='d-flex gap-2 match-height'>
                        <Col sm='12' md='4' className='d-flex flex-column gap-2 '>
                          <Row className='match-height'>
                            <Col >
                              <Squares selected={data1?.eventDetails[selected]}/>
                            </Col>
                          </Row>

                          <Row className='match-height h-100 ' >
                            <Col className='p-0'>
                              <EventDaysResume selected={data1?.eventDetails[selected]}/>
                            </Col>
                          </Row>
                        </Col>

                        <Col  style={{border:'3px #e21d34 solid'}} className='p-0 ' >
                          <Row className='d-flex align-items-center justify-content-center h-100 m-0 p-1 '>

                            <Col className='d-flex align-items-center justify-content-center'>
                              <div className='d-flex flex-column gap-1 w-100 p-2 '>
                                <h5 className='text-center text-black' style={{fontWeight:'bold'}}>Emisión de CO2 por fuente <br/> (Toneladas por Co2e)</h5>
                                <EmisionBars usage={usage} selected={selected} data1={data1}/>
                                <div className='d-flex flex-column align-items-start '>
                                  <EmisionLegend/>
                                </div>
                              </div>
                            </Col>

                            <Col className='d-flex align-items-start justify-content-center h-100'>
                              <div className='d-flex flex-column  gap-1 p-2 h-100 '>
                                <h5 className='text-center text-black' style={{fontWeight:'bold'}}>Utilización hídrica <br/> (Litros)</h5>
                                <WaterBars usage={usage} selected={selected} data1={data1}/>
                                <div className='d-flex flex-column align-items-start '>
                                  <WaterLegend/>
                                </div>
                                <div className='d-flex flex-column align-items-center justify-content-end h-100'>
                                  <img src={lmd} style={{width:'350px'}} className='mb-2'/>
                                </div>
                              </div>
                            </Col>
                          </Row>

                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

              </Row>

              <Row className='d-flex flex-row align-items-center justify-content-center w-100'  >
                <Col sm='12' md='11' className=''>
                  <Row className='d-flex align-items-center justify-content-center'>
                    <Col className='d-flex flex-column align-items-center justify-content-center gap-3 mb-3  card p-3' sm='12' md='12'>
                      <div className='d-flex gap-2'>
                        <ButtonDropdown isOpen={dropdownOpen} toggle={toggleDropdown} className=''   >
                          <DropdownToggle caret className='btn btn-primary' color='primary' >
                            Descargar Dashboard
                          </DropdownToggle>
                          <DropdownMenu className='w-100 rounded'>
                            {names.map((el, index) => <DropdownItem  onClick={() => handleDownloadImage(index)} className='w-100'>{el}</DropdownItem>)}

                          </DropdownMenu>
                        </ButtonDropdown>
                        <button className='btn btn-primary' onClick={(e) => downloadxls(e, data1, numberOfEvents)}>Descarga Inventario de Emisiones</button>
                        <PDFDownloadLink document={<PDFDocument data1={data1} usage={usage} co2e={co2e} numberOfEvents={numberOfEvents}/>} fileName={`declaracion-${data1.generalEventData.eventName}.pdf`} className='btn btn-primary'>
                          {({ loading}) => (loading ? (<><Spinner size='sm'/><span> Cargando documento...</span></>) : `Descargar reporte Huella Chile`)}
                        </PDFDownloadLink>
                      </div>
                    </Col>
                  </Row>
                </Col>

              </Row>
            </Row>
        )}
        {!data1.carbonDeclaration && (
            <Error/>
        )}

      </>
  )
}

export default Summary