import React, {useState, useMemo} from 'react'
import { Table, Badge, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle, Button, Spinner } from 'reactstrap'
import DownloadButton from '../Components/DownloadButton'
import EliminateButton from '../Components/EliminateButton'
import ValidateButton from '../Components/ValidateButton'
import DownloadSheet from '../Components/DownloadSheet'
import DownloadDashboard from '../Components/DownloadDashboard'


const AdminTable = ({data, setData, setLoading}) => {
    console.log(data)
    const [filter, setFilter] = useState('')

    const filteredEvents = useMemo(
        () => data?.filter(evento => {
            const eventName = evento.generalEventData.eventName.toLowerCase().includes(filter.toLowerCase())
            const declarantName = evento.userData.name.toLowerCase().includes(filter.toLowerCase())


            return ((eventName || declarantName))
        }).map((event, index) => (
            <tr key={event.generalEventData.id}>
                <td><span>{index}</span></td>
                <td><span>{event.generalEventData.eventName}</span></td>
                <td><span>{event.userData.name} / {event.userData.razonSocial}</span></td>
                <td><span className='text-success'>{event.generalEventData.totalCO2}</span></td>
                <td className=' h-100'><DownloadButton className='h-100'  id={event.generalEventData.id} index={index} data={data[index]}/></td>
                <td><DownloadSheet data={event} index={index}/></td>
                <td><DownloadDashboard data={event} index={index}/></td>
                <td><EliminateButton id={event.generalEventData.id} index={index} eventName = {event.generalEventData.eventName} setData={setData} setLoading={setLoading}/></td>
                <td><ValidateButton id={event.generalEventData.id} index={index} data={event} setData={setData} setLoading={setLoading}/></td>
            </tr>
        )),
        [filter]
    )
    return (<>
            {data && (
                <div className='d-flex flex-column gap-1 h-100   '>

                    <div className='p-1 d-flex align-items-start justify-content-start '>
                        <input className='form-control' placeholder='Filtra por nombre del evento o nombre del declarante' onChange={e => setFilter(e.target.value)}  />

                    </div>
                    <Table className=''>
                        <thead>
                        <tr>
                            <th>Número</th>
                            <th>Nombre del evento</th>
                            <th>Declarante</th>
                            <th>tCO2e</th>
                            <th>Descargar Declaración H.C.</th>
                            <th>Descargar I. Emisiones</th>
                            <th>Descargar Dashboard</th>
                            <th>Eliminar</th>
                            <th>Validar</th>

                        </tr>
                        </thead>
                        <tbody>
                        {
                            filteredEvents
                        }

                        </tbody>
                    </Table>
                </div>
            )
            }
            {!data && <Spinner/>}
        </>
    )
}

export default AdminTable