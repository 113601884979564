// ** React Imports
import { BrowserRouter } from 'react-router-dom'
import { Spinner } from 'reactstrap'
// ** Toast
import { Toaster } from 'react-hot-toast'
import ReactDOM from 'react-dom/client'

// ** Spinner (Splash Screen)

import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Hot Toast Styles
import '@styles/react/libs/react-hot-toasts/react-hot-toasts.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'

import App from './App'

// ** Lazy load app

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App/>)
