import React, {useContext, useEffect} from "react"
import { Col, Input, Row, Label, FormFeedback, Button, InputGroup } from "reactstrap"
import { Controller, useFieldArray, useFormContext } from "react-hook-form"
import { Plus, Minus } from "react-feather"
import { DataContext } from "../../../../Context/Context"


const Merchandising = ({index}) => {
  const {
    control,
    formState: { errors },
    watch,
    register
  } = useFormContext()


  const {
    fields: merchandisingFields,
    append: merchandisingAppend,
    remove: merchandisingRemove
  } = useFieldArray({ control, name: `[${index}.merchandising.merchandisingFields]` })
  const merchandising = `[${index}.merchandising.use]`
  const {values, usage, data1} = useContext(DataContext)

  const calculateMerchandising = (data, index, context) => {
    let total1 = 0
    const info = data[index]?.merchandising
    if (data[index]?.merchandising) {

      if (data[index]?.merchandising.use === 'false') {
        total1 = 0
        return total1
      } else if (data[index]?.merchandising.use === 'average' && info) {
        total1 = (Number(context.eventDetails[index].attendeesNumber) * values?.promedio_merch_persona / 1000)
      } else if (data[index]?.merchandising.use === 'true') {
        if (info) {
          const totalsize = Number(info.merchandisingFields.length)
          if (totalsize < 1) {
            return 0
          } else {
            for (let i = 0; i < totalsize; i++) {
              let weight = Number(data[index]?.merchandising?.merchandisingFields[i].weight)
              const material = data[index]?.merchandising?.merchandisingFields[i].material
              if (values?.[material]) {
                const uom = data[index]?.merchandising?.merchandisingFields[i].UOM
                const amount = Number(data[index]?.merchandising?.merchandisingFields[i].amount)
                if (uom === 'kg') weight = Number(Number(weight) / 1000)
                total1 += ((Number(weight) * Number(values?.[material]) * Number(amount)) / 1000)

              } else {
                return 0
              }
            }
          }
        }
      }
    }

    return (total1)
  }

  const total = calculateMerchandising(watch(), index, data1)
  useEffect(() => {
    const result = calculateMerchandising(watch(), index, data1)
    usage[index].merchandising = {usage:result}
  }, [watch()])
  return (
      <Row>
        <Row className=""><Col className="d-flex justify-content-end"><h5 className="text-success">+ {total > 1 ? `${total.toFixed(2)} TON ` : `${(total * 1000).toFixed(2)} Kg `}CO<sub>2</sub>e</h5></Col></Row>

        <Row className="input--main--container mt-1">
          <Col className="left--section" md='6' sm='12'>
            <Row>
              <h5>Merchandising</h5>
              <small>Checkea si hubo merchandising</small>
            </Row>
            <Row className="mt-1">
              <div className="d-flex gap-1">
                <div>

                  <input type='radio' className="form-check-input" value='true' {...register(merchandising, {required:true})}  />
                  <Label>Si</Label>
                </div>

                <div>

                  <input type='radio' className="form-check-input" value='average' {...register(merchandising, {required:true})}  />
                  <Label>Sí, pero no tengo la cifra</Label>
                </div>
                <div>

                  <input type='radio' className="form-check-input" value='false' {...register(merchandising, {required:true})}  />
                  <Label>No</Label>
                </div>
              </div>
              <div className="mt-1">
              </div>
              {errors[index]?.merchandising?.use?.types?.required && <small className='text-danger'>Por favor, selecciona si hubo o no merchandising</small>}

            </Row>
          </Col>

          <Col className={`right--section mt-1 ${watch(merchandising) === 'true' ? '' : 'd-none'}`}>
            <>
              {merchandisingFields.map((item, index1) => {
                return (
                    <Row className="mt-1">
                      <Col className="d-flex flex-column gap-1"
                           sm="12"
                           md="12"
                           key={item.id}>
                        <Row>
                          <Col>
                            <Label>Material</Label>
                            <select className="form-select" {...register(`[${index}.merchandising.merchandisingFields[${index1}].material]`)} control={control}>
                              <option value='' selected disabled>Selecciona el material</option>
                              <option value='merch_madera'>Madera</option>
                              <option value='merch_metal_latas_laminas_aluminio'>Latas y láminas de aluminio</option>
                              <option value='merch_metal_latas_mixtas'>Latas mixtas</option>
                              <option value='merch_textiles'>Textiles</option>
                              <option value='merch_carton'>Cartón</option>
                              <option value='merch_papel'>Papel</option>
                              <option value='merch_mezcla_papel_carton'>Mixto: papel - cartón</option>
                              <option value='merch_plastico_promedio'>Plástico</option>
                            </select>
                          </Col>

                        </Row>
                        <Row>
                          <Col>
                            <Label>Peso unitario</Label>
                            <InputGroup>
                              <select className=" input-group-text " {...register(`[${index}.merchandising.merchandisingFields[${index1}].UOM]`)} >
                                <option selected>kg</option>
                                <option>TON</option>
                              </select>
                              <Controller
                                  name={`[${index}.merchandising.merchandisingFields[${index1}].weight]`}
                                  control={control}
                                  defaultValue= '0'
                                  rules={{required:true}}
                                  render = {({field}) => (
                                      <Input type='number' name={`[${index}.merchandising.merchandisingFields[${index1}].weight]`} {...field}/>
                                  )}
                              />
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Label>Cantidad</Label>
                            <Controller
                                control={control}
                                name={`[${index}.merchandising.merchandisingFields[${index1}].amount]`}
                                defaultValue=''
                                render={({ field }) => (
                                    <Input
                                        type="number"
                                        placeholder="100"
                                        name={`[${index}.merchandising.merchandisingFields[${index1}].amount]`}
                                        {...field}
                                    />
                                )}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Row>
                        <Col>
                          <Button
                              color="flat-secondary"
                              className="btn-icon "
                              size='sm'
                              onClick={() => { merchandisingRemove(index1) }}>
                            <span className="align-middle ms-25">Eliminar campo</span>
                          </Button>
                        </Col>
                      </Row>
                    </Row>
                )
              })}
              <Col className="mt-1">
                <Button
                    className="btn-icon"
                    size='sm'
                    color="primary"
                    onClick={() => {
                      merchandisingAppend({origin:'', material:'', amount:'' })
                    }}
                >
                  <Plus size={14} color='white'/>
                  <span className="align-middle ms-25">Agregar campo</span>
                </Button>
              </Col>
            </>
          </Col>

        </Row>
      </Row>
  )
}

export default Merchandising