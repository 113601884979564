import React, { useEffect, useContext} from "react"
import { Col, Input, Row, Label, FormFeedback, InputGroup } from "reactstrap"
import { Controller, useFormContext} from "react-hook-form"
import { DataContext } from "../../../../Context/Context"
import { calculateWater } from "../../../../Helpers/Calculations"


const Water = ({index}) => {
  const { control, formState:{errors}, watch, register} = useFormContext()
  const useOfNetworkWater = `[${index}.agua.agua_red.use]`
  const usageOfNetworkWater = `[${index}.agua.agua_red.usage]`
  const useOfPurchasedWater = `[${index}.agua.agua_camion.use]`
  const usageOfPurchasedWater = `[${index}.agua.agua_camion.usage]`
  const usageOfWaterHole = `[${index}.agua.agua_noria.usage]`
  const useOfWaterHole = `[${index}.agua.agua_noria.use]`
  const networkWaterUOM = `[${index}.agua.agua_red.UOM]`
  const purchasedWaterUOM = `[${index}.agua.agua_camion.UOM]`
  const waterHoleUOM = `[${index}.agua.agua_noria.UOM]`
  const {values, usage, data1} = useContext(DataContext)

  const total = calculateWater(watch(), index, values, data1).lastResult
  useEffect(() => {
    const {result} = calculateWater(watch(), index, values, data1)
    usage[index].water = result

  }, [watch()])
  return (
      <Row className="parent--row d-flex gap-2">
        <Row className=""><Col className="d-flex justify-content-end"><h5 className="text-success">+ {total > 1 ? `${(total).toFixed(2)} TON ` : `${(total * 1000).toFixed(2)} Kg `}CO<sub>2</sub>e</h5></Col></Row>
        <Row className="input--main--container mt-1">
          <Col className="left--section" md='6' sm='12'>
            <Row>
              <h5>Consumo de la red de distribución de agua</h5>
              <small>Checkea en caso de haber utilizado agua proveniente de la red</small>
            </Row>
            <Row className="mt-1">
              <div className="d-flex gap-1">
                <div>

                  <input type='radio' className="form-check-input" value='true' {...register(useOfNetworkWater, {required:true})}  />
                  <Label>Si</Label>
                </div>
                <div>

                  <input type='radio' className="form-check-input" value='average' {...register(useOfNetworkWater, {required:true})}  />
                  <Label>Sí, pero no tengo la cifra</Label>
                </div>
                <div>
                  <input type='radio' className="form-check-input" value='false' {...register(useOfNetworkWater, {required:true})}  />
                  <Label>No</Label>
                </div>
              </div>
              <div className="mt-1">
              </div>
              {errors[index]?.agua?.agua_red?.use?.types?.required && <small className='text-danger  mt-1'>Por favor, selecciona si hubo o no uso de la red de distribución eléctrica</small>}

            </Row>
          </Col>

          <Col className={`right--section mt-1 ${watch(useOfNetworkWater) === 'true' ? '' : 'd-none'}`}>
            <Label>Consumo de la red de distribución de agua <strong>(m<sup>3</sup> o litros)</strong></Label>

            <InputGroup>
              <Controller
                  id={usageOfNetworkWater}
                  name={usageOfNetworkWater}
                  defaultValue='0'
                  control={control}
                  rules={{required:true}}
                  render = {({field}) => (
                      <Input type='number' name={usageOfNetworkWater} invalid= {errors[index]?.usageOfNetworkWater && true} {...field}/>
                  )}
              />
              <select className=" input-group-text " {...register(networkWaterUOM)} >
                <option selected>LT</option>
                <option>m³</option>
              </select>
            </InputGroup>

            {errors[index]?.usageOfNetworkWater?.types?.required && <small className='text-danger'>Por favor, ingresa la cantidad de agua utilizada proventiente de la red</small>}
          </Col>

        </Row>

        <Row className="input--main--container mt-1">
          <Col className="left--section" md='6' sm='12'>
            <Row>
              <h5>Agua transportada</h5>
              <small>Checkea en caso de haber transportado agua</small>
            </Row>
            <Row className="mt-1">
              <div className="d-flex gap-1">
                <div>

                  <input type='radio' className="form-check-input" value='true' {...register(useOfPurchasedWater, {required:true})}  />
                  <Label>Si</Label>
                </div>

                <div>

                  <input type='radio' className="form-check-input" value='average' {...register(useOfPurchasedWater, {required:true})}  />
                  <Label>Sí, pero no tengo la cifra</Label>
                </div>
                <div>

                  <input type='radio' className="form-check-input" value='false' {...register(useOfPurchasedWater, {required:true})}  />
                  <Label>No</Label>
                </div>
              </div>
              <div className="mt-1">
              </div>
              {errors[index]?.agua?.agua_camion?.use?.types?.required && <small className='text-danger  mt-1'>Por favor, selecciona si hubo o no uso uso de agua transportada</small>}
            </Row>
          </Col>

          <Col className={`right--section mt-1 ${watch(useOfPurchasedWater) === 'true' ? '' : 'd-none'}`}>
            <Label>Consumo de agua transportada <strong>(m<sup>3</sup> o litros)</strong></Label>

            <InputGroup>
              <Controller
                  id={usageOfPurchasedWater}
                  name={usageOfPurchasedWater}
                  control={control}
                  defaultValue='0'
                  rules={{required:true}}
                  render = {({field}) => (
                      <Input type='number' name={usageOfPurchasedWater} invalid= {errors[index]?.usageOfPurchasedWater && true} {...field}/>
                  )}
              />
              <select className=" input-group-text " {...register(purchasedWaterUOM)} >
                <option selected>LT</option>
                <option>m³</option>
              </select>
            </InputGroup>

            {errors[index]?.usageOfPurchasedWater?.types?.required && <small className='text-danger'>Por favor, ingresa la cantidad de agua transportada</small>}
          </Col>

        </Row>

        <Row className="input--main--container mt-1">
          <Col className="left--section" md='6' sm='12'>
            <Row>
              <h5>Consumo de agua proveniente de pozo / noria</h5>
              <small>Checkea en caso de haber utilizado agua proveniente de pozo o noria</small>
            </Row>
            <Row className="mt-1">
              <div className="d-flex gap-1">
                <div>

                  <input type='radio' className="form-check-input" value='true' {...register(useOfWaterHole, {required:true})}  />
                  <Label>Si</Label>
                </div>
                <div>

                  <input type='radio' className="form-check-input" value='average' {...register(useOfWaterHole, {required:true})}  />
                  <Label>Sí, pero no tengo la cifra</Label>
                </div>
                <div>

                  <input type='radio' className="form-check-input" value='false' {...register(useOfWaterHole, {required:true})}  />
                  <Label>No</Label>
                </div>
              </div>
              <div className="mt-1">
              </div>
            </Row>
            {errors[index]?.agua?.agua_noria?.use?.types?.required && <small className='text-danger  mt-1'>Por favor, selecciona si hubo o no uso de agua proveniente de pozo o noria</small>}
          </Col>

          <Col className={`right--section mt-1 ${watch(useOfWaterHole) === 'true' ? '' : 'd-none'}`}>
            <Label>Consumo de la agua proveniente de pozo / noria <strong>(m<sup>3</sup> o litros)</strong></Label>

            <InputGroup>
              <Controller
                  id={usageOfWaterHole}
                  name={usageOfWaterHole}
                  control={control}
                  defaultValue='0'
                  rules={{required:true}}
                  render = {({field}) => (
                      <Input type='number' name={usageOfWaterHole} invalid= {errors[index]?.usageOfWaterHole && true} {...field}/>
                  )}
              />
              <select className=" input-group-text " {...register(waterHoleUOM)} >
                <option selected>LT</option>
                <option>m³</option>
              </select>
            </InputGroup>

            {errors[index]?.usageOfWaterHole?.types?.required && <FormFeedback>Por favor, ingresa la cantidad de agua utilizada proventiente de la red</FormFeedback>}
          </Col>

        </Row>
      </Row>
  )
}

export default Water
