
export const calculateWater = (data1, index, values, context) => {
  const info = data1[index]?.agua

  if (info  && context.eventDetails) {
    const networkAverage = Number(context.eventDetails[index].attendeesNumber) * values.promedio_agua_persona
    const transportedAverage = Number(context.eventDetails[index].attendeesNumber) * values.promedio_agua_persona
    let res1 = Number(data1[index]?.agua?.agua_red?.usage) * Number(values?.agua_red)
    if (data1[index]?.agua?.agua_red?.UOM === 'LT') res1 /= 1000
    if (data1[index]?.agua?.agua_red?.use === 'average') res1 = networkAverage

    let res2 = Number(data1[index]?.agua?.agua_camion?.usage) * Number(values?.agua_camion)
    if (data1[index]?.agua?.agua_camion?.UOM === 'LT') res2 /= 1000
    if (data1[index]?.agua?.agua_camion?.use === 'average') res2 = transportedAverage

    let res3 = Number(data1[index]?.agua?.agua_noria?.usage) * Number(0)
    if (data1[index]?.agua?.agua_noria?.UOM === 'LT') res3 /= 1000


    const lastResult = (Number(res1 / 1000) + Number(res2 / 1000) + Number(res3 / 1000))
    const result = {agua_red : (res1 / 1000), agua_camion: (res2 / 1000), agua_noria: (res3 / 1000), total: lastResult}
    return {lastResult, result}

  } else {
    const result = {agua_red : 0, agua_camion: 0, agua_noria: 0}
    const lastResult = 0
    return {lastResult, result}
  }
}

export  const calculateAirConditioning = (data1, index, values) => {
  let total1 = 0
  const AC = 2.45
  const info = data1[index]?.fugitives?.AC
  if (info) {
    const amount = data1[index]?.fugitives?.AC?.amount

    total1 = (Number(amount) * Number(values?.['AC_R410A']) * Number(AC) * 0.05)

  }
  return total1
}

export  const calculateFireExtinguishers = (data1, index, values) => {
  const info = data1[index]
  let totalFE = 0
  if (info) {
    const totalSize = info?.fugitives?.FE?.FEFields?.length
    if (totalSize < 1) {
      return 0
    }
    for (let i = 0; i < totalSize; i++) {
      if (!info?.fugitives?.FE?.FEFields[i]?.size) {
        return 0
      }
      if (!info?.fugitives?.FE?.FEFields[i]?.amount) {
        return 0
      }
      const size = info?.fugitives.FE?.FEFields[i]?.size
      const amount = info?.fugitives.FE?.FEFields[i]?.amount
      totalFE += Number(size) * Number(amount) * Number(values?.extintorCO2)

    }
    return totalFE
  }
}

export const general = (data1, index, values) => {
  const res1 = calculateAirConditioning(data1, index, values)
  const res2 = calculateFireExtinguishers(data1, index, values)
  const totalIndividual = {extintor: (res2 / 1000), aire_acondicionado: (res1 / 1000)}

  const totalGeneral = (Number(res1) + Number(res2)) / 1000
  return {totalGeneral, totalIndividual}
}

export  const calculateFood = (data1, index, context, values) => {

  const info = data1[index]?.alimentos_bebidas
  const ton_por_persona = Number(0.0003)
  const averageTrabajadores = values.promedio_alimentacion_trabajador
  const averageAsistentes = values.promedio_alimentacion_asistentes
  let total = 0
  let staffTotal = 0
  let attend = 0
  if (info) {
    if (info.staff?.wasDistributed === 'false') {
      staffTotal = 0
    } else {
      if (info.staff.wasDistributed === 'average') {
        staffTotal = averageTrabajadores * Number(info.staff.amountOfStaff)
      } else if (info.staff.UOM === 'kg') {
        staffTotal = Number(Number(data1[index]?.alimentos_bebidas?.staff?.amount) * Number(values?.alimentos_bebidas) / 1000)
      } else if (data1[index]?.alimentos_bebidas?.staff?.UOM === 'raciones') {
        staffTotal =  Number(Number(data1[index]?.alimentos_bebidas?.staff?.amount) * Number(values?.alimentos_bebidas) * ton_por_persona * data1[index]?.alimentos_bebidas?.staff?.amountOfStaff) // numero * kgco2/ton * ton * numero
      }


    }

    total = staffTotal
    if (data1[index]?.alimentos_bebidas?.attendees?.wasSold === 'true') {
      attend = (Number(context?.eventDetails[index]?.attendeesNumber) * Number(averageAsistentes))
      total = Number(Number(staffTotal) + attend)
    }
    if (data1[index]?.alimentos_bebidas?.attendees?.foodWasSold === 'false') { total = 0 }

  }

  return (total / 1000)
}

export const calculateEnergy = (context, index, declaration, data, values) => {

  const cont = context?.eventDetails
  const info = declaration[index]?.energy
  console.log(data, declaration)

  if (cont) {
    if (info) {
      if (info.electricNetwork.use === 'false') {
        return 0
      }
      if (info.electricNetwork.use === 'average') {
        return ((Number(context.eventDetails[index].attendeesNumber) * Number(values.promedio_energia_persona)) / 1000)
      }
      let usage = Number(Number(declaration[index].energy.electricNetwork.usage))
      if (info.uom === 'kWh') {
        usage /= 1000
      }
      const result =  Number((data * usage))

      return result
    }
  }
  return 0

}
const calculateDays  = (startDate, endDate) => {
  const sDate = new Date(startDate)
  const eDate = new Date(endDate)
  const difference = eDate.getTime() - sDate.getTime()
  const divisor = 1000 * 3600 * 24
  const totalDays = Math.ceil(difference / divisor)
  if (totalDays === 0) return 1
  return totalDays
}
export const calculateFuel = (data1, index, values, context) => {

  if (data1[index]?.fuels && context.eventDetails && context.generalEventData) {
    const totalDays = calculateDays(context.eventDetails[index].startMontageDate, context.eventDetails[index].endUnmountDate)
    let hours = 0
    if (context.generalEventData.eventType === 'Gira') hours = 1.5 * totalDays
    if (context.generalEventData.eventType === 'Show en vivo') {
      if (context.generalEventData.eventCategory === ('Concierto' || 'Festival')) {
        hours = 10 * totalDays
      } else {
        hours = 1.5 * totalDays
      }
    }
    const dieselAverage = (Number(hours) * values?.promedio_diesel_hora_evento / 1000)
    let res4 = 0
    let res3 = 0

    if (data1[index]?.fuels?.GLP.GLPUOM === 'LT') {
      res3 = Number(data1[index]?.fuels?.GLP?.usage) * Number(values?.GLP)
    } else {
      res3 = (Number(data1[index]?.fuels?.GLP?.usage) * Number(values?.GLP_ton) / 1000)
    }
    if (data1[index]?.fuels?.GLP?.use === 'average') res3 = dieselAverage


    if (data1[index]?.fuels?.gas_natural.gas_naturalUOM === 'LT') {
      res4 = (Number(data1[index]?.fuels?.gas_natural?.usage) * Number(values?.gas_natural) / 1000)
    } else {
      res4 = (Number(data1[index]?.fuels?.gas_natural?.usage) * Number(values?.gas_natural_ton) / 1000)
    }
    if (data1[index]?.fuels?.gas_natural?.use === 'average') res4 = dieselAverage


    let res1 = Number(data1[index]?.fuels?.bencina?.usage) * Number(values?.bencina)
    if (data1[index]?.fuels?.bencina?.bencinaUOM === 'm³') res1 *= 1000
    if (data1[index]?.fuels?.bencina?.use === 'average') res1 = dieselAverage


    let res2 = Number(data1[index]?.fuels?.diesel.usage) * Number(values?.diesel)
    if (data1[index]?.fuels.diesel.dieselUOM === 'm³') res2 *= 1000
    if (data1[index]?.fuels?.diesel?.use === 'average') res2 = dieselAverage

    const totalfinal = (Number(res1) + Number(res2) + Number(res3) + Number(res4)) / 1000
    const total = {bencina: (res1 / 1000), diesel: (res2 / 1000), gas_natural: (res4 / 1000), GLP: (res3 / 1000), total:totalfinal}
    return {totalfinal, total}
  }
  return 0

}
