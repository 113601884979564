import React, {Fragment, useState} from 'react'
import { Label, Row, Col, Button, Form, Input, FormFeedback, Card, CardHeader, CardBody,  Accordion, AccordionBody, AccordionHeader, AccordionItem, UncontrolledTooltip} from 'reactstrap'
import { useFormContext, Controller } from 'react-hook-form'
import { HelpCircle } from 'react-feather'


const EventDetails = ({index, countries1}) => {

    const {formState:{errors, submitCount}, control, register, watch} = useFormContext()

    const countries = Object.keys(countries1)
    const [cities, setCities] = useState(countries1.Afghanistan)

    const eventAddress = `[${index}.eventAddress]`
    const attendeesNumber = `[${index}.attendeesNumber]`
    const eventCountry = `[${index}.eventCountry]`
    const eventCity = `[${index}.eventCity]`
    const startMontageDate = `[${index}.startMontageDate]`
    const endMontageDate = `[${index}.endMontageDate]`
    const startEventDate = `[${index}.startEventDate]`
    const endEventDate = `[${index}.endEventDate]`
    const startUnmountDate = `[${index}.startUnmountDate]`
    const endUnmountDate = `[${index}.endUnmountDate]`
    const eventIdentificator = `[${index}.eventIdentificator]`

    const errorFields = [eventAddress, attendeesNumber, eventCountry, eventCity, startMontageDate, endMontageDate, startEventDate, endEventDate, startUnmountDate, endUnmountDate, eventIdentificator]
    console.log(errors, "erroresEV")
    const handleCountryChange = (e) => {
        const x = e.target.value
        setCities(countries1[x])
    }

    const identificador = watch(eventIdentificator)

    const errorCheck = (errorFields) => {
        if (errors?.[index]) {
            const currentErrors = Object.keys(errors?.[index])
            const found = errorFields.some(r => currentErrors.indexOf(r) >= 0)

            return !found
        }
    }


    const checkMontageDate = () => {
        const startMontage = new Date((watch(startMontageDate)))
        const endMontage = new Date((watch(endMontageDate)))
        const difMontageDates = (endMontage - startMontage)
        if (difMontageDates < 0) {
            return false
        }
        return true
    }

    const checkEventDate = () => {
        const startMontage = new Date((watch(startMontageDate)))
        const endMontage = new Date((watch(endMontageDate)))
        const startEvent = new Date((watch(startEventDate)))
        const endEvent = new Date((watch(endEventDate)))
        const difMontageDates = (endMontage - startMontage)
        console.log('difmon', difMontageDates)
        if ((startEvent - endMontage < 0) || (startEvent - startMontage < 0)) {
            return false
        }
        if ((endEvent - endMontage < 0) || (endEvent - startMontage < 0)) {
            return false
        }
        if ((endEvent - startEvent < 0)) {
            return false
        }

        return true
    }

    const checkUnmountDate = () => {
        const startMontage = new Date((watch(startMontageDate)))
        const endMontage = new Date((watch(endMontageDate)))
        const startEvent = new Date((watch(startEventDate)))
        const endEvent = new Date((watch(endEventDate)))
        const startUnmount = new Date((watch(startUnmountDate)))
        const endUnmount = new Date((watch(endUnmountDate)))
        if ((endUnmount - startUnmount < 0)) {
            return false
        }
        if ((startUnmount - endMontage < 0) || (startUnmount - startMontage < 0) || (startUnmount - startEvent < 0) || (endEvent - startMontage < 0)) {
            return false
        }

        return true

    }

    return (
        <Fragment>
            <AccordionItem>

                <AccordionHeader targetId={`${index + 1}`} invalid={errors && true}  className={`${(errorCheck(errorFields) && true && submitCount >= 1) ? 'border-2 border-danger rounded' : ''}`}>Información evento {index + 1} - {identificador}</AccordionHeader>
                <div className=''>

                    <AccordionBody accordionId={`${index + 1}`}>
                        <Row className=''>

                            <Col  md='6' className='mb-1'>
                                <Label className='form-label' for={eventCountry}>
                                    País del evento
                                </Label>

                                <select
                                    className={`form-select ${errors[index]?.eventCountry && true ? 'border-2 border-danger' : ''}`}
                                    {...register(eventCountry, {required:true})}
                                    onChange = {(e) => handleCountryChange(e, index) }
                                    invalid={errors[index]?.eventCountry && true}
                                >
                                    <option disabled selected value =''> Selecciona un país </option>
                                    {

                                        countries.map((country) => <option key={country}>{country}</option>)
                                    }
                                </select>

                                {errors[index]?.eventCountry?.types?.required && <FormFeedback>Por favor, ingresa el país del evento</FormFeedback>}

                            </Col>

                            <Col  md='6' className='mb-1'>
                                <Label className='form-label' for={eventCity}>
                                    Ciudad / comuna del evento
                                </Label>

                                <select
                                    className={`form-select ${errors[index]?.eventCity && true ? 'border-2 border-danger' : ''}`}
                                    {...register(eventCity, {required:true})}
                                    invalid={errors[index]?.eventCity && true}
                                >
                                    <option disabled selected value=''> Selecciona una ciudad </option>
                                    {
                                        cities.map((city) => <option key={city}>{city}</option>)
                                    }
                                </select>

                                {errors[index]?.eventCity?.types?.required && <small className='text-danger'>Por favor, ingresa la ciudad del evento</small>}

                            </Col>


                            <Col md='6' className='mb-1'>
                                <Label className='form-label' for={eventAddress}>
                                    Dirección del evento
                                </Label>
                                <Controller

                                    id={eventAddress}
                                    name={eventAddress}
                                    rules={{required:true, minLength:5}}
                                    defaultValue=''
                                    control={control}
                                    render={({ field}) => (
                                        <Input placeholder= 'Vergara 324' invalid={errors[index]?.eventAddress && true} {...field}

                                        />
                                    )}
                                />
                                {errors[index]?.eventAddress?.types?.required && <FormFeedback>Por favor, ingresa la dirección del evento</FormFeedback>}
                                {errors[index]?.eventAddress?.types?.minLength && <FormFeedback>Por favor, ingresa una dirección de al menos 5 caracteres</FormFeedback>}
                            </Col>

                            <Col md='6' className='mb-1'>
                                <Label className='form-label' for={eventIdentificator}>
                                    Nombre del evento
                                </Label>
                                <HelpCircle id="event_address" className='mx-1' size='17' color='gray'/>
                                <UncontrolledTooltip placement='right' target='event_address'>
                                    Ingresa un nombre que diferencie este evento de otros
                                </UncontrolledTooltip>
                                <Controller

                                    id={eventIdentificator}
                                    name={eventIdentificator}
                                    rules={{required:true, minLength:3}}
                                    defaultValue=''
                                    control={control}
                                    render={({ field}) => (
                                        <Input placeholder= 'Lollapalooza día 1' invalid={errors[index]?.eventIdentificator && true} {...field}

                                        />
                                    )}
                                />
                                {errors[index]?.eventIdentificator?.types?.required && <FormFeedback>Por favor, ingresa un identificador del evento</FormFeedback>}
                                {errors[index]?.eventIdentificator?.types?.minLength && <FormFeedback>Por favor, ingresa un identificador del evento de al menos 5 caracteres</FormFeedback>}
                            </Col>


                        </Row>

                        <Row>

                            <Col md='6' className='mb-1'>
                                <Label className='form-label' for={attendeesNumber}>
                                    Número de asistentes
                                </Label>
                                <Controller
                                    id={attendeesNumber}
                                    name={attendeesNumber}
                                    rules={{required:true, min:2, max:2000}}
                                    control={control}
                                    defaultValue=''
                                    render={({ field }) => (
                                        <Input placeholder='250' invalid={errors[index]?.attendeesNumber && true}  {...field} type='number' />
                                    )}
                                />
                                {errors[index]?.attendeesNumber?.types?.required && <FormFeedback>Por favor, ingresa un numero de asistentes</FormFeedback>}
                                {errors[index]?.attendeesNumber?.types?.min && <FormFeedback>Por favor, ingresa al menos 2 asistentes</FormFeedback>}
                                {errors[index]?.attendeesNumber?.types?.max && <FormFeedback>Por favor, ingresa como máximo 2000 asistentes</FormFeedback>}
                            </Col>

                            <Col md='6' className='mb-1 '>
                                <Label className='form-label' for={`startMontageDate${index}`}>
                                    Fechas de montaje desde - hasta
                                </Label>
                                <Row className=''>

                                    <Col>

                                        <Controller
                                            id={startMontageDate}
                                            name={startMontageDate}
                                            control={control}
                                            rules={{required:true, validate:checkMontageDate}}
                                            defaultValue=''
                                            render={({ field }) => (
                                                <Input type='date' invalid={errors[index]?.startMontageDate && true} {...field} />
                                            )}
                                        />
                                        {errors[index]?.startMontageDate?.types?.required && <FormFeedback>Por favor, ingresa la fecha de inicio del montaje</FormFeedback>}
                                        {errors[index]?.startMontageDate?.types?.validate && <FormFeedback>Por favor, ingresa una fecha válida de montaje - desmontaje</FormFeedback>}
                                    </Col>

                                    <Col>


                                        <Controller
                                            id={endMontageDate}
                                            name={endMontageDate}
                                            control={control}
                                            defaultValue=''
                                            rules={{required:true, validate: checkMontageDate}}
                                            render={({ field }) => (
                                                <Input type='date' invalid={errors[index]?.endMontageDate && true} {...field} />
                                            )}
                                        />
                                        {errors[index]?.endMontageDate?.types?.required && <FormFeedback>Por favor, ingresa la fecha de término del montaje</FormFeedback>}
                                        {errors[index]?.endMontageDate?.types?.validate && <FormFeedback>Por favor, ingresa una fecha válida de montaje - desmontaje</FormFeedback>}

                                    </Col>
                                </Row>
                            </Col>

                            <Col md='6' className='mb-1 '>
                                <Label className='form-label' for={`startEventDate${index}`}>
                                    Fecha de evento desde - hasta
                                </Label>
                                <Row>
                                    <Col>

                                        <Controller
                                            id={startEventDate}
                                            name={startEventDate}
                                            control={control}
                                            defaultValue=''
                                            rules={{required:true, validate: checkEventDate}}
                                            render={({ field }) => (
                                                <Input type='date' invalid={errors[index]?.startEventDate && true} {...field} />
                                            )}
                                        />
                                        {errors[index]?.startEventDate?.types?.required && <FormFeedback>Por favor, ingresa la fecha de inicio del evento</FormFeedback>}
                                        {errors[index]?.startEventDate?.types?.validate && <FormFeedback>Por favor, ingresa una fecha válida del evento. Ten en consideración que estas no pueden ser anteriores a las de montaje.</FormFeedback>}

                                    </Col>
                                    <Col>

                                        <Controller
                                            id={endEventDate}
                                            name={endEventDate}
                                            control={control}
                                            defaultValue=''
                                            rules={{required:true, validate: checkEventDate}}
                                            render={({ field }) => (
                                                <Input type='date' invalid={errors[index]?.endEventDate && true} {...field} />
                                            )}
                                        />
                                        {errors[index]?.endEventDate?.types?.required && <FormFeedback>Por favor, ingresa la fecha de término del evento</FormFeedback>}
                                        {errors[index]?.endEventDate?.types?.validate && <FormFeedback>Por favor, ingresa una fecha válida del evento. Ten en consideración que esta no puede ser anterior a la fecha de inicio del evneto o a las fechas de montaje.</FormFeedback>}

                                    </Col>
                                </Row>
                            </Col>


                            <Col md='6' className='mb-1'>
                                <Label className='form-label' for={`startUnmountDate${index}`}>
                                    Fecha de desmontaje desde - hasta
                                </Label>
                                <Row>
                                    <Col>

                                        <Controller
                                            id={startUnmountDate}
                                            name={startUnmountDate}
                                            control={control}
                                            defaultValue=''
                                            rules={{required:true, validate: checkUnmountDate}}
                                            render={({ field }) => (
                                                <Input type='date' invalid={errors[index]?.startUnmountDate && true} {...field} />
                                            )}
                                        />
                                        {errors[index]?.startUnmountDate?.types?.required && <FormFeedback>Por favor, ingresa la fecha de inicio del desmontaje</FormFeedback>}
                                        {errors[index]?.startUnmountDate?.types?.validate && <FormFeedback>Por favor, ingresa una fecha válida del evento. Ten en consideración que estas no pueden ser anteriores a las de montaje o del evento.</FormFeedback>}

                                    </Col>

                                    <Col>

                                        <Controller
                                            id={endUnmountDate}
                                            name={endUnmountDate}
                                            control={control}
                                            defaultValue=''
                                            rules={{required:true, validate: checkUnmountDate}}
                                            render={({ field }) => (
                                                <Input type='date' invalid={errors[index]?.endUnmountDate && true} {...field} />
                                            )}
                                        />
                                        {errors[index]?.endUnmountDate?.types?.required && <FormFeedback>Por favor, ingresa la fecha de termino del montaje</FormFeedback>}
                                        {errors[index]?.endUnmountDate?.types?.validate && <FormFeedback>Por favor, ingresa una fecha válida del evento. Ten en consideración que estas no pueden ser anteriores a las de montaje o del evento.</FormFeedback>}

                                    </Col>
                                </Row>
                            </Col>


                        </Row>
                    </AccordionBody>
                </div>
            </AccordionItem>

        </Fragment>
    )
}

export default EventDetails