import React, {useRef, useState} from 'react'
import html2canvas from 'html2canvas'
import Dashboard from './Dashboard'

import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown, ButtonDropdown} from 'reactstrap'

const DownloadDashboard = ({data, index}) => {
  const [current, setCurrent] = useState(0)
  const printRef = useRef()
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const eventos = (Object.values(data.eventDetails)).map(item => item.eventIdentificator)
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen)
  }
  const handleDownloadImage = async (i) => {
    const onDownload = async () => {

      const canvas = await html2canvas(document.getElementById(`print${index}`), {onclone: (document) => { document.getElementById(`print${index}`).style.display = 'block' }})
      const image = canvas.toDataURL('image/png', 2.0)
      const downloadImage = (blob, filename) => {
        const fakeLink = window.document.createElement('a')
        fakeLink.style = 'display:none'
        fakeLink.download = filename
        fakeLink.href = blob
        document.body.appendChild(fakeLink)
        fakeLink.click()
        document.body.removeChild(fakeLink)
        fakeLink.remove()
      }
      downloadImage(image, `Dashboard - ${data.eventDetails[i].eventIdentificator}`)
    }
      setCurrent(i, onDownload(i))
    
  }
  
  return (
      <div className='position-relative  '>
        <div className='position-absolute ' style={{height:'100vh', width:'100vw', display:'none'}} id={`print${index}`}>

          <Dashboard className='' name={data.generalEventData.eventName} usage={data.usage} selected={current} data={data} referencia={printRef} />
        </div>
        <ButtonDropdown isOpen={dropdownOpen} toggle={toggleDropdown} className=''  >
          <DropdownToggle caret className='dropdown-toggle-split' color='warning'>
            Selecciona evento
          </DropdownToggle>
          <DropdownMenu className=''>
            {eventos.map((el, i) => <DropdownItem key={`element${el}- i`} onClick={() => handleDownloadImage(i)} className='w-100'>{el}</DropdownItem>)}

          </DropdownMenu>
        </ButtonDropdown>


      </div>

  )
}

export default DownloadDashboard