import React from 'react'
import {Row, Col} from 'reactstrap'
import { Moon } from 'react-feather'

const EventDaysResume = ({selected}) => {
    const calculateDate = (date1, date2) => {
        const date_1 = new Date(date1)
        const date_2 = new Date(date2)
        const difference = date_2.getTime() - date_1.getTime()
        const TotalDays = Math.ceil(difference / (1000 * 3600 * 24))
        if (TotalDays === 0) return 1
        return TotalDays
      }
      calculateDate(selected.startMontageDate, selected.endUnmountDate)
  return (
    <div className='w-100 h-100  d-flex align-items-center justify-content-center ' style={{border:'3px black solid', backgroundColor:'#e21d34'}}>
        <Row className='d-flex align-items-center justify-content-center w-100 h-100'>
            <Col className='d-flex flex-column gap-2 h-100 w-100 p-3'>
                <Row >
                    <h1 className='text-center text-white'>{calculateDate(selected.startMontageDate, selected.endMontageDate)} {calculateDate(selected.startMontageDate, selected.endUnmountDate) > 1 ? 'Días de Evento' : 'Día de Evento'}</h1>
                </Row>
                <Row className='d-flex justify-content-center align-items-center '>
                    <Col className='d-flex flex-row text-white p-0 ' sm='4'>
                        <Moon color='white' size={150}/>
                        </Col>
                        <Col className=''>
                        <div className=''>
                            <h3 className='mb-1' style={{fontWeight:'bold', color:'black'}}>{calculateDate(selected.startMontageDate, selected.endMontageDate)} {calculateDate(selected.startMontageDate, selected.endMontageDate) > 1 ? 'Días de montaje' : 'Día de desmontaje'}</h3>
                            <h3 className='mb-1' style={{fontWeight:'bold', color:'black'}}>{calculateDate(selected.startMontageDate, selected.endMontageDate)} {calculateDate(selected.startEventDate, selected.endEventDate) > 1 ? 'Días de evento' : 'Día de evento'}</h3>
                            <h3 className='mb-1' style={{fontWeight:'bold', color:'black'}}>{calculateDate(selected.startMontageDate, selected.endMontageDate)} {calculateDate(selected.startUnmountDate, selected.endUnmountDate) > 1 ? 'Días de montaje' : 'Día de desmontaje'}</h3>
                        </div>
                        </Col>
                </Row>
            </Col>
        </Row>
        </div>
  )
}

export default EventDaysResume