import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Landing from "./views/HuellaDeCarbono/Pages/Landing"
import Home from "./views/HuellaDeCarbono/Pages/Home"
import Admin from "./views/HuellaDeCarbono/Pages/Admin"
import { DataProvider } from "./views/HuellaDeCarbono/Context/Context"
import BlankLayout from "@layouts/BlankLayout"
import Summary from "./views/HuellaDeCarbono/Pages/Summary"

const App = () => {
  return (
    <BrowserRouter>
      <DataProvider>
        <Routes>
          <Route path="/" element={<BlankLayout />}>
            <Route path="/" element={<Landing />} />
            <Route path="/formulario" element={<Home />} />
            <Route path="/resumen" element={<Summary />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/*" element={<Landing />} />
          </Route>
        </Routes>
      </DataProvider>
    </BrowserRouter>
  )
}

export default App
