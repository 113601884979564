import React from 'react'
import { View, StyleSheet, Text } from '@react-pdf/renderer'

const styles = StyleSheet.create({
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableCol: {
        width: "50%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCell: {
        margin: "auto",
        marginTop: 5,
        fontSize: 10
    }

})

const Table1 = ({co2e}) => {
    console.log(co2e)
    return (
        <View style={styles.table}>
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Cadena de valor / Fuente de emisión</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Emision GEI tCO2e</Text>
                </View>
            </View>
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Aguas arriba</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{Number(co2e * 0.1).toFixed(2)}</Text>
                </View>
            </View>
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Proceso - Evento</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{Number(co2e * 0.8).toFixed(2)}</Text>
                </View>
            </View>
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Agua abajo</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{Number(co2e * 0.1).toFixed(2)}</Text>
                </View>
            </View>
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>TOTAL</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{Number(co2e).toFixed(2)}</Text>
                </View>
            </View>

        </View>
    )
}

export default Table1