import React, {useContext, useEffect} from "react"
import { useFormContext, Controller } from "react-hook-form"
import { Col, Input, Row, Label, FormFeedback, InputGroup } from "reactstrap"
import { DataContext } from "../../../../Context/Context"

const Waste = ({index}) => {
    const { control, watch, formState:{errors}, register} = useFormContext()

    const recycling = `[${index}.residuos.reciclaje.use]`
    const residuos = `[${index}.residuos.residuos.use]`
    const residuosUsage = `[${index}.residuos.residuos.usage]`
    const compostaje = `[${index}.residuos.compostaje.use]`
    const compostajeUsage = `[${index}.residuos.compostaje.usage]`
    const residuosUOM = `[${index}.residuos.residuos.uom]`
    const reciclajePapelesYCartones = `[${index}.residuos.reciclaje.papelesYCartones]`
    const reciclajePapelesYCartonesUOM = `[${index}.residuos.reciclaje.papelesYCartonesUOM]`
    const reciclajePlastico = `[${index}.residuos.reciclaje.plastico]`
    const reciclajePlasticoUOM = `[${index}.residuos.reciclaje.plasticoUOM]`
    const reciclajeMetales = `[${index}.residuos.reciclaje.metales]`
    const reciclajeMetalesUOM = `[${index}.residuos.reciclaje.metalesUOM]`
    const reciclajeVidrio = `[${index}.residuos.reciclaje.vidrio]`
    const reciclajeVidrioUOM = `[${index}.residuos.reciclaje.vidrioUOM]`
    const compostajeUOM = `[${index}.residuos.compostaje.uom]`

    /*
    */
    const {values, usage, data1} = useContext(DataContext)

    const calculateWaste = (declaration, index) => {
        const info = declaration[index]?.residuos
        console.log(data1)
        console.log(declaration)

        if (info && data1.eventDetails) {
            const residuosAverage = values.promedio_residuos_persona
            const reciclajeAverage = values.promedio_reciclaje_persona

            const attendeesNumber = Number(data1.eventDetails[index].attendeesNumber)

            const compuom = info.compostaje.uom
            const resiuom = info.residuos.uom

            let emisionComp = 0
            if (declaration[index].residuos.compostaje.use === 'true') {
                emisionComp = Number(info.compostaje.usage) * Number(values?.res_compostaje)
                if (compuom === 'kg') emisionComp /= 1000
            }
            let emisionRes = 0
            if (declaration[index].residuos.residuos.use === 'true') {
                emisionRes = Number(info.residuos.usage) * Number(values?.res_relleno_sani_residuos_muni)
                if (resiuom === 'kg') emisionRes /= 1000
            } else if (declaration[index].residuos.residuos.use === 'average') {
                emisionRes = residuosAverage * attendeesNumber
            }

            let emisionPapelesYCartones = 0
            let emisionPlastico = 0
            let emisionMetales = 0
            let emisionVidrio = 0

            const reciclajePapelesYCartones = info.reciclaje.papelesYCartones
            const papelesYCartonesUOM = info.reciclaje.papelesYCartonesUOM
            const reciclajePlastico = info.reciclaje.plastico
            const plasticoUOM = info.reciclaje.plasticoUOM
            const reciclajeMetales = info.reciclaje.metales
            const metalesUOM = info.reciclaje.metalesUOM
            const reciclajeVidrio = info.reciclaje.vidrio
            const vidrioUOM = info.reciclaje.vidrioUOM

            let totalReciclaje = emisionPapelesYCartones + emisionPlastico + emisionMetales + emisionVidrio
            if (declaration[index].residuos.reciclaje.use === 'true') {

                emisionPapelesYCartones = Number(reciclajePapelesYCartones) * Number(values?.rec_papel_y_carton)
                if (papelesYCartonesUOM === 'kg') emisionPapelesYCartones /= 1000

                emisionPlastico = Number(reciclajePlastico) * Number(values?.rec_prom_plasticos)
                if (plasticoUOM === 'kg') emisionPlastico /= 1000

                emisionMetales = Number(reciclajeMetales) * Number(values?.rec_metal_chatarra)
                if (metalesUOM === 'kg') emisionMetales /= 1000

                emisionVidrio = Number(reciclajeVidrio) * Number(values?.rec_vidrio)
                if (vidrioUOM === 'kg') emisionVidrio /= 1000
            } else if (declaration[index].residuos.reciclaje.use === 'average') {
                totalReciclaje = attendeesNumber * reciclajeAverage
                console.log(attendeesNumber, reciclajeAverage)
            }


            const total = emisionComp + emisionRes + totalReciclaje
            console.log(total, 'residuosTotal', emisionComp, emisionRes, totalReciclaje)


            if (usage[index]) {

                const obj =  {compostaje:(emisionComp / 1000), reciclaje: (totalReciclaje / 1000), residuos: (emisionRes / 1000), total :(total / 100)}
                usage[index].waste = obj
            }


            return (total / 1000)
        }
    }

    const total = calculateWaste(watch(), index)
    useEffect(() => {
        calculateWaste(watch(), index)
    }, [watch()])
    return (
        <Row>
            <Row className=""><Col className="d-flex justify-content-end"><h5 className="text-success">+ {total > 1 ? `${total.toFixed(2)} TON ` : `${(total * 1000).toFixed(2)} Kg `}CO<sub>2</sub>e</h5></Col></Row>
            <Row className="input--main--container mt-1">
                <Col className="left--section" md='6' sm='12'>
                    <Row>
                        <h5>Residuos a eliminación</h5>
                        <small>Checkea a continuación si el evento generó residuos que fueron enviados <br/>   a vertedero o relleno sanitario.</small>
                    </Row>
                    <Row className="mt-1">
                        <div className="d-flex gap-1">
                            <div>

                                <input type='radio' className="form-check-input" value='true' {...register(residuos, {required:true})}  />
                                <Label>Si</Label>
                            </div>
                            <div>

                                <input type='radio' className="form-check-input" value='average' {...register(residuos, {required:true})}  />
                                <Label>Sí, pero no tengo la cifra</Label>
                            </div>
                            <div>

                                <input type='radio' className="form-check-input" value='false' {...register(residuos, {required:true})}  />
                                <Label>No</Label>
                            </div>
                        </div>
                        <div className="mt-1">
                        </div>
                        {errors[index]?.residuos?.residuos?.use?.types?.required && <small className='text-danger'>Por favor, selecciona si hubo o no residuos en el evento</small>}

                    </Row>
                </Col>

                <Col className={`right--section mt-1 ${watch(residuos) === 'true' ? '' : 'd-none'}`}>
                    <Label>Cantidad de residuos generados <strong>(kilos o toneladas)</strong></Label>
                    <InputGroup>
                        <Controller
                            id={residuosUsage}
                            name={residuosUsage}
                            control={control}
                            defaultValue='0'
                            rules={{required:true}}
                            render = {({field}) => (
                                <Input type='number' name={residuosUsage} invalid= {errors[index]?.residuosUsage && true} {...field}/>
                            )}
                        />
                        <select className=" input-group-text " {...register(residuosUOM)} >
                            <option selected>kg</option>
                            <option>TON</option>
                        </select>
                    </InputGroup>
                    {errors[index]?.residuosUsage?.types?.required && <small className='text-danger'>Por favor, ingresa la cantidad de energía proveniente de la red eléctrica</small>}
                </Col>

            </Row>
            <Row className="input--main--container mt-1">
                <Col className="left--section" md='6' sm='12'>
                    <Row>
                        <h5>Reciclaje</h5>
                        <small>Checkea a continuación si hubo reciclaje</small>
                    </Row>
                    <Row className="mt-1">
                        <div className="d-flex gap-1">
                            <div>

                                <input type='radio' className="form-check-input" value='true' {...register(recycling, {required:true})}  />
                                <Label>Si</Label>
                            </div>
                            <div>

                                <input type='radio' className="form-check-input" value='average' {...register(recycling, {required:true})}  />
                                <Label>Sí, pero no tengo la cifra</Label>
                            </div>
                            <div>

                                <input type='radio' className="form-check-input" value='false' {...register(recycling, {required:true})}  />
                                <Label>No</Label>
                            </div>
                        </div>
                        <div className="mt-1">
                        </div>
                        {errors[index]?.residuos?.reciclaje?.use?.types?.required && <small className='text-danger'>Por favor, selecciona si hubo o no reciclaje</small>}

                    </Row>
                </Col>

                <Col className={`right--section mt-1 ${watch(recycling) === 'true' ? '' : 'd-none'}`}>

                    <Row className="mb-1" >
                        <Label>Cantidad de papeles y cartones reciclados <strong>(kg o toneladas)</strong></Label>
                        <InputGroup>
                            <Controller
                                id={reciclajePapelesYCartones}
                                name={reciclajePapelesYCartones}
                                control={control}
                                defaultValue='0'
                                rules={{required:true}}
                                render = {({field}) => (
                                    <Input type='number' name={reciclajePapelesYCartones} invalid= {errors[index]?.reciclajePapelesYCartones && true} {...field}/>
                                )}
                            />

                            <select className=" input-group-text " {...register(reciclajePapelesYCartonesUOM)} >
                                <option selected>kg</option>
                                <option>TON</option>
                            </select>
                            {errors[index]?.reciclajePapelesYCartones?.types?.required && <small className='text-danger'>Por favor, ingresa la cantidad de energía proveniente de la red eléctrica</small>}
                        </InputGroup>

                    </Row>

                    <Row className="mb-1">
                        <Label>Cantidad de plastico reciclado <strong>(kg o toneladas)</strong></Label>
                        <InputGroup>
                            <Controller
                                id={reciclajePlastico}
                                name={reciclajePlastico}
                                control={control}
                                defaultValue='0'
                                rules={{required:true}}
                                render = {({field}) => (
                                    <Input type='number' name={reciclajePlastico} invalid= {errors[index]?.reciclajePlastico && true} {...field}/>
                                )}
                            />

                            <select className=" input-group-text " {...register(reciclajePlasticoUOM)} >
                                <option selected>kg</option>
                                <option>TON</option>
                            </select>
                            {errors[index]?.reciclajePlastico?.types?.required && <small className='text-danger'>Por favor, ingresa la cantidad de energía proveniente de la red eléctrica</small>}
                        </InputGroup>

                    </Row>

                    <Row className="mb-1">
                        <Label>Cantidad de metales reciclados <strong>(kilos o toneladas)</strong></Label>
                        <InputGroup>
                            <Controller
                                id={reciclajeMetales}
                                name={reciclajeMetales}
                                control={control}
                                defaultValue='0'
                                rules={{required:true}}
                                render = {({field}) => (
                                    <Input type='number' name={reciclajeMetales} invalid= {errors[index]?.reciclajeMetales && true} {...field}/>
                                )}
                            />

                            <select className=" input-group-text " {...register(reciclajeMetalesUOM)} >
                                <option selected>kg</option>
                                <option>TON</option>
                            </select>
                            {errors[index]?.reciclajeMetales?.types?.required && <small className='text-danger'>Por favor, ingresa la cantidad de energía proveniente de la red eléctrica</small>}
                        </InputGroup>

                    </Row>

                    <Row className="mb-1">
                        <Label>Cantidad de vidrio reciclado <strong>(kg o toneladas)</strong></Label>
                        <InputGroup>
                            <Controller
                                id={reciclajeVidrio}
                                name={reciclajeVidrio}
                                control={control}
                                defaultValue='0'
                                rules={{required:true}}
                                render = {({field}) => (
                                    <Input type='number' name={reciclajeVidrio} invalid= {errors[index]?.reciclajeVidrio && true} {...field}/>
                                )}
                            />

                            <select className=" input-group-text " {...register(reciclajeVidrioUOM)} >
                                <option selected>kg</option>
                                <option>TON</option>
                            </select>
                            {errors[index]?.reciclajeVidrio?.types?.required && <small className='text-danger'>Por favor, ingresa la cantidad de energía proveniente de la red eléctrica</small>}
                        </InputGroup>

                    </Row>

                </Col>

            </Row>
            <Row className="input--main--container mt-1">
                <Col className="left--section" md='6' sm='12'>
                    <Row>
                        <h5>Compostaje</h5>
                        <small>Checkea a continuación en caso de haber compostaje</small>
                    </Row>
                    <Row className="mt-1">
                        <div className="d-flex gap-1">
                            <div>

                                <input type='radio' className="form-check-input" value='true' {...register(compostaje, {required:true})}  />
                                <Label>Si</Label>
                            </div>
                            <div>

                                <input type='radio' className="form-check-input" value='false' {...register(compostaje, {required:true})}  />
                                <Label>No</Label>
                            </div>
                        </div>
                        <div className="mt-1">
                        </div>
                        {errors[index]?.residuos?.compostaje?.use?.types?.required && <small className='text-danger'>Por favor, selecciona si hubo o no compostaje</small>}

                    </Row>
                </Col>

                <Col className={`right--section mt-1 ${watch(compostaje) === 'true' ? '' : 'd-none'}`}>
                    <Label>Cantidad de compostaje <strong>(kg o toneladas)</strong></Label>
                    <InputGroup>

                        <Controller
                            id={compostajeUsage}
                            name={compostajeUsage}
                            control={control}
                            defaultValue='0'
                            rules={{required:true}}
                            render = {({field}) => (
                                <Input type='number' name={compostajeUsage} invalid= {errors[index]?.compostajeUsage && true} {...field}/>
                            )}
                        />
                        <select className=" input-group-text " {...register(compostajeUOM)} >
                            <option selected>kg</option>
                            <option>TON</option>
                        </select>
                    </InputGroup>
                    {errors[index]?.compostajeUsage?.types?.required && <small className='text-danger'>Por favor, ingresa la cantidad de energía proveniente de la red eléctrica</small>}
                </Col>

            </Row>
        </Row>
    )
}

export default Waste