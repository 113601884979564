// ** React Imports
import { Fragment, useContext, useState, useEffect} from 'react'
import axios from 'axios'

// ** Third Party Components
import { useForm, Controller } from 'react-hook-form'
import { ArrowLeft, ArrowRight, HelpCircle } from 'react-feather'

// ** Reactstrap Imports
import { Label, Row, Col, Button, Form, Input, FormFeedback, CardHeader, Card, CardBody, Tooltip} from 'reactstrap'
import { DataContext } from '../../../Context/Context'
import {validate as validaterut} from 'rut.js'
import PhoneMask from '../../InputMasks/PhoneMask'
import Bar from '../../Charts/Bar'


const defaultValues = {
    name: '',
    razonSocial: '',
    RUT: '',
    email: '',
    telefono: ''
}

const userData = ({ stepper }) => {
    // ** Hooks
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({ defaultValues, criteriaMode: 'all'})

    // ** States
    const [tooltipOpen, setTooltipOpen] = useState(false)

    // ** Custom functions
    const toggle = () => setTooltipOpen(!tooltipOpen)

    //** Context
    /**
     * setData -> agregar a data total
     * serValues -> Values de la API
     */
    const { setData, setValues} = useContext(DataContext)

    // ** Submit
    const onSubmit = (data) => {
        const userData = data
        setData(currData => ({...currData, userData}))
        stepper.next()

    }

    // ** Fetch
    useEffect(() => {
        axios
          .get("https://calculadoramde.cl/calculadora/api/valuesAPI")

          .then((resp) => setValues(resp.data))
    }, [])


    return (
        <Fragment>
            <Row className=''>

                <Col>
                    <div className='d-flex flex-row align-items-center justify-content-between px-1  my-2'>
                        <div>
                            <h5 className='mb-0'>Información del declarante</h5>
                            <div>
                                <small>Ingresa a continuación la información necesaria sobre el declarante. <br/>
                                    </small>
                            </div>
                        </div>
                    </div>


                </Col>
                <CardBody>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col md='6' className='mb-1'>
                                <Label className='form-label' for='address'>
                                    Nombre del declarante
                                </Label>
                                <Controller
                                    id='name'
                                    name='name'
                                    rules={{required:true, minLength:5}}
                                    control={control}
                                    render={({ field }) => (
                                        <Input placeholder='Diego Sanhueza' invalid={errors.name && true} {...field} />
                                    )}
                                />
                                {errors.name?.types?.required && <FormFeedback>Por favor, ingresa el nombre del declarante</FormFeedback>}
                                {errors.name?.types?.minLength && <FormFeedback>Por favor, ingresa un nombre de al menos 5 caracteres</FormFeedback>}
                            </Col>

                            <Col md='6' className='mb-1'>
                                <Label className='form-label' for='razonSocial'>
                                    Razón social
                                </Label>
                                <Controller
                                    id='razonSocial'
                                    name='razonSocial'
                                    rules={{required:true, minLength:3}}
                                    control={control}
                                    render={({ field }) => (
                                        <Input placeholder='Razón social' invalid={errors.razonSocial && true} {...field} />
                                    )}
                                />
                                {errors.razonSocial?.types?.required && <FormFeedback>Por favor, ingresa la razón social</FormFeedback>}
                                {errors.razonSocial?.types?.minLength && <FormFeedback>Por favor, ingresa una razón social de al menos 3 caracteres</FormFeedback>}
                            </Col>
                        </Row>

                        <Row>
                            <Col md='6' className='mb-1'>
                                <div className='d-flex'>
                                    <Label className='me-1' for='RUT' >
                                        RUT
                                    </Label>
                                    <HelpCircle id="RUT" className='' size='17' color='gray'/>

                                    <Tooltip
                                        placement="right"
                                        isOpen={tooltipOpen}
                                        autohide={false}
                                        target="RUT"
                                        toggle={toggle}
                                    >Ingresa el rut de la empresa</Tooltip>
                                </div>
                                <Controller
                                    id='RUT'
                                    name='RUT'
                                    rules={{required:true, validate:validaterut}}
                                    control={control}
                                    render={({ field }) => <Input placeholder='18 882 892-2' invalid={errors.RUT && true}  {...field} />}
                                />
                                {errors.RUT?.types?.required && <FormFeedback>Por favor, ingresa tu RUT</FormFeedback>}
                                {errors.RUT?.types?.validate && <FormFeedback>Por favor, ingresa un RUT válido</FormFeedback>}
                            </Col>

                            <Col md='6' className='mb-1'>
                                <Label className='form-label' for='email'>
                                    Correo electrónico
                                </Label>
                                <Controller
                                    id='email'
                                    name='email'
                                    rules = {{required:true, pattern:/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                                    }}
                                    control={control}
                                    render={({ field }) => <Input placeholder='sample@mail.com' {...field} invalid={errors.email && true} />}
                                />
                                {errors.email?.types?.pattern && <FormFeedback>Por favor, ingresa un correo electrónico válido</FormFeedback>}
                                {errors.email?.types?.required && <FormFeedback>Por favor, ingresa tu correo electrónico</FormFeedback>}


                            </Col>


                            <Col md='6' className='mb-1'>
                                <Label className='form-label' for='telefono'>
                                    Número telefónico
                                </Label>
                                <Controller
                                    id='telefono'
                                    name='telefono'
                                    control={control}
                                    rules={{required:true, minLength:12}}
                                    render= {({field:{value}}) => (

                                        <PhoneMask name='telefono' id='telefono' placeholder= '9 3467 2306'value={value}  invalid={errors.telefono && true} onChange= {e => setValue(e.target.name, e.target.rawValue)}  />
                                    )}
                                />
                                {errors.telefono?.types?.required && <FormFeedback>Por favor, ingresa tu número de teléfono</FormFeedback>}
                                {errors.telefono?.types?.minLength && <FormFeedback>Por favor, ingresa un número de teléfono válido</FormFeedback>}
                            </Col>

                        </Row>


                        <Col className='d-flex justify-content-between mt-2'>
                            <Button type='button' color='secondary' className='btn-prev disabled' onClick={() => stepper.previous()}>
                                <ArrowLeft size={14} className='align-middle me-sm-25 me-0'></ArrowLeft>
                                <span className='align-middle d-sm-inline-block d-none'>Ir atrás</span>
                            </Button>
                            <Button type='submit' color='primary' className='btn-next'>
                                <span className='align-middle d-sm-inline-block d-none'>Siguiente</span>
                                <ArrowRight size={14} className='align-middle ms-sm-25 ms-0'></ArrowRight>
                            </Button>
                        </Col >
                    </Form>
                </CardBody>
            </Row>

        </Fragment>
    )
}

export default userData
