
import React, {useContext, useEffect} from "react"
import { Col, Input, Row, Label, FormFeedback, Spinner, InputGroup } from "reactstrap"
import { useFormContext, Controller } from "react-hook-form"
import { DataContext } from "../../../../Context/Context"
import { calculateEnergy } from "../../../../Helpers/Calculations"
import useFetchElectricityInterval from "../../../../Hooks/useFetchElectricityInterval"
const Energy = ({index}) => {
  // ** context
  const { control, watch, formState:{errors}, register} = useFormContext()
  // ** states
// ** variables
  const useOfElectricNetwork = `[${index}.energy.electricNetwork.use]`
  const usageOfElectricNetwork = `[${index}.energy.electricNetwork.usage]`
  const useOfRenovableEnergy = `[${index}.energy.renovableEnergy.use]`
  const usageOfRenovableEnergy = `[${index}.energy.renovableEnergy.usage]`
  const energyUOM = `[${index}.energy.electricNetwork.uom]`

  // ** custom functions
  const {data1, usage, values} = useContext(DataContext)
  const [loading, data] = useFetchElectricityInterval(data1, index)

  const total =  calculateEnergy(data1, index, watch(), data, values)
  useEffect(() => {
    const result = calculateEnergy(data1, index, watch(), data, values)
    usage[index].energy.usage = result

  }, [watch()])

  return (
      <Row className="" >
        {!loading && <>
          <Row className=""><Col className="d-flex justify-content-end"><h5 className="text-success">+ {total > 1 ? `${total.toFixed(2)} TON ` : `${(total * 1000).toFixed(2)} Kg `} CO<sub>2</sub>e</h5></Col></Row>

          <Row className="input--main--container mt-1">
            <Col className="left--section" md='6' sm='12'>
              <Row>
                <h5>Energía de la red eléctrica</h5>
                <small>Checkea en caso de haber utilizado energía proveniente de la red eléctrica</small>
              </Row>
              <Row className="mt-1">
                <div className="d-flex gap-1">
                  <div>

                    <input type='radio' className="form-check-input" value='true' {...register(useOfElectricNetwork, {required:true})}  />
                    <Label>Si</Label>
                  </div>
                  <div>

                    <input type='radio' className="form-check-input" value='average' {...register(useOfElectricNetwork, {required:true})}  />
                    <Label>Sí, pero no tengo la cifra</Label>
                  </div>
                  <div>

                    <input type='radio' className="form-check-input" value='false' {...register(useOfElectricNetwork, {required:true})}  />
                    <Label>No</Label>
                  </div>
                </div>
                {errors[index]?.energy?.useOfElectricNetwork?.types && <small className='text-danger mt-1'>Por favor, selecciona si hubo o no utilización de la red eléctrica</small>}

                <div className="mt-1">
                </div>
              </Row>
            </Col>
            <Col className={`right--section mt-1 ${watch(useOfElectricNetwork) === 'true' ? '' : 'd-none'}`}>
              <Label>Energía utilizada de la red eléctrica <strong>(kWh o mWh)</strong></Label>
              <InputGroup>
                <Controller
                    id={usageOfElectricNetwork}
                    name={usageOfElectricNetwork}
                    control={control}
                    defaultValue='0'
                    rules={{required:true}}
                    render = {({field}) => (
                        <Input type='number' name={usageOfElectricNetwork} invalid= {errors[index]?.usageOfElectricNetwork && true} {...field}/>
                    )}
                />
                <select className=" input-group-text " {...register(energyUOM)} >
                  <option selected>kWh</option>
                  <option>mWh</option>
                </select>
              </InputGroup>

              {errors[index]?.usageOfElectricNetwork?.types?.required && <small className='text-danger'>Por favor, ingresa la cantidad de energía proveniente de la red eléctrica</small>}
            </Col>

          </Row>
          <Row>

            <Row className="input--main--container mt-1">
              <Col className="left--section" md='6' sm='12'>
                <Row>
                  <h5>Energía renovable</h5>
                  <small>Clickea el botón en caso de haber utilizado energía renovable</small>
                </Row>
                <Row className="mt-1">
                  <div className="d-flex gap-1">
                    <div>

                      <input type='radio' className="form-check-input" value='true' {...register(useOfRenovableEnergy, {required:true})} />
                      <Label>Si</Label>
                    </div>
                    <div>

                      <input type='radio' className={`form-check-input`} value='average' {...register(useOfRenovableEnergy, {required:true})} />
                      <Label>Sí, pero no tengo la cifra</Label>
                    </div>
                    <div>

                      <input type='radio' className={`form-check-input`} value='false' {...register(useOfRenovableEnergy, {required:true})} />
                      <Label>No</Label>
                    </div>
                  </div>
                  {errors[index]?.energy?.useOfRenovableEnergy?.types?.required && <small className='text-danger  mt-1'>Por favor, selecciona si hubo o no energia renovable</small>}

                  <div className="mt-1">
                  </div>
                </Row>
              </Col>

              <Col className={`right--section mt-1  ${watch(useOfRenovableEnergy) === 'true' ? '' : 'd-none'}`} md='6' sm='12'>
                <div>

                  <Label>Energía renovable utilizada <strong>(%)</strong></Label>
                  <Controller
                      id={usageOfRenovableEnergy}
                      name={usageOfRenovableEnergy}
                      control={control}
                      defaultValue='0'
                      rules={{required:true}}
                      render = {({field}) => (
                          <Input type='number' name={usageOfRenovableEnergy} invalid= {errors[index]?.usageOfRenovableEnergy && true} {...field}/>
                      )}
                  />
                </div>

                {errors[index]?.usageOfRenovableEnergy?.types?.required && <small className='text-danger'>Por favor, ingresa el porcentaje utilizado en energía renovable</small>}
              </Col>

            </Row>
          </Row>
        </>}

        {loading && <Spinner/>}
      </Row>


  )
}

export default Energy
