import React from 'react'
import Point from './Point'

const EmisionLegend = () => {
    return (
        <>
            <Point name='Relleno sanitario'/>
            <Point name='Consumo de combustible'/>
            <Point name='Consumo de energia'/>
            <Point name='Reciclaje'/>
            <Point name='Alimentos'/>
            <Point name='Traslado'/>
        </>
    )
}

export default EmisionLegend