import React, {useState} from 'react'
import { Card, CardHeader, CardBody, CardTitle, CardText, InputGroup, Input, Form, Label, FormGroup, Button, Col, Row, FormFeedback, Spinner, Toast} from "reactstrap"
import { useForm, Controller } from 'react-hook-form'
import lm from '../assets/Images/lmd.png'
import AdminTable from '../Components/Admin/Table/AdminTable'
import { Toaster } from 'react-hot-toast'

const Admin = () => {
    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm({ criteriaMode: 'all'})
    const [haveCredentials, setHaveCredentials] = useState(false)
    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const onSubmit = (data) => {
        setLoading(true)
        fetch(`https://calculadoramde.cl/calculadora/api/formAPI/admin/${data.username}/${data.password}`)
            .then(response => response.json())
            .then(result => {
                if (result) {
                    fetch('https://calculadoramde.cl/calculadora/api/formAPI')
                        .then(response => response.json())
                        .then(result => {
                            setHaveCredentials(true)
                            setData(result)
                            setLoading(false)
                        })
                } else {
                    setError(true)
                    setLoading(false)
                }
            })
    }

    return (
        <>
            <Row className=' d-flex justify-content-center align-items-center vh-100 '>
                {!haveCredentials && (
                    <Col lg='3' sm='8' className='card p-4'>
                        <form  onSubmit={handleSubmit(onSubmit)}>
                            <Row className='mb-2'><img src={lm} className='img-fluid' /></Row>
                            <Row><h2>Inicio de sesión</h2></Row>
                            <Row>
                                <Col>
                                    <Label className='form-label' for='address'>
                                        Nombre de usuario
                                    </Label>
                                    <Controller
                                        id='username'
                                        name='username'
                                        defaultValue=''
                                        rules={{required:true}}
                                        control={control}
                                        render={({ field }) => (
                                            <Input placeholder='Francisca_Jimenez' invalid={errors.username && true} onClick = {() => setError(false)}{...field} />
                                        )}
                                    />
                                    {errors?.username?.types?.required && <FormFeedback>Por favor, ingresa tu nombre de usuario.
                                    </FormFeedback>}

                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col>
                                    <Label className='form-label' for='address'>
                                        Contraseña
                                    </Label>
                                    <Controller
                                        id='password'
                                        name='password'
                                        defaultValue=''
                                        rules={{required:true}}
                                        control={control}
                                        render={({ field }) => (
                                            <Input placeholder='********' type='password' invalid={errors.password && true} {...field} />
                                        )}
                                    />
                                    {errors?.password?.types?.required && <FormFeedback>Por favor, ingresa tu contraseña.
                                    </FormFeedback>}
                                    {error && <small className={'text-danger'}>No existe esta combinación en nuestros registros. Por favor intenta nuevamente o contáctate con Dyatec.</small>}
                                </Col>
                            </Row>
                            <button type='submit' className='btn btn-primary w-100 mt-2'>Iniciar sesión</button>

                        </form>

                    </Col>

                )}

                {haveCredentials && (
                    <Col lg='11' md='12' sm='12' className='card p-2 '>
                        {loading && <Spinner/>}
                        {(!loading && data) &&  <AdminTable data={data} setData={setData} setLoading={setLoading}/>}
                    </Col>
                )}
                <Toaster/>
            </Row>

        </>
    )
}

export default Admin