// ** React Imports
import { Fragment, useContext, useState} from 'react'

// ** Third Party Components
import { useForm, Controller } from 'react-hook-form'
import { ArrowLeft, ArrowRight } from 'react-feather'

// ** Reactstrap Imports
import { Label, Row, Col, Button, Form, Input, FormFeedback, Card, CardHeader, CardBody} from 'reactstrap'

import { DataContext } from '../../../Context/Context'

const EventData = ({ stepper }) => {


    const [cat, setCat] = useState('Show en vivo')

    const defaultValues = {eventName:'', eventType:'', eventCategory: '', amountOfEvents:1}
    // ** Hooks
    const {
        control,
        handleSubmit,
        register,
        setValue,
        formState: { errors }
    } = useForm({criteriaMode: 'all', defaultValues})

    // ** Context
    /**
     * setNumberOfEvents -> handler de eventos para acceder mas facil
     * co2e -> total co2e
     * setData -> agregar a Data total
     */
    const { setNumberOfEvents, setData, setGoToDeclaration} = useContext(DataContext)

    //** onSubmit
    const onSubmit = (data) => {
        const generalEventData = data
        setData(currData => ({...currData, generalEventData}))
        setGoToDeclaration(true)
        stepper.next()

    }
    console.log(errors)
// ** Custom functions
    const onk = (e) => {
        setValue(e.target.name, e.target.value)
        setNumberOfEvents(e.target.value)
        console.log(e.target.value)
    }

    return (
        <Fragment>

            <Row className='content-header'>

                <div className='d-flex flex-row align-items-center justify-content-between px-2  my-2'>
                    <div>
                        <h5 className='mb-0'>Información del evento</h5>
                        <div>
                            <small>Ingresa a continuación la información general respecto al evento.
                               </small>
                        </div>
                    </div>
                </div>

                <CardBody >
                    <Form onSubmit={handleSubmit(onSubmit)}>

                        <Row>
                            <Col md='6' className='mb-1'>
                                <Label
                                    className='form-label'
                                    for='eventName'
                                >
                                    Nombre del evento
                                </Label>

                                <Controller
                                    id='eventName'
                                    name='eventName'
                                    rules={{required:true, minLength:3}}
                                    control={control}
                                    render={({ field}) => (
                                        <Input
                                            placeholder= 'Lollapalooza'
                                            invalid={errors.eventName && true}
                                            {...field}
                                        />
                                    )}
                                />
                                {errors.eventName?.types?.required && <FormFeedback>Por favor, ingresa el nombre del evento</FormFeedback>}
                                {errors.name?.types?.minLength && <FormFeedback>Por favor, ingresa un nombre del evento de mas de 3 caracteres</FormFeedback>}
                            </Col>

                            <Col  md='6' className='mb-1'>
                                <Label
                                    className='form-label'
                                    for='eventType'
                                >
                                    Tipo de evento
                                </Label>

                                <select
                                    className={`form-select ${errors?.eventType && true ? 'border-2 border-danger' : ''}`}
                                    {...register('eventType', {required:true})}
                                    onChange= {(e) => { e.target.value === 'Gira' ? setCat('Gira') : setCat('Show en vivo') }}
                                    invalid={errors.eventType && true}

                                >
                                    <option disabled selected value=''> Selecciona un tipo de evento</option>
                                    <option>Show en vivo</option>
                                    <option>Gira</option>
                                </select>

                                {errors.eventType?.types?.required && <small className='text-danger'>Por favor, escoge un tipo de evento</small>}

                            </Col>
                        </Row>

                        <Row>
                            <Col md='6' className='mb-1'>
                                <Label
                                    className='form-label'
                                    for='eventCategory'
                                >
                                    Categoría del evento
                                </Label>

                                {cat === 'Show en vivo' ? (
                                    <select className={`form-select ${errors?.eventCategory && true ? 'border-2 border-danger' : ''}`}
                                            {...register('eventCategory', {required:true})} invalid={errors.eventCa && true} >
                                        <option disabled selected value=''> Selecciona una categoría de evento</option>
                                        <option>Concierto</option>
                                        <option>Festival</option>
                                        <option>Feria o Charla</option>
                                    </select>

                                ) : (

                                    <select className='form-control' {...register('eventCategory')}>
                                        <option disabled selected value=''> Selecciona una categoría de evento</option>
                                        <option>Gira Nacional</option>
                                        <option>Gira Internacional</option>

                                    </select>
                                )}

                                {errors.eventCategory?.types?.required && <small className='text-danger'>Por favor, escoge una categoria del evento </small>}

                            </Col>


                            <Col md='6' className='mb-1'>
                                <Label
                                    className='form-label'
                                    for='amountOfEvents'
                                >
                                    Número de eventos
                                </Label>

                                <Controller
                                    id='amountOfEvents'
                                    name='amountOfEvents'
                                    control={control}
                                    rules={{required:true, min:1, max:10}}
                                    render={() => (
                                        <Input
                                            placeholder= 'Numero de eventos'
                                            name='amountOfEvents'
                                            type='number'

                                            className={`form-select ${errors?.amountOfEvents && true ? 'border-2 border-danger' : ''}`}
                                            invalid={errors.amountOfEvents && true}
                                            onChange = { e => onk(e) }

                                        />
                                    )}
                                />
                                {errors.amountOfEvents?.types?.required && <FormFeedback>Por favor, ingresa el número de eventos</FormFeedback>}
                                {errors.amountOfEvents?.types?.min && <FormFeedback>Por favor, ingresa al menos 1 evento</FormFeedback>}
                                {errors.amountOfEvents?.types?.max && <FormFeedback>Por favor, ingresa máximo 10 eventos</FormFeedback>}

                            </Col>

                        </Row>


                        <Col className='d-flex justify-content-between mt-3'>
                            <Button type='button' color='primary' className='btn-prev' onClick={() => stepper.previous()}>
                                <ArrowLeft size={14} className='align-middle me-sm-25 me-0'></ArrowLeft>
                                <span className='align-middle d-sm-inline-block d-none'>Ir atrás</span>
                            </Button>
                            <Button type='submit' color='primary' className='btn-next'>
                                <span className='align-middle d-sm-inline-block d-none'>Siguiente</span>
                                <ArrowRight size={14} className='align-middle ms-sm-25 ms-0'></ArrowRight>
                            </Button>
                        </Col>

                    </Form>
                </CardBody>


            </Row>

        </Fragment>
    )
}

export default EventData
