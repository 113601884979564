const openrouteservice = require("openrouteservice-js")
const Matrix = new openrouteservice.Matrix({ api_key: "5b3ce3597851110001cf6248cd4407f22b474bacb82a30337b468d44"})

const onError = (lonLat1, lonLat2) => {
    const lat1 = lonLat1[1]
    const lat2 = lonLat2[1]
    const lon1 = lonLat1[0]
    const lon2 = lonLat2[0]

    if ((lat1 === lat2) && (lon1 === lon2)) {
        return 0
    } else {
        const radlat1 = Math.PI * lat1 / 180
        const radlat2 = Math.PI * lat2 / 180
        const theta = lon1 - lon2
        const radtheta = Math.PI * theta / 180
        let dist = ((Math.sin(radlat1)) * (Math.sin(radlat2))) + ((Math.cos(radlat1)) * (Math.cos(radlat2)) * (Math.cos(radtheta)))
        if (dist > 1) {
            dist = 1
        }
        dist = Math.acos(dist)
        dist = dist * 180 / Math.PI
        dist = dist * 60 * 1.1515
        dist = dist * 1.609344
        dist = dist.toFixed(2)
        //dist = Intl.NumberFormat('de-DE', {style:'decimal'}).format(dist)

        console.log('distancia calculada con la formula', dist)
        return dist
    }
}

export const getDistance = async (address1, address2) => {
    let result = 0
    const response = await fetch(
        `https://us1.locationiq.com/v1/search?key=pk.1e45912d65eb06dfb57811c34e744122&q=${address1}&format=json`
    )
    const dataFromAddress1 = await response.json()
    console.log(dataFromAddress1)
    const address1LonLat = ([Number(dataFromAddress1[0].lon), Number(dataFromAddress1[0].lat)])

    const response2 = await fetch(
        `https://us1.locationiq.com/v1/search?key=pk.1e45912d65eb06dfb57811c34e744122&q=${address2}&format=json`
    )
    const dataFromAddress2 = await response2.json()
    console.log(dataFromAddress2)
    const address2LonLat =  ([Number(dataFromAddress2[0].lon), Number(dataFromAddress2[0].lat)])

    await Matrix.calculate({
        locations: [address1LonLat, address2LonLat],
        profile:'driving-car',
        sources: ['all'],
        destinations: ['all'],
        metrics:["distance"],
        units:"km"
    })
        .then((response) => {
            if (!response.distances[0].includes(null)) {
                console.log('response', response.distances[0][1])
                console.log('calculado con API')
                result = response.distances[0][1]
                //result = Intl.NumberFormat('de-DE', {style:'decimal'}).format(result)
                //return response.distances[0][1]
            } else {
                console.log('calculado con formulinha')
                result = onError(address1LonLat, address2LonLat)
                // return onError(address1LonLat, address2LonLat)
            }

        })
        .catch(err => {
            console.log('hubo un error', err)
        })

    return result

}
