import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert, Button } from 'reactstrap'
import { Link } from 'react-router-dom'

const CustomModal = ({centeredModal, setCenteredModal, setGoNext}) => {
    return (
        <div className='vertically-centered-modal'>
            <Modal isOpen={centeredModal} toggle={() => setCenteredModal(!centeredModal)} className='modal-dialog-centered'>
                <ModalHeader toggle={() => setCenteredModal(!centeredModal)}>¿Estás segur@ que quieres avanzar?</ModalHeader>
                <ModalBody>
                    Revisa que toda tu información esté en el formulario. Luego no podrás retroceder a hacer cambios.
                </ModalBody>
                <ModalFooter>

                    {<Link to='/resumen' onClick = {() => { setCenteredModal(!centeredModal); setGoNext(true) } }>
                        <span className='btn btn-success w-100'>Ir al resumen</span>
                    </Link>   }
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default CustomModal