// ** React Imports
import { useRef, useState } from 'react'

// ** Steps
import UserData from '../Components/Forms/UserForms/UserData'
import EventData from '../Components/Forms/EventForms/EventData'
import EventMoreInformation from '../Components/Forms/EventForms/EventMoreInformation'
import Declaration from '../Components/Forms/DeclarationForms/Main/Declaration'
import Wizard from '@components/wizard'


const FormWizard = () => {
  // ** Ref
  const ref = useRef(null)

  // ** State
  const [stepper, setStepper] = useState(null)

  const steps = [
    {
        id: 'DatosDelDeclarante',
        title: 'Datos del declarante',
        subtitle: '',
        content:  <UserData stepper={stepper}  />
    },
    {
        id: 'DatosDelEvento',
        title: 'Datos del evento',
        subtitle: '',
        content: <EventData stepper={stepper}  />
    },
    {
        id: 'DetalleDeLosEventos',
        title: 'Detalle de los eventos',
        subtitle: '',
        content: <EventMoreInformation stepper={stepper}  />
    },
    {
      id: 'Declaracion',
      title: 'Declaracion de Huella de Carbono',
      subtitle: ' ',
      content: <Declaration stepper={stepper}  />
  }
  ]

  return (
    <>
      <Wizard  instance={el => setStepper(el)} ref={ref} steps={steps} />
    </>
  )
}

export default FormWizard
