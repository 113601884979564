import React from 'react'
import Bar from './Bar'

const EmisionBars = ({usage, selected, data1}) => {
    return (
        <>
            <Bar usage={usage} selected={selected} name='Relleno sanitario' type='emision' data1={data1}/>
            <Bar usage={usage} selected={selected} name='Consumo de combustible' type='emision' data1={data1}/>
            <Bar usage={usage} selected={selected} name='Consumo de energia' type='emision' data1={data1}/>
            <Bar usage={usage} selected={selected} name='Reciclaje' type='emision' data1={data1}/>
            <Bar usage={usage} selected={selected} name='Alimentos' type='emision' data1={data1}/>
            <Bar usage={usage} selected={selected} name='Traslado' type='emision' data1={data1}/>
        </>
    )
}

export default EmisionBars