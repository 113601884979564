import React from 'react'
import Bar from './Bar'

const WaterBars = ({usage, selected, data1}) => {
    return (
        <>
            <Bar usage={usage} selected={selected} name='Agua de la red' type='agua' data1={data1}/>
            <Bar usage={usage} selected={selected} name='Agua transportada' type='agua' data1={data1}/>
            <Bar usage={usage} selected={selected} name='Agua proveniente de noria' type='agua' data1={data1}/>
        </>
    )
}

export default WaterBars