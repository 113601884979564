// ** React Imports
import { Fragment, useContext, useState} from 'react'

// ** Third Party Components
import { useForm, FormProvider } from 'react-hook-form'
import { ArrowLeft, ArrowRight } from 'react-feather'
import countries1 from '../../../Countries/countries.json'

// ** Reactstrap Imports
import {Row, Col, Button, Form, CardBody,  Accordion} from 'reactstrap'

import { DataContext } from '../../../Context/Context'

import EventDetails from './EventDetails'


const EventMoreInformation = ({stepper}) => {
    /**
     * Context
     * co2e -> cantidad total de co2
     * setData -> agregar info a Data
     * setResults -> hacer objeto para guardar los totales por evento
     * results -> objeto donde se guarda el total de cada evento
     */
    const { setData, numberOfEvents} = useContext(DataContext)

    // ** React-hook-form
    const methods = useForm({criteriaMode: 'all'})

    // ** Submit
    const onSubmit = (data) => {
        const eventDetails = data
        setData(currData => ({...currData, eventDetails}))
        stepper.next()

    }
    const newMeth = {...methods, onSubmit}

    const onGoBack = () => {
        stepper.previous()
    }

    // ** Se utiliza para cambiar el state de la cantidad de eventos

    // ** acordion
    const [open, setOpen] = useState('')
    const toggle = id => {
        open === id ? setOpen() : setOpen(id)
    }


    return (
        <FormProvider {...newMeth} >
            <Row className='content-header  '>
                <div className='d-flex flex-row align-items-center justify-content-between px-1  my-2'>
                    <div>
                        <h5 className='mb-0'>Información del evento</h5>
                        <div>
                            <small>Ingresa a continuación la información general sobre el evento <br/>
                                Luego de este paso podrás rellenar información mas detallada con respecto a <br/> cada uno de los eventos  en caso de ser más de uno.</small>
                        </div>
                    </div>
                </div>

                <CardBody className='h-50'>
                    <Form onSubmit={methods.handleSubmit(onSubmit)} >

                        <Accordion open={open} toggle={toggle} className={`accordion-margin`}>

                            {Array.apply(null, {length: numberOfEvents}).map((e, index) => (
                                <EventDetails index={index} countries1={countries1} key={index}/>
                            ))}

                        </Accordion>

                        <Col className='d-flex justify-content-between mt-3'>
                            <Button type='button' color='primary' className='btn-prev' onClick={() => onGoBack()}>
                                <ArrowLeft size={14} className='align-middle me-sm-25 me-0'></ArrowLeft>
                                <span className='align-middle d-sm-inline-block d-none'>Ir atrás</span>
                            </Button>
                            <Button type='submit' color='primary' className='btn-next'>
                                <span className='align-middle d-sm-inline-block d-none'>Siguiente</span>
                                <ArrowRight size={14} className='align-middle ms-sm-25 ms-0'></ArrowRight>
                            </Button>
                        </Col>

                    </Form>
                </CardBody>


            </Row>

        </FormProvider>
    )
}

export default EventMoreInformation