import React, {useContext, useEffect} from "react"
import { Col, Input, Row, Label, FormFeedback, Button, InputGroup, UncontrolledTooltip } from "reactstrap"
import { Controller, useFieldArray, useFormContext } from "react-hook-form"
import { getDistance } from "../../../../Helpers/distanceCalculator"
import { Plus, Minus, HelpCircle } from "react-feather"
import Select from 'react-select'
import { DataContext } from "../../../../Context/Context"

const Transfer = ({ index }) => {

    const {
        control,
        formState: { /*errors*/ },
        watch,
        setValue,
        register,
        formState:{errors}
    } = useFormContext()

    const options = [{label: 'Bicicleta o pie', value: 'bicicleta_pie' }, {label: 'Bus interurbano', value: 'bus_interurbano'}, {label: 'Bus local', value: 'bus_local'}, {label: 'Bus transantiago', value:'bus_transantiago'}, {label: 'Colectivo', value: 'colectivo'}, {label:'metro', value:'metro'}, {label: 'Metrotren', value:'metrotren'}, {label: 'motocicleta', value:'motocicleta'}, {label: 'Van', value:'van'}, {label: 'Vehiculo particular desconocido', value: 'vehi_part_desconocido' }, {label: 'Vehiculo particular diesel', value: 'vehi_part_diesel' }, {label: 'Vehiculo particular bencina', value: 'vehi_part_bencina' }, {label: 'Avion viaje Chile continental', value: 'avion_tray_dom_chile_cont' }, {label: 'Avion viaje internacional', value: 'avion_tray_inter_prom' }]
    const chargeTransfer = `[${index}.charge.chargeTransfer]`
    const artistsTransfer = `[${index}.artist.artistTransfer]`
    const {values, usage, data1} = useContext(DataContext)
    const {
        fields: artistFields,
        append: artistAppend,
        remove: artistRemove
    } = useFieldArray({ control, name: `[${index}.artist.artistFields]` })

    const {
        fields: chargeFields,
        append: chargeAppend,
        remove: chargeRemove
    } = useFieldArray({ control, name: `[${index}.charge.chargeFields]` })

    const onChargeDistanceCalculation = (index, index1) => {
        (getDistance(watch(`[${index}.charge.chargeFields[${index1}].origin]`), watch(`[${index}.charge.chargeFields[${index1}].end]`)))
            .then(result => {
                setValue(`[${index}.charge.chargeFields[${index1}].distance]`, result)
            })
    }
    const onArtistDistanceCalculation = (index, index1) => {
        (getDistance(watch(`[${index}.artist.artistFields[${index1}].origin]`), watch(`[${index}.artist.artistFields[${index1}].end]`)))
            .then(result => setValue(`[${index}.artist.artistFields[${index1}].distance]`, result))
    }
    const calculateCharge = (data, index) => {
        let total1 = 0
        let distance = 0
        let timesDone = 0
        const info = data[index]?.charge
        if (info) {

            const totalsize = data[index]?.charge?.chargeFields.length
            if (totalsize < 1) {
                console.log('retorne')
                return 0
            } else {
                for (let i = 0; i < totalsize; i++) {
                    if (data[index]?.charge) {
                        timesDone = data[index]?.charge.chargeFields[i]?.timesDone
                        console.log('timesdone', timesDone)
                        distance = data[index]?.charge.chargeFields[i]?.distance * 2
                        if (distance) {
                            console.log('distance', distance)
                            const type = data[index]?.charge.chargeFields[i]?.type
                            console.log('type', type)
                            if (type) {
                                let charge = data[index]?.charge.chargeFields[i]?.charge
                                const uom = data[index]?.charge.chargeFields[i]?.UOM
                                if (uom === 'kg') charge = Number(Number(charge) / 1000)
                                total1 += (Number(timesDone) * Number(distance) * Number(values?.[type]) * Number(charge))
                            }
                        }
                    }

                }
            }
        }
        return total1
    }

    const calculateArtist = (data, index) => {
        let total1 = 0
        const info = data[index]?.artist
        if (info) {
            const totalsize = data[0]?.artist?.artistFields.length

            for (let i = 0; i < totalsize; i++) {
                const transportsSize = data[index]?.artist?.artistFields[i]?.transportsUsed?.length
                if (transportsSize > 0) {

                    let distance = data[index]?.artist?.artistFields[i]?.distance
                    if (!distance) { distance = 0 }
                    const distanceToMultiply = distance / transportsSize
                    const transportes = data[index]?.artist?.artistFields[i]?.transportsUsed
                    const transportinhos = transportes.map((transporte) => transporte?.value)
                    let personas = data[index]?.artist?.artistFields[i].people
                    if (!personas) personas = 0
                    for (let j = 0; j < transportsSize; j++) {
                        total1 = Number(total1) + (Number(distanceToMultiply) * Number(values?.[transportinhos[j]]) * Number(personas))
                    }
                }
            }
        }
        return total1
    }

    const general = (data, index) => {
        const res1 = Number(calculateArtist(data, index) / 1000)
        const res2 = Number(calculateCharge(data, index) / 1000)
        if (chargeTransfer === 'false') {
            res1 = 0
        }
        if (artistsTransfer === 'false') {
            res2 = 0
        }
        let attendeesTransfer = 0
        if (data1.eventDetails) {
            const attendeesNumber = data1.eventDetails[index].attendeesNumber
            attendeesTransfer = (Number(attendeesNumber) * Number(values.promedio_traslado_persona) / 1000)
        }
        const obj =  {traslado_artistas:res1, traslado_carga: res2, traslado_asistentes: attendeesTransfer, total:(res1 + res2 + attendeesTransfer)}
        usage[index].transfer = obj

        return (Number(res1) + Number(res2))
    }

    const total = general(watch(), index)
    useEffect(() => {
        general(watch(), index)
    }, [watch()])

    return (
        <Row className="parent--row d-flex gap-2">
            <Row className=""><Col className="d-flex justify-content-end"><h5 className="text-success">+ {total > 1 ? `${total.toFixed(2)} TON ` : `${(total * 1000).toFixed(2)} Kg `}CO<sub>2</sub>e</h5></Col></Row>

            <Row className="title--row">
                <Col>
                    <h5>Traslado de carga</h5>
                    <small>Checkea en caso de haber trasladado carga</small>
                </Col>
            </Row>
            <Row className="d-flex align-items-center">
                <Row className="mt-1">
                    <div className="d-flex gap-1">
                        <div>

                            <input type='radio' className="form-check-input" value='true' {...register(chargeTransfer, {required:true})}  />
                            <Label>Si</Label>
                        </div>
                        <div>

                            <input type='radio' className="form-check-input" value='false' {...register(chargeTransfer, {required:true})}  />
                            <Label>No</Label>
                        </div>
                    </div>
                    <div className="mt-1">
                    </div>
                    {errors[index]?.charge?.chargeTransfer?.types?.required && <small className='text-danger  mt-1'>Por favor, selecciona si hubo o no traslado de carga</small>}

                </Row>
                {watch(chargeTransfer) === 'true' && (
                    <>
                        {chargeFields.map((item, index1) => {
                            return (
                                <Row className="mt-1">
                                    <Col
                                        className="d-flex flex-column gap-1"
                                        sm="12"
                                        md="7"
                                        key={item.id}
                                    >
                                        <Row className="d-flex align-items-end">
                                            <Col>
                                                <Label>Cantidad de veces que se realizó este recorrido</Label>
                                                <Controller
                                                    control={control}
                                                    name={`[${index}.charge.chargeFields[${index1}].timesDone]`}
                                                    defaultValue='1'
                                                    render={({ field }) => (
                                                        <Input
                                                            key={item.id}
                                                            type="text"
                                                            placeholder="3"
                                                            name={`[${index}.charge.chargeFields[${index1}].timesDone]`}
                                                            defaultValue=""
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </Col>
                                            <Col className="" sm='12' md='6'>
                                                <Label>Cantidad transportada</Label>
                                                <InputGroup>
                                                    <Controller
                                                        control={control}
                                                        name={`[${index}.charge.chargeFields[${index1}].charge]`}
                                                        defaultValue='0'
                                                        render={({ field }) => (
                                                            <Input
                                                                key={item.id}

                                                                type="number"
                                                                placeholder="3"
                                                                name={`[${index}.charge.chargeFields[${index1}].charge]`}
                                                                defaultValue=""
                                                                {...field}
                                                            />
                                                        )}
                                                    />
                                                    <select className=" input-group-text " {...register(`[${index}.charge.chargeFields[${index1}].UOM]`)} >
                                                        <option selected>kg</option>
                                                        <option>TON</option>
                                                    </select>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <Row>

                                            <Col>
                                                <Label>Tipo de transporte</Label>
                                                <select className="form-select"  key={item.id}
                                                        {...register(`[${index}.charge.chargeFields[${index1}].type]`, {required:true})} control={control}>
                                                    <option value='' selected disabled>Elige un tipo de transporte</option>
                                                    <option value='cam_rigid_7tMax_prom'>Camion rigido entre (3.5 - 7.5 toneladas)</option>
                                                    <option value='cam_rigid_17tMax_prom'>Camion rigido entre (7.5 - 17 toneladas) </option>
                                                    <option value='cam_rigid_nolimit_prom'>Camion rigido mas de 17 toneladas</option>
                                                    <option value='van_promedio_diesel'>Van promedio sobre 3.5 toneladas - Diesel</option>
                                                    <option value='van_promedio_gasolina'>Van promedio sobre 3.5 toneladas - Gasolina</option>
                                                </select>
                                            </Col>

                                        </Row>


                                        <Row>
                                            <Col>
                                                <Label>Lugar de origen</Label>
                                                <HelpCircle id="charge_distance" className='mx-1' size='17' color='gray'/>
                                                <UncontrolledTooltip placement='right' target='charge_distance'>
                                                    Si no tienes la dirección exacta, ingresa la comuna y el país.
                                                </UncontrolledTooltip>
                                                <Controller
                                                    control={control}
                                                    name={`[${index}.charge.chargeFields[${index1}].origin]`}
                                                    render={({ field }) => (
                                                        <Input
                                                            key={item.id}

                                                            type="text"
                                                            placeholder="Dublé Almeyda 3224, Chile"
                                                            name={`[${index}.charge.chargeFields[${index1}].origin]`}
                                                            defaultValue=""
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </Col>
                                            <Col>
                                                <Label>Lugar de destino</Label>
                                                <Controller
                                                    control={control}
                                                    name={`[${index}.charge.chargeFields[${index1}].end]`}
                                                    render={({ field }) => (
                                                        <Input
                                                            key={item.id}

                                                            type="text"
                                                            placeholder="Nataniel Cox 251, Chile"
                                                            name={`[${index}.charge.chargeFields[${index1}].end]`}
                                                            defaultValue=""
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="d-flex align-items-center">
                                            <Col className="" ><p className={`${watch(`[${index}.charge.chargeFields[${index1}].distance]`) > 0 ? '' : 'd-none'}`}>Distancia: {watch(`[${index}.charge.chargeFields[${index1}].distance]`)} km</p></Col>
                                            <Col className="" >
                                                <Row className="d-flex justify-content-center align-items-center">
                                                    <Col><Button
                                                        color="flat-secondary"
                                                        className="btn-icon "
                                                        size='sm'
                                                        onClick={() => { chargeRemove(index1) }}>
                                                        <span className="align-middle ms-25">Eliminar recorrido</span>
                                                    </Button>
                                                    </Col>
                                                    <Col>
                                                        <Button type="button" className="" color="success" size='sm' onClick={() => onChargeDistanceCalculation(index, index1)}>
                                                            Calcular distancia
                                                        </Button>
                                                    </Col>

                                                </Row>
                                            </Col>

                                        </Row>
                                    </Col>
                                </Row>
                            )
                        })}
                        <Col className="mt-1">
                            <Button
                                className="btn-icon"
                                size='sm'
                                color="primary"
                                onClick={() => {
                                    chargeAppend({timesDone:'', type:'', origin:'', end:'', distance:''})
                                }}
                            >
                                <Plus size={14} color='white'/>
                                <span className="align-middle ms-25">Agregar recorrido</span>
                            </Button>
                        </Col>
                    </>

                )}

            </Row>

            {/* TRASLADO DE ARTISTAS*/}
            <Row className="title--row">
                <Col>
                    <h5>Traslado de artistas</h5>
                    <small>
                        Checkea en caso de haber trasladado artistas. Por ahora todo lo de{" "}
                        <br /> este campo es de prueba
                    </small>
                </Col>
            </Row>

            <Row className="d-flex align-items-center gap-2">
                <Row className="mt-1">
                    <div className="d-flex gap-1">
                        <div>

                            <input type='radio' className="form-check-input" value='true' {...register(artistsTransfer, {required:true})}  />
                            <Label>Si</Label>
                        </div>
                        <div>

                            <input type='radio' className="form-check-input" value='false' {...register(artistsTransfer, {required:true})}  />
                            <Label>No</Label>
                        </div>
                    </div>
                    <div className="mt-1">
                    </div>
                    {errors[index]?.artist?.artistTransfer?.types?.required && <small className='text-danger  mt-1'>Por favor, selecciona si hubo o no traslado de artistas</small>}

                </Row>
                {watch(artistsTransfer) === 'true' && (
                    <>
                        {artistFields.map((item, index1) => {
                            return (
                                <Row>
                                    <Col
                                        className="d-flex flex-column gap-1"
                                        sm="12"
                                        md="7"
                                        key={item.id}
                                    >
                                        <div>
                                            <Label>Nombre del artista</Label>
                                            <Controller
                                                control={control}
                                                name={`[${index}.artist.artistFields[${index1}].name]`}
                                                render={({ field }) => (
                                                    <Input
                                                        key={item.id}

                                                        type="text"
                                                        placeholder="Nombre del artista"
                                                        name={`[${index}.artist.artistFields[${index1}].name]`}
                                                        defaultValue=""
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </div>

                                        <div>
                                            <Label>Transportes utilizados (selecciona los que sean necesarios)</Label>
                                            <Controller
                                                control={control}
                                                name={`[${index}.artist.artistFields[${index1}].transportsUsed]`}
                                                render={({ field }) => (
                                                    <Select
                                                        type="text"
                                                        isMulti
                                                        options={options}
                                                        key={item.id}

                                                        name={`[${index}.artist.artistFields[${index1}].transportsUsed]`}

                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </div>

                                        <div>
                                            <Label>Cantidad de personas</Label>
                                            <Controller
                                                control={control}
                                                name={`[${index}.artist.artistFields[${index1}].people]`}
                                                render={({ field }) => (
                                                    <Input
                                                        type="number"
                                                        key={item.id}

                                                        name={`[${index}.artist.artistFields[${index1}].people]`}
                                                        defaultValue=""
                                                        placeholder='2'
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </div>

                                        <Row>
                                            <Col sm='12' md='6' className="mb-1">
                                                <Label>Lugar de origen</Label>
                                                <HelpCircle id="artist_distance" className='mx-1' size='17' color='gray'/>
                                                <UncontrolledTooltip placement='right' target='artist_distance'>
                                                    Si no tienes la dirección exacta, ingresa la comuna y el país.
                                                </UncontrolledTooltip>
                                                <Controller
                                                    control={control}
                                                    name={`[${index}.artist.artistFields[${index1}].origin]`}
                                                    render={({ field }) => (
                                                        <Input
                                                            key={item.id}

                                                            type="text"
                                                            placeholder="Dublé Almeyda 3224, Chile"
                                                            name={`[${index}.artist.artistFields[${index1}].origin]`}
                                                            defaultValue=""
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </Col>
                                            <Col>
                                                <Label>Lugar de destino</Label>
                                                <Controller
                                                    control={control}
                                                    name={`[${index}.artist.artistFields[${index1}].end]`}
                                                    key={item.id}
                                                    render={({ field }) => (
                                                        <Input
                                                            key={item.id}

                                                            type="text"
                                                            placeholder="Nataniel Cox 125, Chile"
                                                            name={`[${index}.artist.artistFields[${index1}].end]`}
                                                            defaultValue=""
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="d-flex align-items-center" >
                                            <Col className="" ><p className={`${watch(`[${index}.artist.artistFields[${index1}].distance]`) > 0 ? '' : 'd-none'}`}>Distancia: {watch(`[${index}.artist.artistFields[${index1}].distance]`)} km</p></Col>
                                            <Col className="" md='7' >
                                                <Row className="d-flex justify-content-center align-items-center" >
                                                    <Col><Button
                                                        color="flat-secondary"
                                                        className="btn-icon "
                                                        size='sm'
                                                        onClick={() => { artistRemove(index) }}>
                                                        <span className="align-middle ms-25">Eliminar recorrido</span>
                                                    </Button>
                                                    </Col>
                                                    <Col>
                                                        <Button type="button" className="" color="success" size='sm' onClick={() => onArtistDistanceCalculation(index, index1)}>
                                                            Calcular distancia
                                                        </Button>
                                                    </Col>

                                                </Row>

                                            </Col>

                                        </Row>
                                    </Col>
                                </Row>
                            )
                        })}
                        <Col className="">
                            <Button
                                className="btn-icon"
                                size='sm'
                                color="primary"
                                onClick={() => {
                                    artistAppend()
                                }}
                            >
                                <Plus size={14} color='white'/>
                                <span className="align-middle ms-25">Agregar artista</span>
                            </Button>
                        </Col>
                    </>
                )}

            </Row>
        </Row>
    )
}

export default Transfer
