import React from 'react'
import { Trash } from 'react-feather'
import { Row, Col } from 'reactstrap'
import Agua from '../../assets/icons/agua.png'
import Combustible from '../../assets/icons/combustible.png'
import Energia from '../../assets/icons/energia.png'
import Relleno from '../../assets/icons/relleno.png'

const GeneralData = ({selected}) => {
  return (
    <div className='w-100'>
        <Row className='d-flex gap-2 py-2'>
            <Row>
                <Col className='d-flex justify-content-center align-items-center'>
            <h1 className='text-black' style={{fontWeight:'bold'}}>
            DATOS GENERALES
            </h1>
                </Col>
            </Row>
            <Row>
                <Col className='d-flex flex-column justify-content-center align-items-center gap-1 '>
                <img src={Energia} style={{height:'55px'}}/>
                <div>
                <h3 className='text-center' style={{fontWeight:'bold', color:'#e21d34'}}>{selected.energy.electricNetwork.usage} {selected.energy.electricNetwork.uom}</h3>
                <p className='' style={{fontWeight:'bold'}}>Consumo de energia</p>
                </div>
                </Col>
                <Col className='d-flex flex-column justify-content-center align-items-center gap-1'>
                <img src={Relleno}  style={{height:'55px'}}/>

                <div className='text-center'>
                <h3 className='text-center ' style={{fontWeight:'bold', color:'#e21d34'}}>{selected.residuos.residuos.usage} {selected.residuos.residuos.uom} </h3>
                <p  style={{fontWeight:'bold'}}>Relleno sanitario</p>
                </div>
                </Col>
                <Col className='d-flex flex-column justify-content-center align-items-center gap-1'>
                <img src={Agua}  style={{height:'55px'}}/>
                <div>
                <h3 className='text-center' style={{fontWeight:'bold', color:'#e21d34'}}>{selected.agua.agua_red.usage} {selected.agua.agua_red.UOM}</h3>
                <p  style={{fontWeight:'bold'}}>Consumo de agua</p>
                </div>
                </Col>
                <Col className='d-flex flex-column justify-content-center align-items-center gap-1'>
                <img src={Combustible}  style={{height:'55px'}}/>

                    <div>

                <h3 className='text-center' style={{fontWeight:'bold', color:'#e21d34'}}>{selected.fuels.bencina.usage} {selected.fuels.bencina.bencinaUOM}</h3>
                <p className='text-center'  style={{fontWeight:'bold'}}>Consumo total de combustible</p>
                    </div>
                </Col>
            </Row>
        </Row>
    </div>
  )
}

export default GeneralData