import { Fragment } from 'react'

// ** Third Party Components
import Cleave from 'cleave.js/react'
import 'cleave.js/dist/addons/cleave-phone.cl'


import { Label } from 'reactstrap'

const PhoneMask = ({onChange, name, id, invalid}) => {

    const options = {phone:true, phoneRegionCode: 'CL', prefix:'+56'}
    return (
        <Fragment>
            <Cleave className={`form-control ${invalid ? 'is-invalid' : ''}`} options={options} id={id}  name={name} onChange={onChange}/>
        </Fragment>
    )
}

export default PhoneMask