import React from 'react'

const Bar = ({usage, name, selected, type, data1}) => {
    const calculateTotal = (usage, selected) => {
        let total = 0
        total += usage[selected].alimentation.usage
        total += usage[selected].energy.usage
        total += usage[selected].fuel.total
        total += usage[selected].hotel.usage
        total += usage[selected].merchandising.usage
        total += usage[selected].transfer.total
        total += usage[selected].waste.total
        total += usage[selected].water.total
        return total
    }

    const totalAgua = Number(data1.carbonDeclaration[selected].agua.agua_camion.usage) + Number(data1.carbonDeclaration[selected].agua.agua_noria.usage) + Number(data1.carbonDeclaration[selected].agua.agua_red.usage)
    const colors = {
        'Relleno sanitario' : '#1bcdb8',
        'Consumo de combustible' : '#7c70f0',
        'Consumo de energia': '#ffa3a3',
        Reciclaje: '#11c77b',
        Alimentos: '#fdef21',
        Traslado : '#3fa9f5',
        'Agua de la red': '#0171bd',
        'Agua transportada': '#28abe3',
        'Agua proveniente de noria' : '#7fd9ec'
    }

    const usages = {
        'Relleno sanitario' : usage[selected].waste.residuos,
        'Consumo de combustible' : usage[selected].fuel.total,
        'Consumo de energia': usage[selected].energy.usage,
        Reciclaje: usage[selected].waste.reciclaje,
        Alimentos: usage[selected].alimentation.usage,
        Traslado : usage[selected].transfer.traslado_artistas,
        'Agua de la red': Number(data1.carbonDeclaration[selected].agua.agua_red.usage),
        'Agua transportada' : Number(data1.carbonDeclaration[selected].agua.agua_camion.usage),
        'Agua proveniente de noria' :  Number(data1.carbonDeclaration[selected].agua.agua_noria.usage)
    }

    return (<>
            {type === 'emision' && (
                <div className='d-flex flex-row gap-1 align-items-center justify-content-start'>
                    <div style={{height:'25px', width:'350px', border:'2px black solid'}}>
                        <div style={{width:`${(usages[name] / (calculateTotal(usage, selected))) * 100}%`, height:'100%', backgroundColor: colors[name]}}></div>
                    </div>
                    <h5 style={{color:'black'}}>{Number(usages[name]).toFixed(2)}</h5>
                </div>
            )}

            {type === 'agua' && (
                <div className='d-flex flex-row gap-1 align-items-center justify-content-start'>
                    <div style={{height:'25px', width:'350px', border:'2px black solid'}}>
                        <div style={{width:`${isNaN(Number((usages[name] / totalAgua) * 100)) ? 0 : Number((usages[name] / totalAgua) * 100).toFixed(1) }%`, height:'100%', backgroundColor: colors[name]}}></div>
                    </div>
                    <h5 style={{color:'black'}}>{isNaN(Number((usages[name] / totalAgua) * 100)) ? 0 : (Number(usages[name] / totalAgua)).toFixed(1) * 100} %</h5>
                </div>
            )}

        </>
    )
}

export default Bar