/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable implicit-arrow-linebreak */
import React, { useState, useMemo } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { PDFViewer } from "@react-pdf/renderer";
import PDFDocument from "../../PDF/Document/PDFDocument";

const DownloadButton = React.memo(({ data }) => {
  const [centeredModal, setCenteredModal] = useState(false);

  // Memoriza el documento PDF renderizado, solo regenera si los datos cambian
  const memoizedPDFDocument = useMemo(
    () => (
      <PDFDocument
        data1={data}
        usage={data.usage}
        co2e={data.generalEventData.totalCO2}
        numberOfEvents={data.generalEventData.amountOfEvents}
      />
    ),
    [data]
  );

  return (
    <div className="h-100 d-flex align-items-center justify-content-center">
      <Button
        size="sm"
        color="primary "
        className="py-1"
        onClick={() => setCenteredModal(true)}
      >
        Descargar Declaración Huella Chile
      </Button>

      {centeredModal && (
        <Modal
          isOpen={centeredModal}
          toggle={() => setCenteredModal(!centeredModal)}
          className="modal-dialog-centered modal-lg"
        >
          <ModalHeader toggle={() => setCenteredModal(!centeredModal)}>
            PDF
          </ModalHeader>
          <ModalBody style={{ height: "600px" }}>
            <PDFViewer width={750} height="100%" showToolbar={true}>
              {memoizedPDFDocument}
            </PDFViewer>
          </ModalBody>
        </Modal>
      )}
    </div>
  );
});

export default DownloadButton;

  // <div className='vertically-centered-modal'>
  //           <Modal isOpen={centeredModal} toggle={() => setCenteredModal(!centeredModal)} className='modal-dialog-centered'>
  //               <ModalHeader toggle={() => setCenteredModal(!centeredModal)}>¿Estás segur@ que quieres avanzar?</ModalHeader>
  //               <ModalBody>
  //                   Revisa que toda tu información esté en el formulario. Luego no podrás retroceder a hacer cambios.
  //               </ModalBody>
  //               <ModalFooter>

  //                   {<Link to='/resumen' onClick = {() => { setCenteredModal(!centeredModal); setGoNext(true) } }>
  //                       <span className='btn btn-success w-100'>Ir al resumen</span>
  //                   </Link>   }
  //               </ModalFooter>
  //           </Modal>
  //       </div>