import React from 'react'
import { Button } from 'reactstrap'
import XLSX from 'xlsx'
import { XLSXCreator } from '../../../Helpers/XLSXCreator'

const DownloadSheet = ({data}) => {
  const downloadxls = async(e, data1, numberOfEvents, usage) => {

    const sections = [
      {
        Sección: "Uso de energía",
        "Total Co2e (TON)": 0
      },
      {
        Sección: "Consumo de agua",
        "Total Co2e (TON)": 0
      },
      {
        Sección: "Combustible",
        "Total Co2e (TON)": 0
      },
      {
        Sección: "Traslado de carga",
        "Total Co2e (TON)": 0
      },
      {
        Sección: "Traslado de artistas",
        "Total Co2e (TON)": 0
      },
      {
        Sección: "Traslado de asistentes",
        "Total Co2e (TON)": 0
      },
      {
        Sección: "Alimentación",
        "Total Co2e (TON)": 0
      },
      {
        Sección: "Hoteles",
        "Total Co2e (TON)": 0
      },
      {
        Sección: "Residuos",
        "Total Co2e (TON)": 0
      },
      {
        Sección: "Merchandising",
        "Total Co2e (TON)": 0
      },
      {
        Sección: "TOTAL",
        "Total Co2e (TON)": 0
      }
    ]
    for (let i = 0; i < numberOfEvents; i++) {
      sections[0]['Total Co2e (TON)'] += Number(usage[i].energy.usage)
      sections[1]['Total Co2e (TON)'] += Number(usage[i].water.agua_red) + Number(usage[i].water.agua_camion)
      sections[2]['Total Co2e (TON)'] += Number(usage[i].fuel.total)
      sections[3]['Total Co2e (TON)'] += Number(usage[i].transfer.traslado_carga)
      sections[4]['Total Co2e (TON)'] += Number(usage[i].transfer.traslado_artistas)
      sections[5]['Total Co2e (TON)'] += Number(usage[i].transfer.traslado_asistentes)
      sections[6]['Total Co2e (TON)'] += Number(usage[i].alimentation.usage)
      sections[7]['Total Co2e (TON)'] += Number(usage[i].hotel.usage)
      sections[8]['Total Co2e (TON)'] += Number(usage[i].waste.total)
      sections[9]['Total Co2e (TON)'] += Number(usage[i].merchandising.usage)

    }
    const total = sections.reduce((accum, item) => accum + item['Total Co2e (TON)'], 0)
    sections[10]['Total Co2e (TON)'] = total
    e.preventDefault()
    const wb = XLSX.utils.book_new()
    for (let i = 0; i < numberOfEvents; i++) {
      const country = data1.eventDetails[i].eventCountry
      const response = await fetch(
        `https://calculadoramde.cl/calculadora/api/valuesAPI/hotels/${country}`
      )
      const result = await response.json()
      const ws = XLSX.utils.json_to_sheet(XLSXCreator(data1, usage, i, result[0].emision))
      XLSX.utils.book_append_sheet(wb, ws, data1.eventDetails[i].eventIdentificator)

    }
    const auxiliarObj = XLSX.utils.json_to_sheet(sections)
    XLSX.utils.book_append_sheet(wb, auxiliarObj, 'Resumen')


    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, `Inventario-emisiones-${data.generalEventData.eventName}.xlsx`)
  }
  return (
      <Button className='btn btn-success py-1' size='sm' onClick={(e) => downloadxls(e, data, data.generalEventData.amountOfEvents, data.usage)}>Descargar Inventario</Button>

  )
}

export default DownloadSheet