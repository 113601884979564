import React from 'react'

const Point = ({name}) => {

    const colors = {
        'Relleno sanitario' : '#1bcdb8',
        'Consumo de combustible' : '#7c70f0',
        'Consumo de energia': '#ffa3a3',
        Reciclaje: '#11c77b',
        Alimentos: '#fdef21',
        Traslado : '#3fa9f5',
        'Agua de la red': '#0171bd',
        'Agua transportada': '#28abe3',
        'Agua proveniente de noria' : '#7fd9ec'
    }

    return (
        <div className='d-flex gap-1 align-items-center '>
            <div style={{height:'15px', width:'15px', borderRadius:'10px', backgroundColor:colors[name]}}/>
            <p className='p-0 m-0 text-black'>{name}</p>
        </div>
    )
}

export default Point