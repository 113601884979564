import React, {useState, useContext} from 'react'
import { DataContext } from "../../../../Context/Context"
import { AccordionItem, AccordionHeader, AccordionBody, TabContent, TabPane, Nav, NavItem, NavLink, Button, Alert } from 'reactstrap'
import Energy from '../Forms/Energy'
import Water from '../Forms/Water'
import Fuel from '../Forms/Fuel'
import Transfer from '../Forms/Transfer'
import Alimentation from '../Forms/Alimentation'
import Hotels from '../Forms/Hotels'
import Waste from '../Forms/Waste'
import Merchandising from '../Forms/Merchandising'
import { useFormContext } from 'react-hook-form'
const DeclarationMain = ({index, data}) => {
    const [active, setActive] = useState('1')

    const toggle = tab => {
        if (active !== tab) {
            setActive(tab)
        }
    }

    const {data1, goToDeclaration} = useContext(DataContext)
    const {...methods} = useFormContext()
    const errors = methods.formState.errors[index]
    return (
        <>
            {goToDeclaration && (
                <AccordionItem>

                    <AccordionHeader targetId={`${index + 1}`} className={`${(Object.keys(methods.formState.errors).includes(`${index}`) && true) ? 'border-2 border-danger rounded' : ''}`} >Información evento {index + 1} - {data1?.eventDetails?.[index]?.[`eventIdentificator`]} </AccordionHeader>
                    <div>

                        <AccordionBody  accordionId={`${index + 1}`}>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={`${errors?.energy && true ? 'text-danger' : ''}`}
                                        active={active === '1'}
                                        onClick={() => {
                                            toggle('1')
                                        }}
                                    >
                                        Uso de energía
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        className={`${errors?.agua && true ? 'text-danger' : ''}`}
                                        active={active === '2'}
                                        onClick={() => {
                                            toggle('2')
                                        }}
                                    >
                                        Agua
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={`${errors?.fuels && true ? 'text-danger' : ''}`}
                                        active={active === '4'}
                                        onClick={() => {
                                            toggle('4')
                                        }}
                                    >
                                        Combustible
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={`${(errors?.charge || errors?.artist) && true ? 'text-danger' : ''}`}
                                        active={active === '6'}
                                        onClick={() => {
                                            toggle('6')
                                        }}
                                    >
                                        Traslado
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={`${errors?.alimentos_bebidas && true ? 'text-danger' : ''}`}
                                        active={active === '7'}
                                        onClick={() => {
                                            toggle('7')
                                        }}
                                    >
                                        Alimentación
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={`${errors?.hotel && true ? 'text-danger' : ''}`}
                                        active={active === '8'}
                                        onClick={() => {
                                            toggle('8')
                                        }}
                                    >
                                        Hoteles
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={`${errors?.residuos && true ? 'text-danger' : ''}`}
                                        active={active === '9'}
                                        onClick={() => {
                                            toggle('9')
                                        }}
                                    >
                                        Residuos
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={`${errors?.merchandising && true ? 'text-danger' : ''}`}
                                        active={active === '10'}
                                        onClick={() => {
                                            toggle('10')
                                        }}
                                    >
                                        Merchandising
                                    </NavLink>
                                </NavItem>

                            </Nav>

                            <TabContent className='py-50' activeTab={active}>
                                <TabPane tabId='1'>
                                    <Energy index={index} data={data}/>
                                </TabPane>
                                <TabPane tabId='2'>
                                    <Water index={index}/>
                                </TabPane>
                                <TabPane tabId='4'>
                                    <Fuel index={index}/>
                                </TabPane>
                                <TabPane tabId='6'>
                                    <Transfer index={index}/>
                                </TabPane>
                                <TabPane tabId='7'>
                                    <Alimentation index={index}/>
                                </TabPane>
                                <TabPane tabId='8'>
                                    <Hotels index={index}/>
                                </TabPane>
                                <TabPane tabId='9'>
                                    <Waste index={index}/>
                                </TabPane>
                                <TabPane tabId='10'>
                                    <Merchandising index={index}/>
                                </TabPane>

                            </TabContent>

                        </AccordionBody>
                    </div>
                </AccordionItem>
            )}
        </>
    )
}

export default DeclarationMain