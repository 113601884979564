
import csv from '../assets/CSV/csv.json'

const appendArrayData = (data1, usage, index, section) => {
  //const merchOpts = ['Madera, 'Metal: Latas y láminas de aluminio', 'Metal: Latas mixtas', 'Textiles', 'Papel y cartón: cartón', 'Papel y cartón: papel', 'Papel y cartón: mixto', 'Plastico promedio']
  const merchOpts = {
    merch_madera : 'Madera',
    merch_metal_latas_laminas_aluminio :  'Metal: Latas y láminas de aluminio',
    merch_metal_latas_mixtas : 'Metal: Latas mixtas',
    merch_textiles: 'Textiles',
    merch_carton: 'Papel y cartón: cartón',
    merch_papel : 'Papel y cartón: papel',
    merch_mezcla_papel_carton : 'Papel y cartón: mixto',
    merch_plastico_promedio : 'Plastico promedio'
  }

  const chargeOpts = {
    cam_rigid_7tMax_prom : 'Camion rígido entre (3.5 - 7.5 toneladas)',
    cam_rigid_17tMax_prom : 'Camion rígido entre (7.5 - 17 toneladas)',
    cam_rigid_nolimit_prom : 'Camion rígido más de 17 toneladas',
    van_promedio_diesel :  'Van promedio sobre 3.5 toneladas - Diesel',
    van_promedio_gasolina : 'Van promedio sobre 3.5 toneladas - Gasolina'
  }

  const rows = []
  if (section === 'Merchandising') {
    data1.carbonDeclaration[index].merchandising.merchandisingFields.forEach((item) => {
      rows.push({
        "CCH Nivel 1": "Alcance 3",
        "CCH Nivel 2": "Bienes y servicios adquiridos",
        "CCH Nivel 3": "Merchandising",
        "CCH Nivel 4": merchOpts[item.material],
        Contaminante: "DIóxido de Carbono (CO2)",
        "Valor del FE": "",
        "Unidad del FE": "kgCO2eq / t",
        "Descripcion Emisión": "",
        "Unidad de medida": 'Variada',
        Cantidad: `Cantidad: ${item.amount},
           Peso unitario: ${item.weight}, ${item.UOM}`,
        CO2e: usage[index].merchandising.usage
      })
    })
  }
  if (section === 'Transporte de carga') {
    data1.carbonDeclaration[index].charge.chargeFields.forEach((item) => {
      rows.push({
        "CCH Nivel 1": "Alcance 3",
        "CCH Nivel 2": "Transporte de carga",
        "CCH Nivel 3": "Traslado de carga",
        "CCH Nivel 4": chargeOpts[item.type],
        Contaminante: "CO2 + CH4 + N2O",
        "Valor del FE": "",
        "Unidad del FE": "kgCO2eq/t-km",
        "Descripcion Emisión": "",
        "Unidad de medida": 'Variada',
        Cantidad: `Distancia: ${item.distance},
           Veces hecho: ${item.timesDone},
            Tipo de transporte: ${item.type},
             Carga: ${item.charge} ${item.UOM},
              Inicio - Final: ${item.origin} - ${item.end}`,
        CO2e: usage[index].transfer.traslado_carga
      })
    })
  }

  if (section === 'Transporte de artistas') {
    data1.carbonDeclaration[index].artist.artistFields.forEach((item) => {
      const transports = item.transportsUsed.map(transport => transport.label.replace('_', ' '))
      rows.push({
        "CCH Nivel 1": "Alcance 3",
        "CCH Nivel 2": "Movilización de personas",
        "CCH Nivel 3": "Traslado de artistas",
        "CCH Nivel 4": transports.join(', '),
        Contaminante: "CO2 + CH4 + N2O",
        "Valor del FE": "",
        "Unidad del FE": "kgCO2eq/persona-km",
        "Descripcion Emisión": "",
        "Unidad de medida": 'Variada',
        Cantidad: `Distancia: ${item.distance},
           Tipo de transporte: ${transports.join(', ')}, 
           Personas transportadas: ${item.people},
            Inicio - Final: ${item.origin} - ${item.end}`,
        CO2e: usage[index].transfer.traslado_artistas
      })
    })
  }

  return rows
}

export const XLSXCreator =  (data1, usage, index, hotelEmision) => {
  let data2 = csv
  data2[0].Cantidad = data1.carbonDeclaration[index].energy.electricNetwork.usage
  data2[0]["Unidad de medida"] = data1.carbonDeclaration[index].energy.electricNetwork.uom
  data2[0].CO2e = usage[index].energy.usage

  data2[1].Cantidad = data1.carbonDeclaration[index].energy.renovableEnergy.usage
  data2[1]["Unidad de medida"] = '%'
  data2[1].CO2e = '0'

  data2[2].Cantidad = data1.carbonDeclaration[index].agua.agua_red.usage
  data2[2]["Unidad de medida"] = data1.carbonDeclaration[index].agua.agua_red.UOM
  data2[2].CO2e = usage[index].water.agua_red

  data2[3].Cantidad = data1.carbonDeclaration[index].agua.agua_camion.usage
  data2[3]["Unidad de medida"] = data1.carbonDeclaration[index].agua.agua_camion.UOM
  data2[3].CO2e = usage[index].water.agua_camion

  if (data1.carbonDeclaration[index].fuels.GLP.GLPUOM === 'LT') {
    data2[5].Cantidad = data1.carbonDeclaration[index].fuels.GLP.usage
    data2[5]["Unidad de medida"] = data1.carbonDeclaration[index].fuels.GLP.GLPUOM
    data2[5].CO2e = usage[index].fuel.GLP
  }

  if (data1.carbonDeclaration[index].fuels.GLP.GLPUOM === 'Kg') {
    data2[6].Cantidad = data1.carbonDeclaration[index].fuels.GLP.usage
    data2[6]["Unidad de medida"] = data1.carbonDeclaration[index].fuels.GLP.GLPUOM
    data2[6].CO2e = usage[index].fuel.GLP
  }


  data2[7].Cantidad = data1.carbonDeclaration[index].fuels.diesel.usage
  data2[7]["Unidad de medida"] = data1.carbonDeclaration[index].fuels.diesel.dieselUOM
  data2[7].CO2e = usage[index].fuel.diesel

  data2[8].Cantidad = data1.carbonDeclaration[index].fuels.bencina.usage
  data2[8]["Unidad de medida"] = data1.carbonDeclaration[index].fuels.bencina.bencinaUOM
  data2[8].CO2e = usage[index].fuel.bencina

  if (data1.carbonDeclaration[index].fuels.gas_natural.gas_naturalUOM === 'LT') {
    data2[9].Cantidad = data1.carbonDeclaration[index].fuels.gas_natural.usage
    data2[9]["Unidad de medida"] = data1.carbonDeclaration[index].fuels.gas_natural.gas_naturalUOM
    data2[9].CO2e = usage[index].fuel.gas_natural
  }

  if (data1.carbonDeclaration[index].fuels.gas_naturalUOM === 'Kg') {
    data2[10].Cantidad = data1.carbonDeclaration[index].fuels.gas_natural.usage
    data2[10]["Unidad de medida"] = data1.carbonDeclaration[index].fuels.gas_natural.gas_naturalUOM
    data2[10].CO2e = usage[index].fuel.gas_natural
  }

  data2[11].Cantidad = data1.carbonDeclaration[index].alimentos_bebidas.staff.amount
  data2[11]["Unidad de medida"] = data1.carbonDeclaration[index].alimentos_bebidas.staff.UOM
  data2[11].CO2e = usage[index].alimentation.usage
  data2[11]["Descripcion Emisión"] = `Venta al publico : ${ data1.carbonDeclaration[index].alimentos_bebidas.attendees.wasSold}`


  data2[12].Cantidad = `${data1.carbonDeclaration[index].hotel.nights} Noches, ${data1.carbonDeclaration[index].hotel.rooms} Habitaciones`
  data2[12]["Unidad de medida"] = 'Noches, habitaciones'
  data2[12]["CCH Nivel 4"] = data1.eventDetails[index].eventCountry
  data2[12]["Valor del FE"] = hotelEmision
  data2[12].CO2e = usage[index].hotel.usage

  data2[13].Cantidad = data1.carbonDeclaration[index].residuos.residuos.usage
  data2[13]["Unidad de medida"] = data1.carbonDeclaration[index].residuos.residuos.uom
  data2[13].CO2e = usage[index].waste.residuos

  data2[14].Cantidad = data1.carbonDeclaration[index].residuos.reciclaje.vidrio
  data2[14]["Unidad de medida"] = data1.carbonDeclaration[index].residuos.reciclaje.vidrioUOM
  data2[14].CO2e = usage[index].waste.reciclaje

  //15 papel y carton

  data2[15].Cantidad = data1.carbonDeclaration[index].residuos.reciclaje.papelesYCartones
  data2[15]["Unidad de medida"] = data1.carbonDeclaration[index].residuos.reciclaje.papelesYCartonesUOM
  data2[15].CO2e = '-'
  //16 metal chatarra metalica
  data2[16].Cantidad = data1.carbonDeclaration[index].residuos.reciclaje.metales
  data2[16]["Unidad de medida"] = data1.carbonDeclaration[index].residuos.reciclaje.metalesUOM
  data2[16].CO2e = '-'
  //17 plasticos promedio de plasticos
  data2[17].Cantidad = data1.carbonDeclaration[index].residuos.reciclaje.plastico
  data2[17]["Unidad de medida"] = data1.carbonDeclaration[index].residuos.reciclaje.plasticoUOM
  data2[17].CO2e = '-'
  //18 compostaje
  data2[18].Cantidad = data1.carbonDeclaration[index].residuos.compostaje.usage
  data2[18]["Unidad de medida"] = data1.carbonDeclaration[index].residuos.compostaje.uom
  data2[18].CO2e = usage[index].waste.compostaje


  data2 = [...data2, ...appendArrayData(data1, usage, index, 'Merchandising')]
  data2 = [...data2, ...appendArrayData(data1, usage, index, 'Transporte de carga')]
  data2 = [...data2, ...appendArrayData(data1, usage, index, 'Transporte de artistas')]
  data2 = [
    ...data2, {
      "CCH Nivel 1": "Alcance 3",
      "CCH Nivel 2": "Movilización de personas",
      "CCH Nivel 3": "Traslado de asistentes",
      "CCH Nivel 4": "-",
      Contaminante: "CO2 + CH4 + N2O",
      "Valor del FE": usage[index].transfer.traslado_asistentes,
      "Unidad del FE": "kgCO2/persona",
      "Descripcion Emisión" : "Promedio Greenmind",
      "Unidad de medida" : "Personas (Asistentes)",
      Cantidad: data1.eventDetails[index].attendeesNumber,
      CO2e : usage[index].transfer.traslado_asistentes      
    }
  ]


  return data2
}