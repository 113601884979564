import React, {useContext, useEffect} from "react"
import { Col, Input, Row, Label, InputGroup, InputGroupText } from "reactstrap"
import { Controller, useFormContext } from "react-hook-form"
import { DataContext } from "../../../../Context/Context"
import { calculateFuel } from "../../../../Helpers/Calculations"


const Fuel = ({index}) => {
    const { control, formState:{errors}, register, watch} = useFormContext()
    const useOfGLPFuel = `[${index}.fuels.GLP.use]`
    const useOfNaturalGas = `[${index}.fuels.gas_natural.use`
    const useOfBenzine = `[${index}.fuels.bencina.use]`
    const useOfDiesel = `[${index}.fuels.diesel.use]`
    const usageOfGLPFuel = `[${index}.fuels.GLP.usage]`
    const usageOfNaturalGas = `[${index}.fuels.gas_natural.usage]`
    const usageOfBenzine = `[${index}.fuels.bencina.usage]`
    const usageOfDiesel = `[${index}.fuels.diesel.usage]`
    const glpFuelUOM = `[${index}.fuels.GLP.GLPUOM]`
    const naturalGasUOM = `[${index}.fuels.gas_natural.gas_naturalUOM]`
    const benzineUOM = `[${index}.fuels.bencina.bencinaUOM]`
    const dieselUOM = `[${index}.fuels.diesel.dieselUOM]`
    const {values, usage, data1} = useContext(DataContext)
    const total = calculateFuel(watch(), index, values, data1).totalfinal
    useEffect(() => {
        const {total} = calculateFuel(watch(), index, values, data1)
        usage[index].fuel = total
    }, [watch()])
    return (
        <Row>
            <Row className=""><Col className="d-flex justify-content-end"><h5 className="text-success">+ {total > 1 ? `${total.toFixed(2)} TON ` : `${(total * 1000).toFixed(2)} Kg `}CO<sub>2</sub>e</h5></Col></Row>

            {/* INDIVIDUAL ROW*/}
            <Row className="input--main--container mt-1">
                <Col className="left--section" md='6' sm='12'>
                    <Row>
                        <h5>Uso de combustible GLP</h5>
                        <small>Clickea el botón en caso de haber utilizado combustible GLP</small>
                    </Row>
                    <Row className="mt-1">
                        <div className="d-flex gap-1">
                            <div>

                                <input type='radio' className="form-check-input" value='true' {...register(useOfGLPFuel, {required:true})}  />
                                <Label>Si</Label>
                            </div>
                            <div>

                                <input type='radio' className="form-check-input" value='average' {...register(useOfGLPFuel, {required:true})}  />
                                <Label>Sí, pero no tengo la cifra</Label>
                            </div>
                            <div>

                                <input type='radio' className="form-check-input" value='false' {...register(useOfGLPFuel, {required:true})}  />
                                <Label>No</Label>
                            </div>
                        </div>
                        <div className="mt-1">
                        </div>
                        {errors[index]?.fuels?.GLP?.use?.types?.required && <small className='text-danger  mt-1'>Por favor, selecciona si hubo o no uso de combustible GLP</small>}
                    </Row>
                </Col>

                <Col className={`right--section mt-1 ${watch(useOfGLPFuel) === 'true' ? '' : 'd-none'}`}>
                    <Label>Cantidad utilizada de combustible GLP <strong>(litros o toneladas)</strong></Label>

                    <InputGroup>
                        <Controller
                            id={usageOfGLPFuel}
                            name={usageOfGLPFuel}
                            control={control}
                            defaultValue= {0}
                            rules={{required:true}}
                            render = {({field}) => (
                                <Input type='number' name={usageOfGLPFuel} invalid= {errors[index]?.usageOfGLPFuel && true} {...field}/>
                            )}
                        />
                        <select className=" input-group-text " {...register(glpFuelUOM)} >
                            <option selected>LT</option>
                            <option>Kg</option>
                        </select>
                    </InputGroup>
                    {errors[index]?.usageOfGLPFuel?.types?.required && <small className='text-danger'>Por favor, digita la cantidad utilizada de combustible GLP</small>}
                </Col>

            </Row>

            {/* INDIVIDUAL ROW*/}
            <Row className="input--main--container mt-2">
                <Col className="left--section" md='6' sm='12'>
                    <Row>
                        <h5>Uso de diésel</h5>
                        <small>Clickea el botón en caso de haber utilizado diésel</small>
                    </Row>
                    <Row className="mt-1">
                        <div className="d-flex gap-1">
                            <div>

                                <input type='radio' className="form-check-input" value='true' {...register(useOfDiesel, {required:true})}  />
                                <Label>Si</Label>
                            </div>
                            <div>

                                <input type='radio' className="form-check-input" value='average' {...register(useOfDiesel, {required:true})}  />
                                <Label>Sí, pero no tengo la cifra</Label>
                            </div>
                            <div>

                                <input type='radio' className="form-check-input" value='false' {...register(useOfDiesel, {required:true})}  />
                                <Label>No</Label>
                            </div>
                        </div>
                        <div className="mt-1">
                        </div>
                        {errors[index]?.fuels?.diesel?.use?.types?.required && <small className='text-danger  mt-1'>Por favor, selecciona si hubo o no uso de combustible diésel</small>}

                    </Row>
                </Col>

                <Col className={`right--section mt-1 ${watch(useOfDiesel) === 'true' ? '' : 'd-none'}`}>
                    <Label>Cantidad utilizada de diésel <strong>(litros o m<sup>3</sup>)</strong></Label>

                    <InputGroup>
                        <Controller
                            id={usageOfDiesel}
                            name={usageOfDiesel}
                            control={control}
                            defaultValue= {0}
                            rules={{required:true}}
                            render = {({field}) => (
                                <Input type='number' name={usageOfDiesel} invalid= {errors[index]?.usageOfDiesel && true} {...field}/>
                            )}
                        />
                        <select className=" input-group-text " {...register(dieselUOM)} >
                            <option selected>LT</option>
                            <option>m³</option>
                        </select>
                    </InputGroup>
                    {errors[index]?.usageOfDiesel?.types?.required && <small className='text-danger'>Por favor, digita la cantidad utilizada de diésel</small>}
                </Col>

            </Row>

            {/* INDIVIDUAL ROW*/}
            <Row className="input--main--container mt-2">
                <Col className="left--section" md='6' sm='12'>
                    <Row>
                        <h5>Uso de bencina</h5>
                        <small>Clickea el botón en caso de haber utilizado bencina</small>
                    </Row>
                    <Row className="mt-1">
                        <div className="d-flex gap-1">
                            <div>

                                <input type='radio' className="form-check-input" value='true' {...register(useOfBenzine, {required:true})}  />
                                <Label>Si</Label>
                            </div>

                            <div>

                                <input type='radio' className="form-check-input" value='average' {...register(useOfBenzine, {required:true})}  />
                                <Label>Sí, pero no tengo la cifra</Label>
                            </div>
                            <div>

                                <input type='radio' className="form-check-input" value='false' {...register(useOfBenzine, {required:true})}  />
                                <Label>No</Label>
                            </div>
                        </div>
                        <div className="mt-1">
                        </div>
                        {errors[index]?.fuels?.bencina?.use?.types?.required && <small className='text-danger  mt-1'>Por favor, selecciona si hubo o no uso de bencina</small>}

                    </Row>
                </Col>

                <Col className={`right--section mt-1 ${watch(useOfBenzine) === 'true' ? '' : 'd-none'}`}>
                    <Label>Cantidad utilizada de bencina <strong>(litros o m<sup>3</sup>)</strong></Label>

                    <InputGroup>
                        <Controller
                            id={usageOfBenzine}
                            name={usageOfBenzine}
                            control={control}
                            defaultValue= {0}
                            rules={{required:true}}
                            render = {({field}) => (
                                <Input type='number' name={usageOfBenzine} invalid= {errors[index]?.usageOfBenzine && true} {...field}/>
                            )}
                        />
                        <select className=" input-group-text " {...register(benzineUOM)} >
                            <option selected>LT</option>
                            <option>m³</option>
                        </select>
                    </InputGroup>
                    {errors[index]?.usageOfDiesel?.types?.required && <small className='text-danger'>Por favor, digita la cantidad utilizada de bencina</small>}
                </Col>

            </Row>

            {/* INDIVIDUAL ROW*/}
            <Row className="input--main--container mt-2">
                <Col className="left--section" md='6' sm='12'>
                    <Row>
                        <h5>Uso de gas natural</h5>
                        <small>Clickea el botón en caso de haber utilizado gas natural</small>
                    </Row>
                    <Row className="mt-1">
                        <div className="d-flex gap-1">
                            <div>

                                <input type='radio' className="form-check-input" value='true' {...register(useOfNaturalGas, {required:true})}  />
                                <Label>Si</Label>
                            </div>
                            <div>

                                <input type='radio' className="form-check-input" value='average' {...register(useOfNaturalGas, {required:true})}  />
                                <Label>Sí, pero no tengo la cifra</Label>
                            </div>
                            <div>

                                <input type='radio' className="form-check-input" value='false' {...register(useOfNaturalGas, {required:true})}  />
                                <Label>No</Label>
                            </div>
                        </div>
                        <div className="mt-1">
                        </div>
                        {errors[index]?.fuels?.gas_natural?.use?.types?.required && <small className='text-danger  mt-1'>Por favor, selecciona si hubo o no uso de gas natural</small>}

                    </Row>
                </Col>

                <Col className={`right--section mt-1 ${watch(useOfNaturalGas) === 'true' ? '' : 'd-none'}`}>
                    <Label>Cantidad utilizada de gas natural <strong>(m<sup>3</sup> o toneladas)</strong></Label>

                    <InputGroup>
                        <Controller
                            id={usageOfNaturalGas}
                            name={usageOfNaturalGas}
                            control={control}
                            defaultValue= {0}
                            rules={{required:true}}
                            render = {({field}) => (
                                <Input type='number' name={usageOfNaturalGas} invalid= {errors[index]?.usageOfNaturalGas && true} {...field}/>
                            )}
                        />
                        <select className=" input-group-text " {...register(naturalGasUOM)} >
                            <option selected>LT</option>
                            <option>Kg</option>
                        </select>
                    </InputGroup>
                    {errors[index]?.usageOfDiesel?.types?.required && <small className='text-danger'>Por favor, digita la cantidad utilizada de gas natural</small>}
                </Col>

            </Row>

        </Row>
    )
}

export default Fuel
