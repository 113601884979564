import React from 'react'
import { Check, X } from 'react-feather'
import toast from 'react-hot-toast'

const ValidateButton = ({id, data, setData, setLoading}) => {
  const onValidateClick = async (id) => {
    return fetch('https://calculadoramde.cl/calculadora/api/formAPI/validate', {
      method:'PUT',
      headers:{
        'Content-Type': 'application/json'
      },
      body:JSON.stringify({EventID: id})
    })
        .catch(error => error)
        .then(response => {
          response.json()

        })
        .then(() => {
          setLoading(true)
          fetch('https://calculadoramde.cl/calculadora/api/formAPI')
              .then(response => response.json())
              .then(result => {
                setData(result)
              })
              .then(() => setLoading(false))

          return 'Verificado correctamente!'
        })


  }
  const handleClick = (id) => {
    return toast.promise(onValidateClick(id), {
      loading:'Loading',
      success:'Validado correctamente!',
      error:'Whoops, hubo algun error en la validacion'
    })
  }
  return (<>
        {!data.generalEventData.verified ? (
            <button className='btn btn-icon btn-success' onClick = {() => handleClick(id)}><Check size={20}/></button>
        ) : (
            <button className='btn btn-icon btn-success' disabled><Check size={20} /></button>
        )}

      </>
  )
}

export default ValidateButton